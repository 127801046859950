import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";

import {
  // bh_ngheo,
  bh_nha,
  bhyt,
  bm_care,
  dot_quy,
  easy_care,
  familycare,
  // gc_care,
  // master_rider,
  // mcmc_xm,
  ntd,
  // skv,
  tai_nan,
  tk_htri,
  tnds_bb_oto,
  tnds_bb_xm,
  tomato,
  tomato_combo,
  travel,
  tru_cot,
  vbicare,
  vcoto,
} from "../../extra/hard-data";
import Images from "../../extra/images";
import { animateLeft, animateRight } from "../../extra/utils";
import { useStyles } from "../../styles/inforStyles";
import Products from "../components/Products";

import DownloadIcon from "../../assets/icons/download-2.svg";
import VectorIcon from "../../assets/icons/vector.svg";

const packages = [
  { id: 1, name: "Gói bảo hiểm TNDS BB Xe máy" },
  // { id: 2, name: "Gói bảo hiểm mất cắp, mất cướp xe máy" },
  // { id: 3, name: "Gói bảo hiểm đột quỵ" },
  { id: 4, name: "Gói bảo hiểm TNDS BB Ô Tô" },
  { id: 5, name: "Gói bảo hiểm tai nạn" },
  // { id: 6, name: "Gói bảo hiểm bệnh hiểm nghèo" },
  // { id: 7, name: "Gói bảo hiểm Easy Care" },
  { id: 8, name: "Gói bảo hiểm nhà tư nhân" },
  // { id: 9, name: "Gói bảo hiểm sức khoẻ GC Care" },
  { id: 10, name: "Gói bảo hiểm Tomato" },
  { id: 11, name: "Gói bảo hiểm TNDS BB Xe Máy Combo" },
  // { id: 12, name: "Gói bảo hiểm trụ cột gia đình" },
  // { id: 13, name: "Gói bảo hiểm Master Rider" },
  { id: 14, name: "Gói bảo hiểm Du lịch" },
  // { id: 15, name: "Gói bảo hiểm tiết kiệm Hưu trí" },
  { id: 16, name: "Gói bảo hiểm vật chất xe" },
  // { id: 17, name: "Gói bảo hiểm y tế" },
  // { id: 18, name: "Gói bảo hiểm BM Care" },
  // { id: 19, name: "Gói bảo hiểm Sức Khỏe Vàng" },
  { id: 20, name: "Gói bảo hiểm Nhà tư nhân" },
  { id: 21, name: "Gói bảo hiểm VBI Care" },
  { id: 22, name: "Gói bảo hiểm Family Care" },
];

const animateImg = {
  initial: { y: -50, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
  transition: { duration: 0.5 },
};

export default function Information(props) {
  const { state, dispatch, isMobile } = props;
  const { inforData, products, loading, navSelected } = state;
  const { selected } = inforData;
  const classes = useStyles();
  const infoRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => dispatch("unMore");
  }, [navSelected]);

  useEffect(() => {
    if (inforData.selected && inforData?.more) {
      scrollToInfo();
    }
  }, [inforData.selected]);

  const scrollToInfo = () => {
    const element = document.getElementById("info");
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - 100;
    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const renderInfo = (key) => {
    switch (key - 1) {
      case 21:
        return (
          <>
            <div className="text-bold">BẢO HIỂM FAMILY CARE</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerFamilyCare}
                alt="banner-0"
                className="full-width"
              />
            </motion.div>

            {familycare}
          </>
        );
      case 20:
        return (
          <>
            <div className="text-bold">BẢO HIỂM VBI CARE</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerVBICare}
                alt="banner-0"
                className="full-width"
              />
            </motion.div>

            {vbicare}
          </>
        );
      case 0:
        return (
          <>
            <div className="text-bold">BẢO HIỂM TNDS BB XE MÁY</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerBBXM}
                alt="banner-0"
                className="full-width"
              />
            </motion.div>

            {tnds_bb_xm}
          </>
        );
      // case 1:
      //   return (
      //     <>
      //       <div className="text-bold">BẢO HIỂM MẤT CẮP, MẤT CƯỚP XE MÁY</div>

      //       <motion.div key={key} {...animateImg}>
      //         <img
      //           src={Images.BannerMCXe}
      //           alt="banner-1"
      //           className="full-width"
      //         />
      //       </motion.div>
      //       {mcmc_xm}
      //     </>
      //   );
      case 2:
        return (
          <>
            <div className="text-bold">BẢO HIỂM ĐỘT QUỴ</div>
            {dot_quy}
          </>
        );
      case 3:
        return (
          <>
            <div className="text-bold">
              BẢO HIỂM TNDS BB Ô TÔ - AN TÂM TRÊN MỌI NẺO ĐƯỜNG
            </div>
            {tnds_bb_oto}
          </>
        );
      case 4:
        return (
          <>
            <div className="text-bold">BẢO HIỂM TAI NẠN CÁ NHÂN</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerTaiNan}
                alt="banner-4"
                className="full-width"
              />
            </motion.div>
            {tai_nan}
          </>
        );
      // case 5:
      //   return (
      //     <>
      //       <div className="text-bold">BẢO HIỂM BỆNH HIỂM NGHÈO</div>
      //       <motion.div key={key} {...animateImg}>
      //         <img
      //           src={Images.BannerNgheo}
      //           alt="banner-5"
      //           className="full-width"
      //         />
      //       </motion.div>

      //       {bh_ngheo}
      //     </>
      //   );
      case 6:
        return (
          <>
            <div className="text-bold">BẢO HIỂM SỨC KHOẺ EASY CARE</div>
            {easy_care}
          </>
        );
      case 7:
        return (
          <>
            <div className="text-bold">BẢO HIỂM NHÀ TƯ NHÂN (HOME CARE)</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerNha}
                alt="banner-7"
                className="full-width"
              />
            </motion.div>
            {bh_nha}
          </>
        );
      // case 8:
      //   return (
      //     <>
      //       <div className="text-bold">BẢO HIỂM SỨC KHOẺ GC CARE</div>

      //       {gc_care}
      //     </>
      //   );
      case 9:
        return (
          <>
            <div className="text-bold">BẢO HIỂM TAI NẠN TOMATO GÓI CÁ NHÂN</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerTomato}
                alt="banner-9"
                className="full-width"
              />
            </motion.div>
            {tomato}
          </>
        );
      case 10:
        return (
          <>
            <div className="text-bold">BẢO HIỂM TNDS BB XE MÁY</div>
            {tomato_combo}
          </>
        );
      case 11:
        return (
          <>
            <div className="text-bold">
              BẢO HIỂM TRỤ CỘT GIA ĐÌNH - MANG AN TÂM VỀ CHO GIA ĐÌNH BẠN
            </div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerTrucot}
                alt="banner-11"
                className="full-width"
              />
            </motion.div>
            {tru_cot}
          </>
        );
      // case 12:
      //   return (
      //     <>
      //       <div className="text-bold">BẢO HIỂM MASTER RIDER</div>
      //       {master_rider}
      //     </>
      //   );
      case 13:
        return (
          <>
            <div className="text-bold">BẢO HIỂM DU LỊCH</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerDulich}
                alt="banner-13"
                className="full-width"
              />
            </motion.div>
            {travel}
          </>
        );
      case 14:
        return (
          <>
            <div className="text-bold">BẢO HIỂM TIẾT KIỆM HƯU TRÍ</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerHTri}
                alt="banner-13"
                className="full-width"
              />
            </motion.div>
            {tk_htri}
          </>
        );
      case 15:
        return (
          <>
            <div className="text-bold">BẢO HIỂM VẬT CHẤT Ô TÔ</div>
            {vcoto}
          </>
        );
      case 16:
        return (
          <>
            <div className="text-bold">BẢO HIỂM Y TẾ</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerBHYT}
                alt="banner-16"
                className="full-width"
              />
            </motion.div>
            {bhyt}
          </>
        );
      // case 17:
      //   return (
      //     <>
      //       <div className="text-bold">BẢO HIỂM BM CARE</div>
      //       <motion.div key={key} {...animateImg}>
      //         <img
      //           src={Images.BannerBMCare}
      //           alt="banner-17"
      //           className="full-width"
      //         />
      //       </motion.div>
      //       {bm_care}
      //     </>
      //   );
      // case 18:
      //   return (
      //     <>
      //       <div className="text-bold">BẢO HIỂM SỨC KHỎE VÀNG</div>
      //       <motion.div key={key} {...animateImg}>
      //         <img
      //           src={Images.BannerSKV}
      //           alt="banner-20"
      //           className="full-width"
      //         />
      //       </motion.div>
      //       {skv}
      //     </>
      //   );

      case 19:
        return (
          <>
            <div className="text-bold">BẢO HIỂM NHÀ TƯ NHÂN</div>
            <motion.div key={key} {...animateImg}>
              <img
                src={Images.BannerNhaToanDien}
                alt="banner-20"
                className="full-width"
              />
            </motion.div>
            {ntd}
          </>
        );

      default:
        return <div></div>;
    }
  };

  return (
    <div className={classes.container}>
      <motion.div key="banner" {...animateRight} className="mx-30">
        <a
          href="https://g2.globalcare.vn/file/cb18f22d-fd67-42bc-8d20-a70cfa038fc5"
          target="_blank"
          download={"Salekits.zip"}
        >
          <img
            src={isMobile ? Images.Image8 : Images.Image6}
            alt="img-6"
            className="img"
          />
        </a>
      </motion.div>
      <Products list={products} dispatch={dispatch} />
      <div id="info" ref={infoRef} className="mx-8">
        <div className="title">Thông tin sản phẩm</div>
        <div className="row">
          {isMobile ? (
            <Select value={selected} label="" className="select">
              {packages.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.id}
                  onClick={() => dispatch({ type: "selectInfo", id: item.id })}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <div className="width-30">
              {packages.map((item, index) => (
                <div
                  key={index}
                  className={` menu-item ${
                    selected === item.id ? "color" : ""
                  }`}
                  onClick={() => dispatch({ type: "selectInfo", id: item.id })}
                >
                  <span>{item.name}</span>
                  {selected === item.id ? (
                    <ChevronRightIcon className="color" />
                  ) : (
                    <ExpandLessIcon />
                  )}
                </div>
              ))}
            </div>
          )}

          <div className="width-70 ml-3 content">
            <motion.div key={selected} {...animateLeft}>
              {renderInfo(selected)}
            </motion.div>
          </div>
        </div>
      </div>
      <div className="download">
        <div>Salekits tìm hiểu thêm về sản phẩm</div>
        <Button
          variant="contained"
          textTransform="initial"
          endIcon={
            loading ? (
              <CircularProgress size={20} color="success" />
            ) : (
              <img src={DownloadIcon} alt="download" />
            )
          }
          onClick={() => dispatch("downloadFile")}
        >
          {loading ? "Đang tải..." : "Tải Salekit ngay"}
        </Button>
        <img src={VectorIcon} alt="vector" className="icon" />
      </div>
    </div>
  );
}
