import QrIcon from "../assets/icons/qr-down.svg";
import Icons from "../extra/icons";
import Images from "../extra/images";

// ** HARD DATA
export const banners = [
  {
    imgMobile: Images.Banner2,
    img: Images.Frame2,
    link: "https://core.globalcare.vn/app/gsale/download",
  },
  {
    imgMobile: Images.Banner6,
    img: Images.Frame6,
    link: "https://core.globalcare.vn/app/gsale/download",
  },

  {
    imgMobile: Images.Banner1,
    img: Images.Frame1,
    link: "https://core.globalcare.vn/app/gsale/download",
  },
  {
    imgMobile: Images.Banner5,
    img: Images.Frame5,
    link: "https://core.globalcare.vn/app/gsale/download",
  },
  {
    imgMobile: Images.Banner3,
    img: Images.Frame3,
    link: "https://core.globalcare.vn/app/gsale/download",
  },
];

export const list_product = [
  // {
  //   id: 19,
  //   icon: Icons.skv,
  //   name: "Sức khỏe vàng",
  //   content:
  //     "Sản phẩm bảo hiểm sức khỏe có quyền lợi trả góp theo tháng, giúp bạn giảm gánh nặng chi phí trước nhiều quyền lợi ưu việt: nằm viện, khám và điều trị ngoại trú, tử vong do tai nạn, ốm bệnh, thai sản.. và chỉ từ 2.300đ/ngày",
  //   commission: "22%",
  //   new: true,
  // },
  {
    id: 22,
    icon: Icons.familycare,
    name: "Family Care",
    content:
      "Với mức phí chỉ từ 287.000đ/ năm và Quyền lợi bảo vệ tài chính lên đến 2 TỶ ĐỒNG, Family Care giúp “trụ cột” gia đình an tâm trọn vẹn trước những “ngày mưa” trong cuộc sống khi gia đình luôn được bảo vệ vững vàng với giải pháp dự phòng tài chính ưu việt.",
    commission: "29%",
    new: true,
  },
  {
    id: 21,
    icon: Icons.vbicare,
    name: "VBI Care",
    content:
      "Chỉ từ 1.000 đ mỗi ngày. Đặc biệt quyền lợi thai sản thời gian ngắn nhất thị trường. Với 5 gói Bảo vệ sức khỏe của bản thân và gia đình bạn VBI Care. Đây là giải pháp tài chính tối ưu, giúp bạn và gia đình an tâm tận hưởng cuộc sống. Không lo ngại chi phí khi không may rủi ro xảy đến.",
    commission: "16%",
    new: true,
  },
  {
    id: 20,
    icon: Icons.ntd,
    name: "Bảo hiểm Nhà tư nhân",
    content:
      "Với quyền lợi bảo vệ lên đến 10 TỶ ĐỒNG mà mức phí chỉ từ 200.000đ/năm để bảo vệ “tổ ấm” yêu thương của bạn, Global Care liên kết với Tổng Công ty CP Bảo hiểm Hàng không (VNI) mang lại cho khách hàng quyền lợi bảo vệ ngôi nhà trước các rủi ro: cháy, nổ, giông bão, lũ lụt, trộm cắp….",
    commission: "38%",
    new: true,
  },
  // {
  //   id: 15,
  //   icon: Icons.tkasIc,
  //   name: "Tiết kiệm Hưu trí",
  //   content:
  //     "Bảo hiểm Tiết kiệm Hưu trí với nhiều quyền lợi vượt trội cho khách hàng khi kết hợp yếu tố bảo vệ, đầu tư sinh lời và tích lũy giá trị tài khoản theo thời gian.",
  //   commission: "22%",
  //   new: true,
  // },

  {
    id: 10,
    icon: Icons.tomatoIc,
    name: "Tomato",
    content:
      "Giúp bạn an tâm trải nghiệm cuộc sống khi được bảo vệ toàn diện trước các rủi ro tai nạn, đồng thời sử dụng tiền lẻ một cách hiệu quả với quyền lợi tích lũy nhiều lần/năm. Quyền lợi bảo hiểm gấp 500 lần phí tích lũy...",
    commission: "36%",
    hot: true,
  },
  {
    id: 16,
    icon: Icons.votoIc,
    name: "Vật chất Ô tô",
    content:
      "Giúp bạn bảo vệ chiếc xe thân yêu khỏi nhiều rủi ro bất ngờ, nằm ngoài sự kiểm soát như: đâm va, lật, đổ, rơi, chìm, cháy, nổ, bị vật khác va chạm hay những tai họa bất khả kháng… với mức bồi thường lên đến 100% giá trị xe...",
    commission: "13%",
    hot: true,
  },
  // {
  //   id: 17,
  //   icon: Icons.bhytIc,
  //   name: "BHYT",
  //   content:
  //     "BHYT là chính sách bảo hiểm của đảng và nhà nước, vì quyền lợi của người lao động",
  //   commission: "5%",
  //   hot: true,
  // },
  {
    id: 1,
    icon: Icons.bbxmIc,
    name: "TNDS BB Xe Máy",
    content:
      "Chỉ với khoản phí nhỏ, giúp bạn an tâm hơn khi đã được đảm bảo tài chính trước rủi ro với Quyền lợi Bảo hiểm lên đến 150.000.000 VNĐ…",
    commission: "58%",
    hot: true,
  },
  // {
  //   id: 2,
  //   icon: Icons.mcxmIc,
  //   name: "Mất cắp mất cướp xe máy",
  //   content:
  //     "Với mức phí hợp lý, sản phẩm bảo vệ tài sản xe máy của bạn khỏi nạn trộm cắp, mất cướp. Bồi thường theo giá trị thực tế của xe, tối đa lên đến 50 triệu đồng…",
  //   commission: "42%",
  // },
  // {
  //   id: 3,
  //   icon: Icons.dotquyIc,
  //   name: "Đột Quỵ",
  //   content:
  //     "An tâm sức khỏe với phạm vi bảo hiểm rộng với phạm vi Tử vong, thương tật vĩnh viễn do Đột quỵ. Quyền lợi tối đa lên đến 100.000.000 VNĐ…",
  //   commission: "30%",
  // },
  {
    id: 4,
    icon: Icons.bbotoIc,
    name: "TNDS BB Ô Tô",
    content:
      "An tâm trên mọi hành trình với mức phí bảo hiểm hợp lý. Quyền lợi bảo hiểm lên đến 150 triệu đồng/người/vụ. Dịch vụ bồi thường nhanh chóng, chuyên nghiệp…",
    commission: "37%",
  },
  {
    id: 5,
    icon: Icons.tainanIc,
    name: "Tai Nạn",
    content:
      "Bảo vệ bạn khỏi rủi ro khi bị tai nạn sinh hoạt, tai nạn giao thông, tai nạn lao động và ngộ độc,.., quyền lợi bảo vệ lên đến 100 triệu đồng...",
    commission: "29%",
  },
  // {
  //   id: 6,
  //   icon: Icons.bhngheoIc,
  //   name: "Bệnh Hiểm Nghèo",
  //   content:
  //     "An tâm tận hưởng cuộc sống khi được bảo vệ tài chính trước 14 bệnh hiểm nghèo. Chi trả 100% số tiền bảo hiểm khi mắc bệnh hiểm nghèo...",
  //   commission: "18%",
  // },
  // {
  //   id: 7,
  //   icon: Icons.ecIc,
  //   name: "Easy Care",
  //   content:
  //     "Bảo hiểm giúp bạn giảm gánh nặng tài chính, trợ cấp nằm viện do ốm đau bệnh tật, tai nạn đến 1.200.000 VNĐ/ngày, Quyền lợi chính tối đa 36.000.000 VNĐ,có thể đăng ký theo gia đình…",
  //   commission: "20%",
  // },
  {
    id: 8,
    icon: Icons.bhnhaIc,
    name: "Home Care",
    content:
      "Bảo vệ tổ ấm của bạn trước các rủi ro cháy nổ, giông, bão, lũ lụt... và các rủi ro bất ngờ khác…",
    commission: "28%",
  },
  // {
  //   id: 9,
  //   icon: Icons.gcIc,
  //   name: "GC Care",
  //   content:
  //     "Sản phẩm bảo hiểm sức khỏe giúp bạn giảm gánh nặng chi phí trước nhiều quyền lợi ưu việt: nằm viện, khám và điều trị ngoại trú, tử vong do tai nạn, ốm bệnh, thai sản...",
  //   commission: "22%",
  // },
  // {
  //   icon: Icons.tcIc,
  //   name: "Tomato Combo",
  //   content:
  //     "Đồng hành cùng doanh nghiệp với mong muốn gia tăng phúc lợi người lao động, Global Care tặng doanh nghiệp Bảo hiểm TOMATO với quyền lợi bảo vệ lên đến 1 TỶ đồng khi mua Combo TNDS BB xe máy.",
  //   commission: "50%",
  // },
  // {
  //   id: 12,
  //   icon: Icons.trucotIc,
  //   name: "Trụ Cột Gia Đình",
  //   content:
  //     "An tâm vui sống khi người trụ cột gia đình được bảo vệ trước các rủi ro, gia đình đỡ lo tài chính về Bệnh đặc biệt – Đột quỵ, Dịch bệnh – Bệnh thông thường – Bệnh khác, trợ cấp nằm viện lên đến 180 ngày/năm...",
  //   commission: "23,33%",
  // },
  // {
  //   icon: Icons.bhxhIc,
  //   name: "BHXH",
  //   content:
  //     "BHXH là một trong những chính sách an sinh hữu ích đối với người tham gia, do Nhà nước tổ chức và được bảo đảm thực hiện.",
  //   commission: "10%",
  // },
  // {
  //   id: 18,
  //   icon: Icons.bmcareIc,
  //   name: "BM Care",
  //   content:
  //     "Bảo vệ sức khỏe gia đình bạn để an nhiên vui sống với ba gói bảo hiểm tha hồ chọn lựa là Gói phổ thông, Gói cao cấp và Gói thượng hạng, quyền lợi tối đa 150 triệu đồng...",
  //   commission: "31%",
  // },
  // {
  //   id: 13,
  //   icon: Icons.mriderIc,
  //   name: "Master Rider",
  //   content:
  //     "Giải pháp bảo hiểm vật chất mô tô – xe máy thông minh chỉ với một khoản chi phí nhỏ, bạn đã có thể bảo vệ toàn diện cho chiếc xe đồng hành hàng ngày trước các rủi ro bị mất cắp, mất cướp hoặc hư hỏng do tai nạn, cháy nổ, thiên tai với quy trình bồi thường trực tuyến, minh bạch và nhanh chóng...",
  //   commission: "33%",
  // },

  {
    id: 14,
    icon: Icons.travelIc,
    name: "Du lịch",
    content:
      "Để tận hưởng chuyến du lịch một cách trọn vẹn nhất, bảo vệ bạn toàn diện trước các rủi ro từ tính mạng, sức khỏe đến thiệt hại về hành lý và tư trang… với nhiều lựa chọn linh hoạt về Gói quyền lợi khi tham gia...",
    commission: "36%",
  },
  {
    id: 11,
    icon: Icons.comboxmIc,
    name: "Combo TNDS BB Xe máy",
    content:
      "Mua Bảo hiểm TNDS BB với số lượng lớn và thu nhập cao, giúp bạn chủ động và tiết kiệm hơn, an tâm trên mỏi nẻo đường và hiệu lực bảo hiểm linh động...",
    commission: "35%",
  },
];

export const list_story = [
  {
    icon: Images.Avt1,
    name: "Phạm Thị Ngân",
    comment:
      "Với nền tảng mà GSale cung cấp tôi rất yên tâm vì có tính bảo mật cao cùng thu nhập hấp dẫn. Đặc biệt là không bị ràng buộc về thời gian, rất phù hợp với các mẹ bỉm sữa như tôi. Mỗi tháng nếu chăm chỉ thì thu nhập không dưới 10 triệu đồng.",
  },
  {
    icon: Images.Avt2,
    name: "Nguyễn Văn Trí",
    comment:
      "Trở thành Cộng tác viên của GSale thật dễ dàng, giá như tôi biết sớm hơn thì đã có thêm thu nhập thời sinh viên. Kiếm tiền tăng thu nhập qua Online 100%, giúp tôi tiết kiệm được rất nhiều thời gian. Trong khi vừa tư vấn, tôi có thể tra cứu thông tin trực tiếp ngay trên điện thoại nhanh chóng, dễ dàng vào bất kì lúc nào và bất kể nơi đâu.",
  },
  {
    icon: Images.Avt3,
    name: "Lê Thị Bảo Ngọc",
    comment:
      "Tôi gần như mù Công nghệ nhưng khi tham gia sử dụng với GSale và được sự hướng dẫn nhiệt tình từ đội ngũ CSKH tại đây, tôi đã có thể tự mình kiếm được 5-10 triệu mỗi tháng nhờ giao diện dễ dùng và tiện ích này.",
  },
  {
    icon: Images.Avt4,
    name: "Nguyễn Tường Vi",
    comment:
      "Kiếm tiền tăng thu nhập qua Online 100%, giúp tôi tiết kiệm được rất nhiều thời gian. Trong khi vừa bán hàng, tôi có thể tra cứu thông tin trực tiếp ngay trên điện thoại nhanh chóng, dễ dàng vào. ",
  },
  {
    icon: Images.Avt5,
    name: "Trần Thị Hoàng Lan",
    comment:
      "Đó giờ tôi chỉ mua Bảo hiểm bắt buộc xe máy với ô tô cho chồng con tôi qua người quen hoặc đại lý nhưng lấy hoa hồng rất cao, cũng như không biết cách tự mua. Được bạn bè giới thiệu, tôi biết đến bảo hiểm qua Global Care tự mình trải nghiệm qua công nghệ hiện đại mà được giảm giá 30% nên tôi rất hài lòng. Sẽ ủng hộ lâu dài. Cảm ơn Global Care nhé!",
  },
  {
    icon: Images.Avt6,
    name: "Trần Đình Thưởng",
    comment:
      "Nhờ GSale mà mỗi tháng tôi đều có thêm thu nhập ổn định Từ bán được 1 đơn thành nhiều đơn và nhận nhiều giải thưởng tiền mặt qua các chương trình hấp dẫn từ Công ty, giúp anh Thưởng có động lực cố gắng hơn.",
  },
  {
    icon: Images.Avt7,
    name: "Nguyễn Thị Thanh Tuyền",
    comment:
      "Mình rất thích GSale Sản phẩm trên GSale rất rõ ràng, mà hoa hồng cũng cao. Chị và nhiều bạn bè của chị rất thích và tin tưởng GSale. Sản phẩm thích nhất là Bảo hiểm Tai nạn Cá nhân, sản phẩm này mua vừa rẻ lại bồi thường cao.",
  },
  {
    icon: Images.Avt8,
    name: "Nguyễn Việt Nga",
    comment:
      "Tôi biết và tin tưởng GSale được hơn 4 năm rồi Đối với chị Nga, GSale có giao diện thân thiện, dễ sử dụng. Đặc biệt, không cần thủ tục, giấy tờ phức tạp như bảo hiểm truyền thống nữa. Sản phẩm Bảo hiểm Tai nạn Cá nhân là sản phẩm chị yêu thích và bán chạy suốt 4 năm nay.",
  },
  {
    icon: Images.Avt9,
    name: "Tân Mỹ Quân",
    comment:
      "Chị Mỹ Quân thích GSale vì mua bán bảo hiểm, rút tiền nhanh tiện, thao tác đơn giản. Bảo hiểm chị Mỹ Quân yêu thích là Bảo hiểm Mất cắp Mất cướp Xe máy. Thời gian rảnh chị tìm hiểu về tính năng GSale và giới thiệu sản phẩm đến bạn bè, người thân.Giờ đây, chị đã có thêm 1 khoản thu nhập thêm cho mình một cách dễ dàng.",
  },
  {
    icon: Images.Avt10,
    name: "Thùy Dung",
    comment:
      "Dễ dàng tìm hiểu quyền lợi bảo hiểm, so sánh mức phí giữa các nhà bảo hiểm và tham gia sản phẩm ngay trên app. Chị Dung vừa tham gia Bảo hiểm Tiết kiệm Hưu trí trên GSale. Chị rất ấn tượng với sản phẩm vì vừa có chương trình Bảo hiểm Sức khỏe, vừa có khoản đầu tư, góp tích lũy hàng tháng. Được biết tài khoản đầu tư này qua Quỹ VFF của Quỹ đầu tư hàng đầu VinaCapital nên chị càng an tâm hơn.",
  },
];

export const support_info = [
  {
    q: "GSale là gì?",
    a: "GSale là ứng dụng đa nền tảng của Global Care, giúp bạn tìm hiểu các chương trình bảo hiểm và nâng cao thu nhập thật dễ dàng thông qua chính sách hấp dẫn dành cho cộng tác viên.",
  },
  {
    q: "GSale thuộc công ty nào?",
    a: "GSale là sản phẩm công nghệ thuộc Công ty Cổ Phần Tư vấn Global Care - Công ty chuyên cung cấp các giải pháp công nghệ phục vụ cho việc bán hàng và quản lý bán hàng của các đại lý và đối tác kinh doanh trong lĩnh vực bảo hiểm phi nhân thọ.",
  },
  {
    q: "Sử dụng GSale để kiếm tiền có an toàn không?",
    a: "Khi tham gia kiếm tiền cùng GSale chúng tôi đảm bảo:",
    a1: [
      "Các hoạt động trên GSale được bảo mật thông tin và quyền riêng tư người dùng theo tiêu chuẩn quốc tế về Bảo Mật An Ninh Thông Tin",
      "Các nhà cung cấp dịch vụ trên GSale là những công ty uy tín hàng đầu Việt Nam như MIC, PVI, PTI, Bảo Minh, PJICO,…",
      "Bạn sẽ nhận được sự hỗ trợ nhiệt tình cùng mức thu nhập hấp dẫn khi tham gia cùng chúng tôi.",
    ],
  },
  {
    q: "Người không có kinh nghiệm, không có vốn có tham gia GSale được không?",
    a: (
      <div>
        <span>
          Bất cứ ai cũng có thể tham gia tăng thu nhập trên GSale mà không phân
          biệt ngành nghề hay trình độ học vấn bằng cách:
        </span>
        <div className="li">
          <span className="underline">Bước 1:</span> Tải app GSale, điền mã kích
          hoạt và đăng ký Cộng tác viên (CTV) trên GSale, giới thiệu các sản
          phẩm bảo hiểm dưới sự dẫn dắt của trưởng nhóm với nhiều năm kinh
          nghiệm trong lĩnh vực bảo hiểm.
        </div>
        <div className="li">
          <span className="underline">Bước 2:</span> Tham gia tư vấn bán bảo
          hiểm.
        </div>
        <div className="li">
          <span className="underline">Bước 3:</span> Nhận tiền ngay trong ví khi
          bán sản phẩm thành công.
        </div>
      </div>
    ),
  },
  {
    q: "Tính năng ưu việt của GSale?",
    a1: [
      "Hoa hồng chi trả tức thì sau khi hoàn tất đơn",
      "Đa đạng hình thức thanh toán chuyển khoản, thẻ ATM, thẻ VISA, Ví...",
      "Có thể dễ dàng áp dụng mô hình affiliate marketing",
      "Có hệ thống quản lý xây dựng phát triển đội nhóm không giới hạn",
      "Các sản phẩm đa dạng với nhiều đối tượng",
      "Có hỗ trợ hệ thống báo cáo phân tích số liệu kinh doanh",
      "Sử dụng đa nền tảng",
      "Được các chuyên gia nhiều năm hỗ trợ tư vấn",
    ],
  },
  {
    q: "Thao tác mua bảo hiểm trên GSale có khó không?",
    a: "GSale luôn luôn cố gắng đem đến sự thoải mái, tiện lợi nhất cho Khách hàng nên việc mua bảo hiểm trên GSale rất dễ dàng. Chỉ với vài thao tác là bạn có thể hoàn tất việc mua sản phẩm và vô vàn chương trình ưu đãi. Hãy trải nghiệm ngay bạn nhé.",
  },
  {
    q: "Tôi phải ký bao nhiêu giấy tờ, thủ tục để mua bảo hiểm?",
    a: "GSale có mặt để giúp bạn không phải làm những việc đó. Bạn không cần phải ký giấy tờ gì để mua các sản phẩm của GSale. Không còn những giấy tờ làm bạn rối trí, Giấy Chứng Nhận Bảo Hiểm điện tử sẽ được gửi qua Email, ZNS và ứng dụng GSale của bạn.",
  },
  {
    q: "Hướng dẫn đăng ký và cài đặt GSale?",
    a: (
      <div>
        <span>
          Chỉ cần 1 chiếc điện thoại thông minh được kết nối internet bạn đã có
          thể đăng ký được GSale theo các bước sau:
        </span>
        <div className="li">
          <span className="underline">Bước 1:</span> Truy cập vào cửa hàng ứng
          dụng Appstore (iOS) hoặc CH Play (Android) hoặc quét mã QR sau:
        </div>
        <div className="text-center">
          <img src={QrIcon} alt="qr-icon" className="qr-code" />
        </div>
        <div className="li">
          <span className="underline">Bước 2:</span> Tìm từ khóa GSale và cài
          đặt ứng dụng
        </div>
        <div className="li">
          <span className="underline">Bước 3:</span> Mở ứng dụng GSale vừa cài
          đặt và bấm vào [Đăng ký] làm theo hướng dẫn
        </div>
        <div className="li">
          <span className="underline">Bước 4:</span> Nhập mã OTP và hoàn tất
          đăng ký.
        </div>
        <span>
          Như vậy, bạn đã hoàn thành việc đăng ký tài khoản trên GSale, hãy mở
          ứng dụng và trải nghiệm các tiện ích trên GSale ngay nhé.
        </span>
      </div>
    ),
  },
  {
    q: "Cách liên hệ GSale nhanh chóng khi cần hỗ trợ?",
    a: "GSale luôn bên bạn để giải đáp mọi thắc mắc, bạn có thể liên hệ chúng tôi qua các kênh:",
    a1: [
      <div>
        Hotline: <a href="tel:1900292987">1900 2929 87</a>
      </div>,
      <div>
        Zalo OA:{" "}
        <a
          href="https://zalo.me/1747508283619492630?gidzl=I1mh7ODwI1171YeUWc1B44W7B1cLPHiKK0H_JS9d74DK3Ib9aMOV4m07UHAMC4zB0GOj5pVpKbCuYdn04m"
          target="_bank"
        >
          GLOBAL CARE
        </a>
      </div>,
      <div>
        Email: <a href="mailto:cskh@globalcare.vn">cskh@globalcare.vn</a>
      </div>,
      <div>
        Website:
        <a href="www.gsale.com.vn" target="_bank">
          www.gsale.com.vn
        </a>
      </div>,
    ],
  },
  {
    q: "Cách kiếm tiền trên GSale?",
    a: "Có rất nhiều gói bảo hiểm tích hợp trên GSale như: Bảo hiểm dân sự bắt buộc (TNDS BB) ô tô, bảo hiểm TNDS BB xe máy, bảo hiểm đột quỵ, bảo hiểm tai nạn Tomato,... mức thu nhập cho các sản phẩm bảo hiểm trên GSale cực kỳ hấp dẫn, lên đến 50% giá trị hợp đồng. Công việc bạn cần làm là:",
    a1: [
      "Mua các sản phẩm cho bản thân mình",
      "Tìm khách hàng có nhu cầu mua các sản phẩm bảo hiểm",
      "Chọn sản phẩm mà khách hàng muốn mua",
      "Nhập thông tin khách hàng và hoàn tất thanh toán",
      "Nhận tiền ngay sau khi thanh toán thành công.",
    ],
  },
  {
    q: "Bảo hiểm trên GSale do bên nào cung cấp?",
    a: "GSale chỉ cung cấp các gói bảo hiểm tốt nhất của những công ty bảo hiểm uy tín nhất trên thị trường được bảo hộ bởi các công ty bảo hiểm lớn như MIC, PVI, PTI, Bảo Minh, PJICO,... Có những sản phẩm chỉ được phân phối độc quyền qua GSale - thể hiện sự tin tưởng của những công ty bảo hiểm gửi trọn nơi GSale.",
  },
  {
    q: "Tôi muốn sửa đơn hàng đã thanh toán qua GSale thì làm như thế nào?",
    a: (
      <div>
        Bạn vui lòng gửi thông tin cần sửa đổi về email:{" "}
        <a href="mailto:cskh@globalcare.vn">cskh@globalcare.vn</a>. Sau đó, bạn
        sẽ nhận được 01 sửa đổi bổ sung với thông tin chính xác đã cung cấp qua
        email.
      </div>
    ),
  },
  {
    q: "Tôi sẽ nhận giấy tờ gì để đảm bảo quyền lợi?",
    a: (
      <div>
        Chúng tôi sẽ gửi cho bạn 01 Giấy Chứng Nhận Bảo Hiểm Điện Tử - được xem
        là Hợp Đồng Bảo Hiểm chính thức đảm bảo về mặt pháp lý theo quy định
        pháp luật (theo{" "}
        <a
          href="https://globalcare.com.vn/wp-content/uploads/2022/07/nghi-dinh-so-03_2021_nd-cp_15.01.2021-1.pdf"
          target="_bank"
        >
          {" "}
          Nghị định 03/2021/NĐ-CP
        </a>
        ,{" "}
        <a
          href="https://drive.google.com/file/d/1b5F86AiBLZOaVZfrI38uwfsZsDYNc66Z/view"
          target="_bank"
        >
          Công văn 813/BCA-C08
        </a>
        ,{" "}
        <a
          href="https://drive.google.com/file/d/1ijol1zjBRC3V6_w6NMqIwkvghAJwgRvZ/view"
          target="_bank"
        >
          Thông tư 04/2021/TT_BTC{" "}
        </a>
        )
      </div>
    ),
  },
  {
    q: "Hiện GSale đã có mặt trên hệ điều hành nào?",
    a: "GSale hiện đã có trên Android và iOS. Chúng tôi hiện diện ở mọi nơi để chăm sóc bạn.",
  },
  {
    q: "Cách tra cứu thông tin bảo hiểm Online?",
    a: (
      <div>
        Bạn truy cập vào đường link này:{" "}
        <a href="www.gsale.com.vn" target="_bank">
          https://tracuu.globalcare.vn/
        </a>{" "}
        và nhập CCCD/CMND
      </div>
    ),
  },
  {
    q: "Khi khách hàng phát sinh sự cố cần bồi thường, họ có cần liên hệ ai để xử lý ?",
    a: (
      <div>
        Khi xảy ra sự cố khách hàng nên gọi ngay đến hotline của công ty bảo
        hiểm. Nhân viên bán hàng có nhiệm hướng dẫn/ hỗ trợ khách hàng thực hiện
        đúng quy trình bồi thường của công ty bảo hiểm.
        <br />
        Hoặc gọi tới Hotline: <a href="tel:1900292987">1900 2929 87</a> trong
        giờ làm việc hoặc{" "}
        <a
          href="https://zalo.me/1747508283619492630?gidzl=I1mh7ODwI1171YeUWc1B44W7B1cLPHiKK0H_JS9d74DK3Ib9aMOV4m07UHAMC4zB0GOj5pVpKbCuYdn04m"
          target="_bank"
        >
          {" "}
          Zalo OA Global Care{" "}
        </a>{" "}
        để được hướng dẫn nhanh chóng nhất 24/7.
      </div>
    ),
  },
];

export const salekits = [
  {
    id: 1,
    name: "TNDS-BB-Xe-May",
    link: "https://g2.globalcare.vn/file/d72cde9d-92cd-4c47-9a2b-7ba09fac9f33",
  },
  {
    id: 2,
    name: "MCMC-Xe-May",

    link: "https://g2.globalcare.vn/file/038c1e1c-cf7e-4b40-866a-d6268becad7d",
  },
  {
    id: 3,
    name: "Dot-Quy",
    link: "https://g2.globalcare.vn/file/d07b686e-7185-4c63-ac44-b6d52bb710e4",
  },
  {
    id: 4,
    name: "TNDS-BB-O-To",
    link: "https://g2.globalcare.vn/file/ee5e68ab-0dd5-4185-906f-2d8f2ceeba73",
  },
  {
    id: 5,
    name: "Tai-Nan",
    link: "https://g2.globalcare.vn/file/9336cd35-ec5b-4f95-bfa9-72dbc6dbfb9f",
  },
  {
    id: 6,
    name: "Benh-Hiem-Ngheo",
    link: "https://g2.globalcare.vn/file/4e511f35-cc54-4511-b383-c9833800ba5e",
  },
  {
    id: 8,
    name: "Nha-Tu-Nhan",
    link: "https://g2.globalcare.vn/file/3f4ac826-0091-4628-9dbf-ec9a3ba062d2",
  },
  {
    id: 9,
    name: "GC-Care",
    link: "https://g2.globalcare.vn/file/02801f66-88c2-4d95-ae87-f425b48f280d",
  },
  {
    id: 10,
    name: "Tomato",
    link: "https://g2.globalcare.vn/file/fb9b7fec-6dac-4f4f-bf73-f28ecbe9ec33",
  },
  {
    id: 12,
    name: "Tru-Cot",
    link: "https://g2.globalcare.vn/file/6e3907ef-7695-45f6-986e-1fa604f25952",
  },
  // {
  //   id: 13,
  //   name: "Master-Rider",
  //   link: "https://g2.globalcare.vn/file/140d0722-e590-4ab1-aa6d-a3638c5fa509",
  // },
  // {
  //   id: 18,
  //   name: "BM-Care",
  //   link: "https://g2.globalcare.vn/file/3e95d48c-7e84-405f-99a4-02a8dcb09776",
  // },
  {
    id: 14,
    name: "Du-Lich",
    link: "https://g2.globalcare.vn/file/ee6ceb3d-0d11-4260-89b2-0e3fbfbd0842",
  },
  {
    id: 15,
    name: "Tiet-kiem-huu-tri",
    link: "https://g2.globalcare.vn/file/f258a82a-7b27-466f-86a1-5cf467858d85",
  },
  {
    id: 16,
    name: "Vat-Chat-Xe",
    link: "https://g2.globalcare.vn/file/2dfad9d8-6965-4bc7-8808-671e3f146bd9",
  },
  // {
  //   id: 19,
  //   name: "suc-khoe-vang",
  //   link: "https://g2.globalcare.vn/file/be91147b-ce0c-421a-ad9d-871bd345c59a",
  // },
  {
    id: 20,
    name: "nha-tu-nhan",
    link: "https://g2.globalcare.vn/file/4493c449-7acd-4672-aa3e-1b489597798e",
  },
  {
    id: 21,
    name: "VBI Care",
    link: "https://g2.globalcare.vn/file/2729c0f3-536a-46f0-acd3-f035a789771b",
  },
  {
    id: 22,
    name: "Family Care",
    link: "https://g2.globalcare.vn/file/3159d4c5-3f40-4517-b62a-e8e415dc32f4",
  },
];

// ** HARD DATA - REFLEX FROM WORD FILE

export const tnds_bb_xm = (
  <>
    <div>
      - Bảo hiểm bắt buộc trách nhiệm dân sự là bảo hiểm bắt buộc (TNDSBB) theo
      quy định của nhà nước đồng thời giúp bạn có đủ tài chính để bồi thường cho
      người khác khi không may bạn gây ra tai nạn cho người đó. Nếu không tham
      gia, bạn sẽ bị xử phạt theo quy định của pháp luật khi tham gia giao
      thông.
      <br /> - Bảo hiểm tai nạn người ngồi trên xe (NNTX) là bảo hiểm cho những
      thiệt hại về tính mạng, thương tật thân thể của người ngồi trên xe máy do
      tai nạn khi tham gia giao thông
      <br />
      ❓Bạn đang băn khoăn vì không biết mua bảo hiểm TNDS xe máy ở đâu?
      <br />
      ❓Bảo hiểm xe máy của bạn sắp hết hạn?
      <br />
      🛵 Gói bảo hiểm TNDS BB xe máy sẽ mang đến cho bạn sự bảo vệ về mặt tài
      chính trước những rủi ro phát sinh với bên thứ ba, thiệt hại thân thể của
      người ngồi trên xe.
    </div>
    Khi tham gia gói bảo hiểm bạn sẽ nhận được những quyền lợi sau:
    <div>
      📌 Bảo vệ tính mạng và tài sản của bên thứ 3<br />
      📌 Bảo vệ ví tiền của bạn trước những rủi ro phát sinh khi tham gia giao
      thông
      <br />
      📌 Được chi trả bởi nhà bảo hiểm hàng đầu Việt Nam
      <br />⚡ Chỉ từ 66.000 đồng sở hữu ngay gói bảo hiểm TNDS BB Xe Máy quyền
      lợi lên đến 150 triệu đồng, Tham gia ngay nhé!
    </div>
    <div className="text-bold">Quyền lợi:</div>
    <div className="cell-4"></div>
    <div className="row-table bg-lgreen">
      <div className="border cell-4">Quyền lợi bảo hiểm</div>
      <div className="border cell-6"></div>
    </div>
    <div className="row-table">
      <div className="border cell-4">Bồi thường thiệt hại về người</div>
      <div className="border cell-6">150.000.000 VNÐ/người/vụ</div>
    </div>
    <div className="row-table">
      <div className="border cell-4">Bồi thường thiệt hại về tài sản</div>
      <div className="border cell-6">50.000.000 VNÐ/vụ</div>
    </div>
    <div>Chi trả cho người ngồi trên xe:</div>
    <div className="ml-25">
      - 10.000.000 đồng/1 người đối với trường hợp Tử vong do tai nạn;
      <br />- Chi trả theo Bảng tỷ lệ thương tật đối với các Thương tật thân thể
      (thương tật bộ phận vĩnh viễn, thương tật tạm thời) do tai nạn, tối đa đến
      10.000.000 đồng/người/vụ.
    </div>
    <div className="text-bold">Đối tượng:</div>
    <div>Ðối tượng mua bảo hiểm</div>
    <div className="ml-25">
      - Là chủ sở hữu xe cơ giới hoặc được chủ sở hữu xe cơ giới giao chiếm hữu,
      sử dụng hợp pháp xe cơ giới.
    </div>
    <div>Ðối tượng được bảo hiểm</div>
    <div className="ml-25">
      - Là bên thứ ba là người bị thiệt hại về sức khoẻ, tính mạng, tài sản do
      xe cơ giới gây ra, trừ những người sau:
      <br />- Người lái xe, người trên chiếc xe đó
      <br />- Chủ xe (trừ trường hợp chủ xe đã giao xe đó cho tổ chức/ cá nhân
      khác chiếm hữu, sử dụng chiếc xe đó.
    </div>
    <div className="text-bold">Quy tắc:</div>
    <div className="ml-25">
      - Bảo hiểm bắt buộc trách nhiệm dân sự: theo Thông tư số 04/2021/TT-BTC
      ngày 15/01/2021 của Bộ Tài Chính và Nghị Định 03/2021/NĐ-CP ngày
      15/01/2021 của Chính Phủ. Trường hợp quy tắc Bảo hiểm bắt buộc trách nhiệm
      dân sự đính kèm Hợp Đồng này bị sửa đổi, bổ sung do Bộ Tài Chính/Chính Phủ
      thay đổi hoặc ban hành Thông tư hoặc Nghị định mới, Global Care thông báo
      cho đối tác về nội dung thay đổi trước thời điểm áp dụng.
      <br />- Bảo hiểm tai nạn người ngồi trên xe (NNTX): theo Quy tắc bảo hiểm
      tự nguyện xe mô tô – xe máy ban hành kèm theo Quyết định số
      104/2016/QĐ-MIC ngày 01/01/2016 của Tổng Giám Đốc Tổng Công ty Cổ phần Bảo
      hiểm Quân đội. Trường hợp Quy tắc Bảo hiểm tự nguyện xe mô tô – xe máy
      đính kèm Hợp Đồng này bị sửa đổi, bổ sung do Công Ty Bảo Hiểm thay đổi
      hoặc ban hành quy định mới, Global Care thông báo cho đối tác về nội dung
      thay đổi trước thời điểm áp dụng.
    </div>
    <div className="text-bold">Phạm vi:</div>
    <div className="ml-25">
      <div>
        - Đối với Bảo hiểm TNDSBB: Trách nhiệm của Người mua bảo hiểm đối với
        các Thiệt hại về thân thể, tính mạng và tài sản của người khác (bên thứ
        ba) do xe của Người mua bảo hiểm gây ra.
        <br />- Đối với bảo hiểm NNTX: Thiệt hại về tính mạng và thương tật thân
        thể của người lái xe và người ngồi trên xe của Người mua bảo hiểm khi có
        tai nạn do xe của Người mua bảo hiểm gây ra.
      </div>
    </div>
    <div className="text-bold">Các điểm loại trừ:</div>
    <div className="ml-25">
      <div>
        - Hành động cố ý gây thiệt hại của chủ xe cơ giới, người lái xe hoặc
        người bị thiệt hại.
        <br />
        - Người lái xe gây tai nạn cố ý bỏ chạy không thực hiện trách nhiệm dân
        sự của chủ xe cơ giới. Trường hợp người lái xe gây tai nạn cố ý bỏ chạy
        nhưng đã thực hiện trách nhiệm dân sự của chủ xe cơ giới thì không thuộc
        trường hợp loại trừ trách nhiệm bảo hiểm.
        <br />- Người lái xe chưa đủ độ tuổi hoặc quá độ tuổi điều khiển xe cơ
        giới theo quy định của pháp luật về giao thông đường bộ;
      </div>
      <div>- Xem thêm Số 03/2021/NĐ-CP</div>
    </div>
    <div className="text-bold">Hồ sơ/Quy trình yêu cầu bồi thường:</div>
    <div>
      Hồ sơ bồi thường sẽ được Giám định viên Công ty Bảo hiểm yêu cầu cung cấp
      theo mỗi tình huống, dựa theo Quy định của Thông tư số 04/2021/TT-BTC ban
      hành ngày 15/01/2021 của Bộ Tài Chính và Nghị Định số 03/2021/NĐ-CP ban
      hành ngày 15/01/2021 của Chính Phủ.
      <br />
      Hãy ngay lập tức sơ cấp cứu cho người bị thương (nếu có), sau đó thực hiện
      các bước sau đây:
    </div>
    <div className="ml-25">
      <div>
        - B1: Gọi công an giao thông nơi gần nhất
        <br />
        - B2: Gọi ngay cho số hotline của Công Ty Bảo Hiểm để báo sự cố bảo hiểm
        và được hướng dẫn. Trong thời hạn 5 ngày làm việc kể từ ngày xảy ra tai
        nạn (trừ trường hợp bất khả kháng) bên mua bảo hiểm, người được bảo hiểm
        phải gửi thông báo tai nạn bằng văn bản hoặc thông qua hình thức điện tử
        cho doanh nghiệp bảo hiểm.
        <br />- B3: Chờ công an hoặc Giám định viên của Công Ty Bảo Hiểm xuống
        hiện trường xử lý. Trường hợp thiệt hại không lớn, Giám định viên sẽ
        hướng dẫn thoả thuận mức đền bù cho bên thứ ba cùng với khách hàng.{" "}
        <br />
        - B4: Khách hàng bổ sung đầy đủ hồ sơ mà Giám định viên yêu cầu để được
        nhận bồi thường.
        <br />- B5: Công ty Bảo hiểm thanh toán bồi thường cho người được bảo
        hiểm trong vòng 15 (mười lắm) ngày làm việc kể từ khi nhận được đầy đủ
        hồ sơ yêu cầu bồi thường bảo hiểm hợp lệ và không quá 30 (ba mươi) ngày
        làm việc kể từ khi nhận được đầy đủ hồ sơ yêu cầu bồi thường bảo hiểm
        hợp lệ trong trường hợp phải tiến hành xác minh hồ sơ.
      </div>
    </div>
    <div>
      Trường hợp từ chối bồi thường, Công ty Bảo hiểm sẽ thông báo bằng văn bản
      cho Người được bảo hiểm biết lý do từ chối bồi thường trong thời hạn 15
      (mười lăm) ngày làm việc kể từ ngày nhận được đầy đủ hồ sơ yêu cầu bồi
      thường bảo hiểm hợp lệ.
    </div>
  </>
);
// export const mcmc_xm = (
//   <>
//     <div className="bold">
//       Sản phẩm Bảo hiểm mất cắp, mất cướp xe máy được thiết kế nhằm giảm thiểu
//       thiệt hại cho tài xế và chủ xe trong các trường hợp không may xảy ra trộm
//       cắp, mất cướp xe máy, xe mô tô, xe máy điện,…
//       <br />
//       🏍 Đối với nhiều người chiếc xe máy không chỉ là một phương tiện giúp chúng
//       ta kiếm thêm thu nhập mà còn là người bạn đồng hành cùng mình trên mọi nẻo
//       đường.
//       <br />
//       🤔 Thử tưởng tượng một ngày khi tự dưng “người bạn” này không cánh mà bay
//       thì hỡi ơi chủ nhân sẽ bàng hoàng, hoang mang biết mấy. Vậy tại sao không
//       trang bị cho xe máy của mình với gói bảo hiểm mất cắp, mất cướp tại GSale
//       để chủ động đề phòng các tình huống xấu nhất có thể xảy ra.
//       <br />
//       Gói bảo vệ trong trường hợp mất cắp, mất cướp xe máy được:
//       <br />
//       🔹Chi trả bởi nhà bảo hiểm uy tín nhất tại Việt Nam
//       <br />
//       🔹Mức chi trả lên đến 50 triệu đồng
//       <br />
//       🔹Phí tham gia cực kỳ ưu đãi chỉ từ 330k/năm
//       <br />
//       👉 Còn chần chừ gì mà không trang bị gói bảo vệ dành cho “người bạn đường
//       của mình ngay nào!
//     </div>
//     <div className="text-bold"> Quyền lợi:</div>
//     <div>
//       - Lái xe hoặc chủ xe có thể lựa chọn gói bảo hiểm tương ứng như sau:
//     </div>
//     <div className="cell-4"></div>
//     <div className="row-table bg-lgreen">
//       <div className="border cell-4 bold">Gói bảo hiểm</div>
//       <div className="border cell-3 bold">Gói 1</div>
//       <div className="border cell-3 bold">Gói 2</div>
//     </div>
//     <div className="row-table">
//       <div className="border cell-4">Giá trị xe tối đa đến</div>
//       <div className="border cell-3">VND 30,000,000</div>
//       <div className="border cell-3">VND 50,000,000</div>
//     </div>
//     <div className="row-table">
//       <div className="border cell-4">Phí bảo hiểm/năm (đã bao gồm VAT)</div>
//       <div className="border cell-3">VND 330,000</div>
//       <div className="border cell-3">VND 550,000</div>
//     </div>
//     <div>
//       - Công ty Bảo hiểm sẽ bồi thường cho Chủ xe theo giá trị thực tế của xe
//       tại thời điểm mua Bảo hiểm và tối đa không vượt quá Số tiền bảo hiểm theo
//       từng Gói.
//     </div>
//     <div className="text-bold">Đối tượng:</div>
//     <div className="ml-25">
//       Tất cả các loại xe máy, xe mô tô, xe máy điện được cấp phép hoạt động tại
//       Việt Nam.
//     </div>
//     <div className="text-bold">Quy tắc:</div>
//     <div className="ml-25">
//       Bảo hiểm bảo hiểm mất cắp, mất cướp xe máy: theo Mục B. Bảo hiểm Mất cắp,
//       Mất cướp toàn bộ xe trong Quy tắc bảo hiểm tự nguyện Vật Chất xe Mô tô –
//       Xe máy ban hành kèm theo Quyết định số 08/QĐ-PTI ngày 10/01/2013 của Tổng
//       Giám Đốc Tổng Công ty Cổ phần Bảo hiểm Bưu Điện. Trường hợp Quy tắc bảo
//       hiểm mất cắp, mất cướp xe máy đính kèm Hợp Đồng này bị sửa đổi, bổ sung do
//       Công Ty Bảo Hiểm thay đổi hoặc ban hành quy định mới, Global Care phải
//       thông báo cho đối tác về nội dung thay đổi trước thời điểm áp dụng.
//     </div>
//     <div className="text-bold">Phạm vi:</div>
//     <div className="ml-25">
//       <div>
//         • Các trường hợp nằm trong phạm vi bảo hiểm
//         <br />- Bị cướp hoặc tấn công bằng vũ lực;
//         <br />
//         - Mất cắp tại nhà, nơi cư trú bị đột nhập, cạy phá có dấu vết và bằng
//         chứng để lại;
//         <br />
//         - Mất cắp tại các điểm trông giữ xe của trường học, bệnh viện, tòa nhà,
//         cơ quan nhà nước, các điểm trông giữ xe được cơ quan nhà nước có thẩm
//         quyền cấp giấy phép hoạt động đồng thời có phiếu, thẻ, vé giữ xe;
//         <br />- Mất cắp, mất cướp trong trường hợp bị tai nạn giao thông;
//         <br />- Đối với các trường hợp mất cắp khác ngoài các trường hợp quy
//         định ở trên thì được bồi thường tối đa 15% số tiền bảo hiểm.
//       </div>
//     </div>
//     <div className="text-bold">Các điểm loại trừ:</div>
//     <div className="ml-25">
//       <div>
//         • Các trường hợp không nằm trong phạm vi bảo hiểm
//         <br />
//         - Hành động cố ý gây tai nạn của Chủ xe, Lái xe, những người được giao
//         sử dụng xe;
//         <br />
//         - Nguyên nhân chiến tranh, các lý do tương tự chiến tranh như nội chiến,
//         đình công, bạo động dân sự, khủng bố;
//         <br />
//         - Mất cắp, mất cướp xảy ra ngoài lãnh thổ nước Cộng hoà xã hội chủ nghĩa
//         Việt Nam (trừ trường hợp có thỏa thuận khác bằng văn bản);
//         <br />
//         - Tổn thất, thiệt hại bị quy cho hành vi lừa đảo, lợi dụng lòng tin của
//         bất kỳ người nào mà theo định nghĩa của Bộ luật hình sự là lừa đảo/tội
//         phạm, lợi dụng lòng tin hoặc do hành vi gian lận, không trung thực,
//         thông đồng với kẻ gian của người được bảo hiểm;
//         <br />- Mất cắp, mất cướp bộ phận của xe;
//         <br />- Người được bảo hiểm không thông báo cho PTI trong vòng 10 ngày
//         kể từ ngày phát hiện ra sự việc (trừ trường hợp bất khả kháng).
//       </div>
//     </div>
//     <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
//     <div className="ml-25">
//       <div>
//         - Hồ sơ bồi thường sẽ được Giám định viên Công ty Bảo hiểm yêu cầu cung
//         cấp theo mỗi tình huống, Danh mục hồ sơ bồi thường bao gồm:
//       </div>
//       <div className="ml-25">
//         + Thông báo tai nạn và yêu cầu bồi thường (Gốc)
//         <br />
//         + Giấy phép Lái xe; Giấy Đăng ký xe (Bản sao công chứng/bản sao có xác
//         thực của công ty Bảo hiểm)
//         <br />
//         Và một số giấy tờ khác tùy theo tình huống mà Công ty Bảo hiểm sẽ yêu
//         cầu thu thập.
//       </div>
//     </div>

//     <div className="text-bold">Quy trình bồi thường</div>
//     <div className="ml-25">
//       <div>
//         - B1. Trình báo công an nơi gần nhất
//         <br />- B2. Làm thông báo tai nạn và yêu cầu bồi thường kèm theo biên
//         bản công an nộp về cty BH hoặc khai báo tại ứng dụng bán hàng hoặc gọi
//         đến Hotline thông báo bồi thường của PTI: 1900 545475 <br />
//         - B3. Trong vòng 60 ngày điều tra kể từ lúc khách hàng khai báo mất xe,
//         công ty bảo hiểm có nhiệm vụ làm việc với cơ quan công an để thu thập hồ
//         sơ (nếu cần).
//         <br />- B4. Ngày thứ 61 nhà bảo hiểm chi trả bồi thường trong trường hợp
//         không tìm lại xe.
//       </div>
//       <div>- B5. Khách hàng ký biên bản từ bỏ xác xe.</div>
//     </div>
//   </>
// );
export const dot_quy = (
  <>
    <div className="bold">
      💊 Đột quỵ là một trong những biến cố dễ dàng lấy đi mạng sống của chúng
      ta nhất, hoặc nếu không thì cũng để lại những di chứng nặng nề suốt đời.
      Và cách tốt nhất để đối phó với nó chính là trang bị cho mình một tấm
      khiên bảo vệ vững chắc.
      <br />
      Với gói bảo hiểm đột quỵ tại GSale bạn sẽ nhận được lợi ích vàng sau:
    </div>
    <div className="ml-25">
      🔹 "Chấp tất" các bệnh đặc biệt (đột quỵ, ung thư, suy thận...)
      <br />
      🔹 Chi phí tham gia thấp chỉ từ 1.000 đồng/ngày
      <br />
      🔹 Mức trách nhiệm tối đa lên đến 100 triệu đồng
    </div>
    <div className="bold">
      🚿 Bảo hiểm đột quỵ không còn là sản phẩm cho người già nữa và hiện nay
      đột quỵ đang “trẻ hóa” với số người trẻ mắc bệnh tăng 2% mỗi năm. Việc
      trang bị cho mình một tấm áo bảo vệ trước những giông tố bất ngờ là vô
      cùng cần thiết. Ngay bây giờ còn chờ đợi gì mà không sở hữu gói bảo hiểm
      đột quỵ cho mình và người thân nhé!
    </div>
    <div className="text-bold">Quyền lợi: </div>
    <div className="table">
      <div className="row-table">
        <div className="col-4-dq bold text-center bg-lgreen">
          Phạm vi bảo hiểm
        </div>
        <div className="col-2-dq bold text-center bg-lgreen">
          Hiệu lực bảo hiểm
        </div>
        <div className="column cell-4-dq bg-lgreen">
          <div className="cell-center bold">Mức trách nhiệm</div>
          <div className="row-table">
            <div className="border cell-5 bold">Gói Vàng</div>
            <div className="border cell-5 bold">Gói Kim Cương</div>
          </div>
        </div>
      </div>
      <div className="row-table">
        <div className="column">
          <div className="col-4-dq h-90">
            Tử vong, thương tật vĩnh viễn do Đột quỵ
          </div>
          <div className="col-4-dq h-90">Trợ cấp nằm viện do Đột quỵ</div>
        </div>
        <div className="col-2-dq text-center">
          Sau 02 ngày, kể từ ngày đóng phí đầy đủ.
        </div>
        <div className="column cell-4-dq">
          <div className="row-table">
            <div className="border cell-5 h-90 bold">50.000.000</div>
            <div className="border cell-5 h-90 bold">100.000.000</div>
          </div>
          <div className="row-table">
            <div className="border cell-5 h-90">
              500.000 đ/ngày Tối đa 30 ngày/ năm
            </div>
            <div className="border cell-5 h-90">
              1.000.000 đ/ngày Tối đa 30 ngày/ năm
            </div>
          </div>
        </div>
      </div>
      <div className="row-table">
        <div className="col-4-dq">Phí bảo hiểm (năm)</div>
        <div className="col-2-dq"></div>
        <div className="row-table cell-4-dq">
          <div className="border cell-5 bold">295.000 đ</div>
          <div className="border cell-5 bold">590.000 đ</div>
        </div>
      </div>
    </div>

    <div>Quyền lợi đối với Đột quỵ: </div>
    <div className="ml-25">
      <div>
        - Trường hợp Người được bảo hiểm bị tử vong thuộc phạm vi bảo hiểm, Bảo
        hiểm Viễn Đông sẽ trả tối đa đến Số tiền bảo hiểm ghi trong Chứng nhận
        bảo hiểm;
        <br />- Trường hợp Người được bảo hiểm bị thương tật vĩnh viễn thuộc
        phạm vi bảo hiểm, Bảo hiểm Viễn Đông sẽ trả số tiền tương ứng với tỷ lệ
        phần trăm thương tật được nêu cụ thể tại Phụ lục 01 của Quy tắc này;
      </div>
    </div>
    <div>Quyền lợi đối với trợ cấp nằm viện do đột quỵ:</div>
    <div className="ml-25">
      <div>
        - Bảo hiểm viễn đông sẽ chi trả trợ cấp nằm viện tương ứng số tiền bảo
        hiểm cho mỗi Ngày nằm viện được thể hiện trong bảng quyền lợi, kể từ
        Ngày nằm viện thứ 3 (ba), nhưng không quá 30 ngày/năm.
        <br />- Tổng mức trách nhiệm tối đa của Bảo hiểm Viễn Đông cho 2 quyền
        lợi trên không vượt qua Số tiền bảo hiểm ghi trên Chứng nhận bảo hiểm.
      </div>
    </div>
    <div className="text-bold">Đối tượng:</div>
    <div>
      Đối tượng bảo hiểm là các công dân Việt Nam, người nước ngoài đang công
      tác, học tập tại Việt Nam từ đủ 18 tuổi và không quá 60 tuổi tại ngày tham
      gia bảo hiểm.
    </div>
    <div className="text-bold">Quy tắc:</div>
    <div>
      Căn cứ vào Quy tắc Bảo hiểm Trụ cột gia đình ban hành kèm theo Quyết định
      số 237/2015/QĐ-VASS/TGĐ ngày 17/09/2015 của Tổng Giám đốc Công ty Cổ phần
      Bảo hiểm Viễn Đông (VASS) (sau đây gọi tắt là Quy tắc) và các điều khoản
      khác được thỏa thuận giữa các bên tại Phụ lục này. Bên B sẽ chi trả bồi
      thường cho Người được bảo hiểm trong trường hợp Người được bảo hiểm bị tai
      nạn bất ngờ xảy ra trong thời hạn bảo hiểm. Trong trường hợp có bất cứ mâu
      thuẫn nào giữa Quy tắc và các điều khoản được quy định tại Phụ lục này,
      Các Bên sẽ ưu tiên áp dụng các điều khoản được quy định tại Phụ lục này.
    </div>
    <div className="text-bold">Phạm vi:</div>
    <div>
      <span className="underline">Phạm vi 1:</span> Tử vong hoặc Thương tật vĩnh
      viễn do tai nạn
      <br />
      Người được bảo hiểm bị tử vong hoặc Thương tật vĩnh viễn do tai nạn mà
      không thuộc các điểm loại trừ của Quy tắc bảo hiểm.
      <br />
      <span className="underline">Phạm vi 2:</span> Tử vong hoặc Thương tật vĩnh
      viễn do Đột quỵ, đột tử và các Bệnh đặc biệt <br />
      Người được bảo hiểm bị tử vong hoặc Thương tật vĩnh viễn do Đột quỵ, đột
      tử và các Bệnh đặc biệt được định nghĩa bên trên mà không thuộc các điểm
      loại trừ của Quy tắc bảo hiểm.
      <br /> <span className="underline">Phạm vi 3:</span> Tử vong do Bệnh thông
      thường, Dịch bệnh và bệnh khác
      <br />
      Người được bảo hiểm bị tử vong do Bệnh thông thường, Dịch bệnh và bệnh
      khác mà không thuộc các điểm loại trừ của Quy tắc bảo hiểm.
      <br />
      <span className="underline">Phạm vi 4:</span> Trợ cấp nằm viện
    </div>
    <div>
      Trường hợp Người được bảo hiểm bị Thương tật vĩnh viễn do tai nạn, đột
      quỵ, bệnh đặc biệt và bệnh thông thường. Bảo hiểm Viễn Đông sẽ chi trả trợ
      cấp nằm viện kể từ Ngày nằm viện thứ 3 (ba).
    </div>
    <div className="text-bold">Các điểm loại trừ:</div>
    <div className="ml-25">
      <div>
        Loại trừ các trường hợp sau:
        <br />• Những người đang bị bệnh tâm thần, phong (cùi, hủi);
        <br />
        • Những người đang có tỷ lệ thương tật vĩnh viễn từ 50% trở lên;
        <br />
        • Người đang trong thời gian điều trị hoặc đang mắc các bệnh đặc biệt.
        <br />
        • Hành động cố ý của Người được bảo hiểm hoặc việc tử vong hay thương
        tật vĩnh viễn của Người được bảo hiểm bị gây ra bởi người thụ hưởng,
        người thừa kế hợp pháp;
        <br />
        • Người được bảo hiểm tham gia các hoạt động thể thao chuyên nghiệp;
        <br />
        • Do ảnh hưởng của rượu, bia, ma túy và các chất kích thích tương tự
        khác;
        <br />• Ngộ độc thức ăn, đồ uống;
        <br />• Bị các bệnh/triệu chứng bẩm sinh; Phẩu thuật thẩm mỹ.
        <br />• Bệnh/thương tật có sẵn;
        <br />
        • Do các bệnh lây lan qua đường tình dục, bệnh giang mai, bệnh lậu, liên
        quan đến các bệnh thuộc hội chứng suy giảm miễn dịch (HIV);
        <br />• Do điều trị hoặc sử dụng thuốc không theo chỉ dẫn của bác sỹ
        hoặc cơ sở y tế.
      </div>
    </div>
    <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
    <div className="ml-25">
      <div>
        1. Giấy chứng nhận bảo hiểm (Bản chính hoặc bản sao);
        <br />
        2. Giấy Thông báo và yêu cầu trả tiền bảo hiểm do Người được bảo
        hiểm/Người thụ hưởng/Người thừa kế hợp pháp điền đầy đủ và ký tên;
        <br />
        3. Bản gốc hay bản sao hợp lệ của Giấy chứng tử (trong trường hợp Tử
        vong), trong một số trường hợp đặc biệt, Bảo hiểm Viễn Đông có thể yêu
        cầu giám định pháp y nếu thấy cần thiết và được pháp luật cho phép;
        <br />
        4. Bản gốc hay bản sao hợp lệ Giấy xác nhận của cơ quan y tế có thẩm
        quyền về tình trạng Thương tật vĩnh viễn của Người được bảo hiểm, các
        chứng từ y tế hợp lệ liên quan đến việc điều trị (Giấy ra viện, Phiếu
        điều trị, Phiếu mổ - trường hợp phải phẫu thuật, Đơn thuốc, hóa đơn,
        biên lai thu tiền viện phí, Phim X – quang,...);
        <br />
        5. Trường hợp Người được bảo hiểm ủy quyền cho người khác nhận số tiền
        bảo hiểm, phải có giấy ủy quyền hợp pháp.
      </div>
    </div>
  </>
);
export const tnds_bb_oto = (
  <>
    <div className="bold">
      Bảo Hiểm Trách Nhiệm Dân Sự Bắt Buộc Ô Tô là loại bảo hiểm bắt buộc khi
      tham gia giao thông buộc các chủ xe ô tô phải xuất hình khi công an tiến
      hành kiểm tra.
      <br />
      Lợi ích vàng của việc tham gia bảo hiểm mà bạn không thể bỏ qua:
    </div>
    <div className="bold ml-25">
      📌 Bảo vệ tính mạng và tài sản của bên thứ 3<br />
      📌 Bảo vệ ví tiền của bạn trước những rủi ro phát sinh khi tham gia giao
      thông
      <br />
      📌 Được chi trả bởi nhà bảo hiểm hàng đầu Việt Nam
    </div>
    <div className="bold">
      Với mức phí cực sốc chỉ từ 480.000 đồng bạn sẽ nhận được quyền lợi chi trả
      lên đến 250 triệu đồng.{" "}
    </div>
    <div className="bold">Tham gia ngay nhé!</div>
    <div className="text-bold">Quyền lợi:</div>- Chi trả cho Bên thứ ba đối với
    bảo hiểm TNDS BB tối đa như sau:
    <div className="ml-25">
      • 150.000.000 đồng/1 người/1 vụ tai nạn đối với thiệt hại về người.
      <br /> • 100.000.000 đồng/1 vụ tai nạn đối với thiệt hại về tài sản.
    </div>
    - Bảo hiểm tai nạn người ngồi trên xe:
    <br />
    Chi trả theo Bảng tỷ lệ thương tật đối với các Thương tật thân thể (thương
    tật bộ phận vĩnh viễn, thương tật tạm thời) do tai nạn: Hạn mức lựa chọn
    VNĐ/người/vụ. Tối đa đến 50.000.000 đồng/người/vụ.
    <div className="text-bold">Đối tượng:</div>
    Tất cả các loại xe được cấp phép hoạt động tại Việt Nam, có giấy kiểm định
    đi đường và bao gồm các loại xe như sau:
    <div className="ml-25">
      • Xe không kinh doanh vận tải
      <br />• Xe kinh doanh vận tải
      <br />• Xe tải chở hàng
    </div>
    <div className="text-bold">Quy tắc:</div>
    <div className="ml-25">
      - Bảo hiểm bắt buộc trách nhiệm dân sự: theo Thông tư số 04/2021/TT-BTC
      ngày 15/01/2021 của Bộ Tài Chính và Nghị Định 03/2021/NĐ-CP ngày
      15/01/2021 của Chính Phủ. Trường hợp quy tắc Bảo hiểm bắt buộc trách nhiệm
      dân sự đính kèm Hợp Đồng này bị sửa đổi, bổ sung do Bộ Tài Chính/Chính Phủ
      thay đổi hoặc ban hành Thông tư hoặc Nghị định mới, Bên A phải thông báo
      cho Bên B về nội dung thay đổi trước thời điểm áp dụng.
      <br />- Bảo hiểm tai nạn người ngồi trên xe (NNTX): theo Quy tắc bảo hiểm
      tự nguyện xe ô tô ban hành kèm theo Quyết định số 370/2018/QĐ-PTI ngày
      26/12/2018 của Tổng Giám Đốc Tổng Công ty Cổ phần Bảo hiểm Bưu Điện.
      Trường hợp Quy tắc Bảo hiểm tai nạn tự nguyện xe ô tô đính kèm Hợp Đồng
      này bị sửa đổi, bổ sung do Công Ty Bảo Hiểm thay đổi hoặc ban hành quy
      định mới, Bên A phải thông báo cho Bên B về nội dung thay đổi trước thời
      điểm áp dụng.
    </div>
    <div className="text-bold">Phạm vi:</div>- Đối với Bảo hiểm TNDS BB:
    <div className="ml-25">
      • Thiệt hại ngoài hợp đồng về sức khỏe, tính mạng và tài sản đối với bên
      thứ ba do xe cơ giới gây ra.
      <br />• Thiệt hại về sức khỏe, tính mạng của hành khách do xe cơ giới gây
      ra.
    </div>
    - Đối với bảo hiểm tai nạn NNTX (Tự nguyện):
    <div className="ml-25">
      • Thiệt hại thân thể đối với lái xe, phụ xe do tai nạn liên quan trực tiếp
      từ việc sử dụng xe.
      <br /> • Thiệt hại thân thể đối với người ngồi trên xe do tai nạn khi đang
      ở trên xe, lên xuống xe trong quá trình tham gia giao thông.
    </div>
    <div className="text-bold">Các điểm loại trừ:</div>
    <div>
      1. Hành động cố ý gây thiệt hại của chủ xe cơ giới, người lái xe hoặc
      người bị thiệt hại.
      <br />
      2. Người lái xe gây tai nạn cố ý bỏ chạy không thực hiện trách nhiệm dân
      sự của chủ xe cơ giới. Trường hợp người lái xe gây tai nạn cố ý bỏ chạy
      nhưng đã thực hiện trách nhiệm dân sự của chủ xe Cơ giới thì không thuộc
      trường hợp loại trừ trách nhiệm bảo hiểm.
      <br />
      3. Người lái xe chưa đủ độ tuổi hoặc quá độ tuổi điều khiển xe cơ giới
      theo quy định pháp luật về giao thông đường bộ; người lái xe không có Giấy
      phép lái xe hoặc sử dụng Giấy phép lái xe không hợp lệ (có số phôi ghi ở
      mặt sau không trùng với số phôi được cấp mới nhất trong hệ thống thông tin
      quản Lý Giấy phép lái xe) hoặc sử dụng Giấy phép lái xe không do cơ quan
      có thẩm quyền cấp, Giấy phép lái xe bị tẩy xóa hoặc sử dụng Giấy phép lái
      xe hết hạn sử dụng tại thời điểm xảy ra tai nạn hoặc sử dụng Giấy phép lái
      xe không phù hợp đối với xe cơ giới bắt buộc phải có Giấy phép lái xe.
      Trường hợp người lái xe bị tước quyền sử dụng Giấy phép lái xe có thời hạn
      hoặc bị thu hồi Giấy phép lái xe thì được coi là không có Giấy phép lái
      xe.
      <br />
    </div>
    <div className="text-bold">Hồ sơ/quy trình yêu cầu bồi thường:</div>
    <div>
      Ngay khi xảy ra tai nạn, giữ nguyên hiện trường (nếu có thể) và gọi ngay
      cho Hotline của nhà bảo hiểm thông báo sự cố phát sinh (trừ trường hợp bất
      khả kháng) để được Giám định viên hướng dẫn;
      <br />
      <br />
      Hồ sơ bồi thường sẽ được Giám định viên Công ty Bảo hiểm yêu cầu cung cấp
      theo mỗi tình huống, dựa theo Quy định của Thông tư số 04/2021/TT-BTC ban
      hành ngày 15/01/2021 của Bộ Tài Chính và Nghị Định số 03/2021/NĐ-CP ban
      hành ngày 15/01/2021 của Chính Phủ.
    </div>
  </>
);
export const tai_nan = (
  <>
    <div className="bold">
      Cuộc sống luôn có những tai nạn dù lớn hay nhỏ bất ngờ đến với chúng ta.
      Nó không chỉ ảnh hưởng đến tính mạng, sức khoẻ mà còn là tổn thất về tài
      chính. Hãy trang bị cho mình một kế hoạch thông minh để ứng phó với sự cố
      thường ngày bạn nhé.
    </div>
    <div className="text-bold">Quyền lợi bảo hiểm:</div>
    Người được bảo hiểm bị tử vong, mất tích, thương tật vĩnh viễn hay thương
    tật tạm thời do tai nạn thuộc phạm vi bảo hiêm quy định tại Điều 5 và Điều 8
    của Chương trình bảo hiểm này, Bảo hiểm PVI sẽ chỉ trả một số tiền cụ thể
    theo Bảng Quyền lợi như sau:
    <br />
    <div className="row-table bg-lgreen">
      <div className="border cell-4 bold">Gói</div>
      <div className="border cell-3 bold">Gói 1</div>
      <div className="border cell-3 bold">Gói 2</div>
    </div>
    <div className="row-table bg-color">
      <div className="border cell-4">Số tiền bảo hiểm/Người/Vụ </div>
      <div className="border cell-3">100.000.000 đồng</div>
      <div className="border cell-3">50.000.000 đồng</div>
    </div>
    <div className="row-table">
      <div className="border cell-4">A. Quyền lợi tử vong và mất tích</div>
      <div className="border cell-3">100.000.000 đồng</div>
      <div className="border cell-3">50.000.000 đồng</div>
    </div>
    <div className="row-table">
      <div className="border cell-4">B. Thương tật toàn bộ vĩnh viễn</div>
      <div className="border cell-3">100.000.000 đồng</div>
      <div className="border cell-3">50.000.000 đồng</div>
    </div>
    <div className="row-table">
      <div className="border cell-4">C. Thương tật bộ phận vĩnh viễn</div>
      <div className="border cell-3">
        Theo Bảng tỷ lệ trả tiền bảo hiểm thương tật đính kèm
      </div>
      <div className="border cell-3">
        Theo Bảng tỷ lệ trả tiền bảo hiểm thương tật đính kèm
      </div>
    </div>
    <div className="row-table">
      <div className="border cell-4">D. Thương tật tạm thời</div>
      <div className="border cell-3">
        Theo Bảng tỷ lệ trả tiền bảo hiểm thương tật đính kèm
      </div>
      <div className="border cell-3">
        Theo Bảng tỷ lệ trả tiền bảo hiểm thương tật đính kèm
      </div>
    </div>
    <div className="text-bold">Đối tượng:</div>
    Tổng công ty Bảo hiểm PVI (gọi tắt là Bảo hiểm PVI) nhận báo hiểm tai nạn cá
    nhân cho mọi công dân Việt Nam và Người nước ngoài đang sinh sống hợp pháp
    tại Việt Nam từ đủ 01 tuổi đến 70 tuổi (tính theo lần sinh nhật gần nhất),
    loại trừ các đối tượng sau đây:
    <div className="ml-25">
      - Những người bị bệnh tâm thần, phong, ung thư. <br />- Những người bị tàn
      tật hoặc thương tật vĩnh viễn từ 50% trở lên. <br />- Những người đang
      trong thời gian điều trị bệnh tật, thương tật.
    </div>
    <div className="text-bold">Quy tắc:</div>
    BẢO HIẾM TAI NẠN CÁ NHÂN THIẾT YẾU (Theo Quy tắc Bảo hiểm Tai nạn cá nhân
    ban hành theo Quyết định số 1359/QĐ-PVIBH ngày 21 tháng 08 năm 2017 của Tổng
    giám đốc Tổng công ty Bảo hiểm PVI)
    <div className="text-bold">
      Phạm vi:
      <br />
      Những rủi ro được bảo hiểm
    </div>
    <div>
      Người được bảo hiểm bị thương tật thân thể hoặc tử vong do tai nạn hoặc do
      Người được bảo hiểm có hành động tự vệ chính đáng, hành động cứu người,
      cứu tài sản của Nhà nước, của nhân dân và tham gia chống các hành động
      phạm pháp cũng được coi là tai nạn.
      <br /> Người được bảo hiểm mất tích và Tòa án có quyết định tuyên bố là
      mất tích do tai nạn trong thời hạn bảo hiểm.
      <br />
      (Bảo hiểm PVI - Chương trình Bảo hiểm Tai nạn cá nhân thiết yếu - Áp dụng
      từ ngày 05 tháng 10 năm 2020) <br />
      Rủi ro được bảo hiểm phải xảy ra trong lãnh thổ Việt Nam và trong thời
      gian hiệu lực của hợp đồng bảo hiệm hoặc giấy chứng nhận bảo hiểm.
    </div>
    <div className="text-bold">Hiệu lực bảo hiểm</div>
    Bảo hiểm tai nạn cá nhân có hiệu lực kể từ ngày bắt đầu thời hạn bảo hiểm
    ghi trên hợp đồng bảo hiểm/giấy chứng nhận bảo hiểm với điều kiện bên mua
    bảo hiểm/người được bảo hiểm đóng phí đầy đủ theo đúng thời hạn thanh toán
    trên hợp đồng/giấy chứng nhận bảo hiểm và phù hợp với quy định của pháp
    luật.
    <div className="text-bold">Các điểm loại trừ:</div>
    <div>
      - Vi phạm pháp luật, vi phạm quy chế an toàn lao động
      <br />- Do hành động cố ý của bản thân
      <br />- Tai nạn có nguyên nhân trực tiếp từ việc hành động dưới tác động
      của cồn, ma túy, các chất kích thích khác
      <br />- Tham gia ẩu đả, trừ trường hợp tự vệ
      <br />- Các bệnh tật, bệnh nghề nghiệp, thai sản
      <br />- Các rủi ro thiên tai, phóng xạ, hạt nhân, hóa chất có độc hay ảnh
      hưởng do các vụ nổ
      <br />- Tham gia các hoạt động hàng không
      <br />- Chiến tranh, nội chiến, bạo động,…
      <br />- Mất tích không do tai nạn
      <br />- Đột tử, tử vong không rõ nguyên nhân
      <br />- Tham gia tập luyện, thi đấu thể thao chuyên nghiệp
    </div>
    <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
    Giấy yêu cầu trả tiền bảo hiểm theo mẫu của PVI
    <div className="text-bold">Chứng từ y tế</div>• Chứng nhận thương tích •
    Chứng từ y tế đã điều trị tại bệnh viện
    <div className="text-bold">Chứng từ khi tử vong</div>
    • Giấy chứng tử
    <br />• Giấy xác nhận quyền thừa kế có công chứng
    <div className="text-bold">Biên bản tai nạn</div>
    • Tường trình tai nạn có xác nhận của cơ quan/ chính quyền địa phương nơi cư
    trú
    <br />• TNGT có biên bản Công an + GPLX + CMND
  </>
);
// export const bh_ngheo = (
//   <>
//     <div className="ml-25">
//       👊 Bệnh tật xảy đến bất chợt, khiến bạn không kịp trở tay?
//       <br />
//       👊 Các khoản thu phí từ bệnh viện cứ ngày một tăng dần?
//     </div>
//     ⚡ Thật ra đó không chỉ là câu chuyện của riêng bạn mà còn rất nhiều người
//     gặp phải, những cơn bệnh hiểm nghèo bất chợt ập đến khi bạn vẫn chưa kịp
//     trang bị gì cho bản thân và gia đình sẽ khiến bạn vô cùng khổ sở. Hiểu được
//     điều đó, gói bảo hiểm bệnh hiểm nghèo được thiết kế với mục tiêu san sẻ
//     những khó khăn mà gia đình bạn đang phải đối mặt, khi tham gia gói bạn sẽ
//     nhận được:
//     <div className="ml-25">
//       🔹 Chi trả 100% dựa theo số tiền bảo hiểm
//       <br />
//       🔹 Thủ tục chi trả đơn giản, không cần chứng từ thanh toán gốc
//       <br />
//       🔹 Có thể mua trả góp.
//     </div>
//     <div>
//       🔥 Đừng để tiền bạc làm ảnh hưởng đến những quyết định điều trị của bạn.
//       Tham gia gói bảo hiểm bệnh hiểm nghèo ngay trong hôm nay bạn nhé.
//     </div>
//     <div className="bold">
//       Bảo hiểm bệnh hiểm nghèo của Tổng công ty cổ phần Bảo hiểm Bưu điện là sản
//       phẩm bảo hiểm ĐẦU TIÊN tại thị trường bảo hiểm phi nhân thọ ở Việt Nam.
//       Tham gia bảo hiểm bệnh hiểm nghèo của PTI, khách hàng sẽ được cung cấp
//       nguồn tài chính kịp thời cho quá trình điều trị và đảm bảo kế hoạch tài
//       chính cho bản thân và gia đình. Bảo hiểm bệnh hiểm nghèo của Tổng công ty
//       cổ phần Bảo hiểm Bưu điện là sản phẩm bảo hiểm ĐẦU TIÊN tại thị trường bảo
//       hiểm phi nhân thọ ở Việt Nam. Tham gia bảo hiểm bệnh hiểm nghèo của PTI,
//       khách hàng sẽ được cung cấp nguồn tài chính kịp thời cho quá trình điều
//       trị và đảm bảo kế hoạch tài chính cho bản thân và gia đình.
//     </div>
//     <div className="text-bold">Quyền lợi Bảo hiểm:</div>
//     1.Mức phí bảo hiểm hợp lý <br /> 2.Chi trả 100% số tiền bảo hiểm khi mắc
//     bệnh hiểm nghèo
//     <div className="text-bold">Quyền lợi Bảo hiểm:</div>
//     Đối tượng: Công dân Việt Nam hoặc người nước ngoài đang cư trú trong lãnh
//     thổ Việt Nam không quá độ tuổi quy định và không bị bệnh ung thư, bệnh hiểm
//     nghèo theo định nghĩa (tại thời điểm tham gia bảo hiểm), tâm thần hoặc bị
//     thương tật vĩnh viễn từ 80% trở lên.
//     <br /> Độ tuổi tham gia rộng từ 01 tuổi đến 60 tuổi. Mở rộng độ tuổi đến 65
//     tuổi nếu tham gia liên tục từ năm 60 tuổi
//     <div className="text-bold">Quy tắc:</div>
//     Quy tắc Bảo hiểm Bệnh hiểm nghèo Ban hành theo quyết định số 144/QĐ-PTI ngày
//     16/05/2016 của Tổng giám đốc Tổng công ty Cổ phần Bảo hiểm Bưu điện
//     <div className="text-bold">Phạm vi:</div>
//     Trường hợp Người được bảo hiểm mắc phải bệnh hiểm nghèo trong thời hạn bảo
//     hiểm, PTI sẽ chi trả toàn bộ số tiền bảo hiểm được nêu cụ thể trong Hợp đồng
//     với điều kiện sau:
//     <div className="ml-25">
//       • Bệnh hiểm nghèo xuất hiện lần đầu đối với Người được bảo hiểm theo chẩn
//       đoán của bác sỹ <br />• Thời gian chờ hiệu lực 90 ngày kể từ ngày hiệu lực
//       bảo hiểm đầu tiên <br />• Người được bảo hiểm còn sống ít nhất 30 ngày kể
//       từ ngày kết luận của bác sỹ.
//     </div>
//     Hợp đồng bảo hiểm sẽ kết thúc sau khi Người được bảo hiểm nhận được số tiền
//     bồi thường và không tái tục cho những năm sau.
//     <div>
//       <span className="text-bold">Phạm vi địa lý bảo hiểm:</span> Việt Nam
//     </div>
//     <div>Các bệnh hiểm nghèo được bảo hiểm:</div>
//     <div className="ml-25">
//       + Nhồi máu cơ tim lần đầu
//       <br />+ Đột quỵ
//       <br />
//       + Ung thư
//       <br />
//       + Suy thận
//       <br />
//       + Cấy ghép nội tạng/ tủy xương
//       <br />
//       + Liệt vĩnh viễn các chi
//       <br />
//       + U não lành tính
//       <br />
//       + Phẩu thuật thông động mạch vành
//       <br />
//       + Đa xơ cứng
//       <br />
//       + Phẫu thuật ghép động mạch chủ
//       <br />
//       + Tăng huyết áp động mạch phổi
//       <br />
//       + Thay thế van tim
//       <br />
//       + Bệnh Parkinson
//       <br />+ Bệnh gan giai đoạn cuối
//     </div>
//     <div>
//       Số tiền bảo hiểm:
//       <br />
//       Lựa chọn 1: 110,000,000 VND/ người/ năm
//       <br />
//       Lựa chọn 2: 220,000,000 VND/ người/ năm
//       <br />
//       Lựa chọn 3: 330,000,000 VND/ người/ năm
//     </div>
//     <div className="text-bold">Các điểm loại trừ:</div>
//     Bên mua bảo hiểm xác nhận không vi phạm các điểm loại trừ riêng sau: <br />
//     - Trước khi tham gia Hợp đồng bảo hiểm này, Người được bảo hiểm đã từng bị
//     một công ty bảo hiểm từ chối, trì hoãn, hủy hợp đồng, hoặc chấp nhận bảo
//     hiểm nhưng có điểm loại trừ riêng hoặc phí bảo hiểm phải đóng cao hơn phí
//     tiêu chuẩn hoặc phải thay đổi quyền lợi hoặc tham gia chương trình bảo hiểm
//     tương tự và đã được bồi thường;
//     <br />- Trước khi tham gia Hợp đồng bảo hiểm này, Người được bảo hiểm đã
//     từng mắc phải hoặc có bất kỳ triệu chứng nào của các bệnh sau đây:
//     <div className="ml-25">
//       • Ung thư, u, u nang;
//       <br />
//       • Đột quỵ, đau tim; liệt
//       <br />
//       • HIV/ AIDS/ bệnh lây nhiễm qua đường tình dục;
//       <br />
//       • Bất kì bệnh mãn tính, tiến triển hoặc rối loạn thận, phổi, gan, ruột,
//       tủy xương hoặc tuyến giáp;
//       <br />
//       • Bất kì bệnh rối loạn hệ thống sinh sản (ví dụ rối loạn tuyền tiền liệt,
//       nội mạc tử cung);
//       <br />• Bất kì bệnh tật kéo dài hoặc cần điều trị nhiều hơn 28 ngày.
//     </div>
//     - Trong vòng 05 năm trước, Người được bảo hiểm đã từng mắc phải hoặc có bất
//     kỳ triệu chứng nào của các bệnh sau đây:
//     <div className="ml-25">
//       • Tăng huyết áp kháng trị, tăng huyết áp không kiểm soát;
//       <br />
//       • Tiểu đường;
//       <br />• Chảy máu trong hệ thống tiết niệu, thận hoặc bàng quang, bụng, rối
//       loạn đường tiết niệu.
//     </div>
//     - Trước khi tham gia Hợp đồng bảo hiểm này, Người được bảo hiểm đã từng có
//     kết quả bất thường khi làm kết quả xét nghiệm phết bào tử cung (Xét nghiệm
//     Pap)
//     <br /> - Bất cứ thành viên nào trong gia đình của Người được bảo hiểm bao
//     gồm bố mẹ, anh chị em ruột mắc phải:
//     <div className="ml-25">
//       • Có 1 thành viên trong gia đình Người được bảo hiểm (nữ) bị Ung thư buồng
//       trứng;
//       <br /> • Có 1 thành viên trong gia đình Người được bảo hiểm (nam) bị Ung
//       thư tuyến tiền liệt;
//       <br /> • Có 2 thành viên trở lên trong gia đình Nguời được bảo hiểm bị Ung
//       thư khác, bệnh tim, đột quỵ, tiểu đường
//     </div>
//     - Người được bảo hiểm hút trung bình 20 điếu thuốc lá trở lên /ngày và/hoặc
//     uống nhiều hơn 13l rượu vang đỏ, 25l bia, 2l rượu whisky/ tuần.
//     <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
//     <div className="text-bold">Chứng từ bồi thường</div>- Giấy yêu cầu bồi
//     thường, chứng từ y tế (giấy ra vào viện, sổ khám bệnh, kết luận bệnh và chỉ
//     định điều trị, báo cáo y tế, lịch sử bệnh tật, tóm tắt tình trạng khi xuất
//     viện…).
//     <div className="bold">Thời hạn thông báo</div>
//     - Trong vòng 14 ngày kể từ ngày có kết luận của bác sỹ về Bệnh hiểm nghèo.
//     Việc thông báo có thể thực hiện qua thư, email, fax hoặc điện thoại. Thời
//     hạn thanh toán
//     <br />- Thanh toán trước ngày bắt đầu bảo hiểm/ hiệu lực bảo hiểm.
//     <div className="bold">Thời hạn nộp hồ sơ bồi thường</div>- 365 ngày kể từ
//     ngày có kết luận của bác sỹ về Bệnh hiểm nghèo.
//     <div className="bold">Thời hạn giải quyết bồi thường</div>- 20 ngày làm việc
//     kể từ ngày nhận được bộ hồ sơ đầy đủ và hợp lệ.
//     <div className="bold">Đơn vị giải quyết bồi thường</div>
//     Hồ sơ bồi thường gửi về đơn vị Giải quyết bồi thường của PTI:
//     <br />
//     <div className="ml-25">
//       • CÔNG TY TNHH INSMART <br />• Tại Hà Nội: Tầng 25, tòa nhà Ngọc Khánh
//       Plaza, số 01 Phạm Huy Thông, Quận Ba Đình, Hà Nội. <br />• Điện thoại:
//       1900 636.730 - Fax: (024) 37728110. <br />• Tại Hồ Chí Minh: Tầng 9, cao
//       ốc Đinh Lễ, Số 1 Đinh Lễ, phường 12, quận 4, TP. Hồ Chí Minh. <br />• Điện
//       thoại: 1900 636.730 - Fax: (028) 38266101.
//     </div>
//   </>
// );
export const easy_care = (
  <>
    <div className="cell-center bold bg-lgreen">Quyền lợi bảo hiểm</div>
    <div className="row-table">
      <div className="border cell-5 bold">
        Điều trị nội trú do ốm đau, bệnh tật, tai nạn. Trong đó:
      </div>
      <div className="border cell-5 ">36,000,000</div>
    </div>
    <div className="row-table">
      <div className="border cell-5 bold">
        Trợ cấp chi phí y tế/ngày nằm viện. Tối đa 30 ngày/năm
      </div>
      <div className="border cell-5 ">1,200,000</div>
    </div>
    <div className="row-table">
      <div className="border cell-5 bold">Phí bảo hiểm/người/năm</div>
      <div className="border cell-5 ">1,260,000</div>
    </div>
    <div className="text-bold">Đối tượng:</div>
    • Mọi công dân Việt Nam và người nước ngoài đang sinh sống, làm việc tại
    Việt Nam từ 15 ngày tuổi đến 70 tuổi (người tham gia bảo hiểm từ trước năm
    65 tuổi được quyền tái tục đến năm 75 tuổi) sau đây gọi là Người được bảo
    hiểm.
    <br />
    • Người được bảo hiểm có thể đăng ký bảo hiểm cho các thành viên khác sống
    cùng với chủ hộ bao gồm: 2 vợ chồng và các con dưới 24 tuổi (không giới hạn
    số con), tuy nhiên mở rộng này không bao gồm cho trường hợp con trên 18 tuổi
    đã có gia đình.
    <br />• Loại trừ đối tượng:
    <div className="ml-25">
      - Những người bị bệnh tâm thần, phong, ung thư; <br />- Những người bị tàn
      phế hoặc thương tật vĩnh viễn từ 50% trở lên;
    </div>
    <div className="text-bold">Quy tắc:</div>
    Sản phẩm Bảo hiểm Easy Care áp dụng theo Quy tắc Bảo hiểm Bình An Cá Nhân
    ban hành kèm theo Quyết định số 106/2015/QĐ-VASS/TGĐ ngày 19/05/2015 của
    Tổng Giám đốc Công ty Cổ Phần Bảo hiểm Viễn Đông.
    <div className="text-bold">Phạm vi:</div>
    <div className="cell-center bold bg-yellow">Phạm vi bảo hiểm</div>
    <div className="row-table">
      <div className="border cell-5 bold">
        Điều trị nội trú do ốm đau, bệnh tật, tai nạn. Trong đó:
      </div>
      <div className="border cell-5 ">36,000,000</div>
    </div>
    <div className="row-table">
      <div className="border cell-5 bold">
        Trợ cấp chi phí y tế/ngày nằm viện. Tối đa 30 ngày/năm
      </div>
      <div className="border cell-5 ">1,200,000</div>
    </div>
    <div className="row-table">
      <div className="border cell-5 bold">Phí bảo hiểm/người/năm</div>
      <div className="border cell-5 ">1,260,000</div>
    </div>
    <div className="text-bold">Thời gian chờ:</div>
    - 0 ngày cho tai nạn
    <br />
    - 30 ngày ốm đau, bệnh tật thông thường
    <br />- 365 ngày bệnh đặc biệt, bệnh có sẵn
    <div className="text-bold">Các điểm loại trừ:</div>
    -Hành động cố ý của Người được bảo hiểm hoặc người thừa kế hợp pháp (trừ
    trường hợp người thừa kế là trẻ em dưới 14 tuổi);
    <br />
    -Người được bảo hiểm bị ảnh hưởng trực tiếp do sử dụng rượu, bia, ma túy
    hoặc các chất kích thích tương tự khác;
    <br />
    -Người được bảo hiểm vi phạm nghiêm trọng pháp luật, nội quy, quy định của
    chính quyền địa phương hoặc các tổ chức xã hội;
    <br />
    -Điều trị hoặc sử dụng thuốc không theo hướng dẫn của cơ sở y tế;
    <br />
    -Điều dưỡng, an dưỡng;
    <br />
    -Nằm viện để kiểm tra sức khỏe hoặc khám giám định y khoa mà không liên quan
    đến việc điều trị bệnh tật;
    <br />
    -Điều trị hoặc phẫu thuật các bệnh tật bẩm sinh, những thương tật và chĩ
    định phẫu thuật có từ trước ngày bắt đầu bảo hiểm;
    <br />
    -Điều trị chưa được khoa học công nhận hoặc điều trị thử nghiệm;
    <br />
    -Tạo hình thẩm mỹ, chỉnh hình, phục hồi chức năng, làm chân, tay giả, mắt
    giả, răng giả;
    <br />
    -Kế hoạch hóa gia đình;
    <br />
    -Những người được bảo hiểm mắc các bệnh giang mai, lậu, nhiễm vi rút HIV,
    sốt rét, lao và bệnh nghề nghiệp;
    <br />
    -Các hoạt động hàng không (trừ khi với tư cách là hành khách có vé), các
    cuộc diễn tập, huấn luyện quân sự, tham gia chiến đấu của các lực lượng vũ
    trang;
    <br />
    -Người được bảo hiểm tham gia đánh giá nhau trừ khi được xác nhận đó là hành
    động tự vệ;
    <br />
    -Động đất, núi lửa, nhiễm phóng xạ;
    <br />
    -Chiến tranh, nội chiến, đình công, khủng bố.
    <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
    <div className="bold">A. Bồi thường không online</div>
    <div className="ml-25">
      + Giấy yêu cầu trả tiền bảo hiểm (bản gốc)
      <br />
      + Giấy tờ tuỳ thân
      <br />
      + Giấy ra viện (bản gốc hoặc bản công chứng)
      <br />+ Toa thuốc trường hợp ngoại trú (bản gốc hoặc bản công chứng)
    </div>
    1. Chứng từ liên quan đến tai nạn ( bản chính)
    <div className="ml-25">
      + Đăng ký xe và Giấy phép lái xe trong trường hợp Người được bảo hiểm bị
      tai nạn khi đang điều khiển xe cơ giới – bản sao công chứng.
      <br />
      + Tai nạn giao thông: Hồ sơ tai nạn giao thông theo quy định của cơ quan
      công an.
      <br />+ Tai nạn lao động: Biên bản tai nạn lao động do cơ quan chủ quản
      lập theo quy định.
    </div>
    2. Tai nạn sinh hoạt: <br />
    Bản tường trình tai nạn có chữ ký của những người làm chứng và/hoặc xác nhận
    của cơ quan chủ quản có thẩm quyền nơi xảy ra sự kiện tai nạn. <br />
    3. Trường hợp tử vong
    <div className="ml-25">
      + Giấy chứng tử - bản sao công chứng.
      <br />
      + Giấy xác nhận quyền thừa kế hợp pháp (bản gốc/sao công chứng).
      <br />+ Giấy ủy quyền của những người thừa kế hợp pháp – bản gốc.
    </div>
    Nơi tiếp nhận hồ sơ yêu cầu bồi thường:
    <br />
    Công ty CP Bảo hiểm Viễn Đông
    <br />
    19 Phùng Khắc Khoan, Phường Đakao, Quận 1, TPHCM.
    <div className="bold">
      B. Bồi thường online (theo quy trình bồi thường online của LIAN), theo đó
      người dùng upload:
    </div>
    <div className="ml-25">
      - Hình chụp giấy tờ tuỳ thân (bản chính)
      <br />
      - Hình chụp giấy ra viện (bản chính)
      <br />
      - Hình chụp toa thuốc (bản chính)
      <br />
      -Bản gốc các chứng từ y tế hợp lệ liên quan đến việc điều trị (Tóm tắt hồ
      sơ bệnh án, Giấy ra viện, Phiếu điều trị, Phiếu mổ - trường hợp phải phẫu
      thuật, Đơn thuốc, hóa đơn, biên lai thu tiền viện phí, Phim X – quang,...)
    </div>
    Ps: Trong mọi trường hợp, Công ty bảo hiểm có quyền yêu cầu Người được bảo
    hiểm cung cấp hồ sơ yêu cầu trả tiến bảo hiểm bán chính khi phục vụ công tác
    điều tra phòng chống trục lợi.
    <br />
    1. Chứng từ liên quan đến tai nạn
    <div className="ml-25">
      + Đăng ký xe và Giấy phép lái xe trong trường hợp Người được bảo hiểm bị
      tai nạn khi đang điều khiển xe cơ giới (hình chụp bản chính)
      <br />
      + Tai nạn giao thông: Hồ sơ tai nạn giao thông theo quy định của cơ quan
      công an (chụp bản chính)
      <br />
      + Tai nạn lao động: Biên bản tai nạn lao động do cơ quan chủ quản lập theo
      quy định (chụp bản chính)
      <br />+ Tai nạn sinh hoạt: Bản tường trình tai nạn có chữ ký của những
      người làm chứng và/hoặc xác nhận của cơ quan chủ quản có thẩm quyền nơi
      xảy ra sự kiện bảo hiểm.
    </div>
    2. Trường hợp tử vong
    <div className="ml-25">
      + Giấy chứng tử - bản sao công chứng.
      <br />
      + Giấy xác nhận quyền thừa kế hợp pháp (bản gốc/sao công chứng).
      <br />+ Giấy ủy quyền của những người thừa kế hợp pháp – bản gốc.
    </div>
  </>
);
export const bh_nha = (
  <>
    <div className="text-bold">Quyền lợi:</div>
    <div className="blue">BẢN TÓM TẮT QUYỀN LỢI BẢO HIỂM VÀ PHÍ BẢO HIỂM</div>
    <div className="table">
      <div className="row-table">
        <div className="border cell-05-bm bg-color text-center bold">STT</div>
        <div className="column">
          <div className="row-table bg-color bold">
            <div className="border cell-35-bm">GÓI BẢO HIỂM</div>
            <div className="border cell-2-bm">GÓI 1</div>
            <div className="border cell-2-bm">GÓI 2</div>
            <div className="border cell-2-bm">GÓI 3</div>
          </div>
          <div>
            <div className="row-table bg-color bold">
              <div className="border cell-35-bm">Tổng số tiền bảo hiểm</div>
              <div className="border cell-2-bm">850,000,000</div>
              <div className="border cell-2-bm">1,600,000,000</div>
              <div className="border cell-2-bm">3,000,000,000</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row-table">
        <div className="border cell-05-bm text-center">1</div>
        <div className="row-table">
          <div className="border cell-35-bm">Ngôi nhà (khung nhà)</div>
          <div className="border cell-2-bm">500,000,000</div>
          <div className="border cell-2-bm">1,000,000,000</div>
          <div className="border cell-2-bm">2,000,000,000</div>
        </div>
      </div>
      <div className="row-table">
        <div className="border cell-05-bm text-center">2</div>
        <div className="row-table">
          <div className="border cell-35-bm">Tài sản bên trong *</div>
          <div className="border cell-2-bm">300,000,000</div>
          <div className="border cell-2-bm">500,000,000</div>
          <div className="border cell-2-bm">800,000,000</div>
        </div>
      </div>
      <div className="row-table">
        <div className="border cell-05-bm text-center">3</div>
        <div className="row-table">
          <div className="border cell-35-bm">Trách nhiệm công cộng</div>
          <div className="border cell-2-bm">50,000,000</div>
          <div className="border cell-2-bm">100,000,000</div>
          <div className="border cell-2-bm">200,000,000</div>
        </div>
      </div>
      <div className="row-table bold">
        <div className="border col-4-mr text-center">
          Phí bảo hiểm đã bao gồm VAT
        </div>
        <div className="row-table">
          <div className="border cell-2-bm">400,000</div>
          <div className="border cell-2-bm">650,000</div>
          <div className="border cell-2-bm">1,150,000</div>
        </div>
      </div>
    </div>
    <div className="text-bold">
      Đối tượng:
      <br />
      Đối tượng: nhà ở, chung cư.
    </div>
    - Khung nhà hoặc giá trị xây dựng ngôi nhà. <br />- Tài sản bên trong ngôi
    nhà.
    <div className="text-bold">
      Quy tắc:
      <br />
      Theo Quy tắc bảo hiểm Toàn diện Nhà Tư nhân ban hành kèm theo Quyết định
      số{" "}
      <a
        href="https://drive.google.com/file/d/1jYVsgKXQJwfeiF9WrhdBgmz36sClfSn_/view"
        target="_bank"
      >
        670/QĐ-PVIBH
      </a>{" "}
      ngày 11 tháng 05 năm 2018 của Tổng giám đốc Tổng công ty Bảo hiểm PVI.
      <br />
      Phạm vi:
    </div>
    1.Cháy do nổ hoặc bất kỳ nguyên nhân nào khác nhưng loại trừ thiệt hại do:
    <br />
    <div className="ml-25">
      - Tài sản tự lên men, tỏa nhiệt hoặc tự cháy, hoặc
      <br />- Tài sản bị đốt cháy theo lệnh / quyết định của cơ quan công quyền
      cho dù việc ra lệnh /quyết định đó có đúng và phù hợp với quy định của
      pháp luật hay không.
    </div>
    2.Nổ binh gas hoặc nồi hơi, binh đun nước phục vụ sinh hoạt;
    <br />
    3.Sét đánh trực tiếp;
    <br />
    4.Tràn nước từ bể chứa, đường ống, thiết bị chứa nước nhưng loại trừ tài sản
    bị tổn thất hoặc thiệt hại do nước từ hệ thống phòng cháy chữa cháy tự động;
    <br />
    5.Trộm cắp/cướp tài sản đi kèm dấu hiệu đột nhập và/hoặc sử dụng vũ lực, bạo
    động, đình công, hành động ác ý của bất kỳ người nào khác không phải Người
    được bảo hiểm hoặc thành viên trong gia đình Người được bảo hiểm đang sinh
    sống tại Ngôi nhà được bảo hiểm hoặc người đại diện của Người được bảo hiểm;
    <br />
    6.Thiệt hại do:
    <div className="ml-25">
      - Đâm va bởi xe cộ hoặc động vật không thuộc quyền sở hữu, kiểm soát của
      Người được bảo hiểm hoặc các thành viên trong gia đình Người được bảo hiểm
      đang sinh sống tại Ngôi nhà được bảo hiểm hoặc người làm thuê cho Người
      được bảo hiểm tại Ngôi nhà được bảo hiểm.
      <br />
      - Máy bay và các thiết bị trên không khác và/hoặc các thiết bị trên các
      phương tiện đó rơi vào.
      <br />- Cây đổ, cành cây gãy hoặc bất kỳ đồ vật / thiết bị nào khác (không
      thuộc sở hữu hoặc quản lý của Người được bảo hiểm) rơi vào Ngôi nhà được
      bảo hiểm và không phải do hành động cố tình của Người được bảo hiểm hoặc
      các thành viên trong gia đình Người được bảo hiểm đang sinh sống tại Ngôi
      nhà được bảo hiểm hoặc người làm thuê cho Người được bảo hiểm tại Ngôi nhà
      được bảo hiểm.
      <div />
      <div className="text-bold">Các điểm loại trừ:</div>
      Tổn thất hoặc thiệt hại gây ra do ô nhiễm hoặc nhiễm bẩn ngoại trừ tổn
      thất hoặc thiệt hại đối với Tài sản được bảo hiểm gây ra bởi ô nhiễm hoặc
      nhiễm bẩn do một rủi ro được bảo hiểm gây ra Tổn thất, thiệt hại đối với
      các công trình kiến trúc nằm ngoài Ngôi nhà được bảo hiểm, trừ hệ thống
      tường bao và hệ thống cửa ra vào Ngôi nhà được bảo hiểm. Ngôi nhà được bảo
      hiểm bị bỏ trống, không có người cư trú hoặc không có người sử dụng liên
      tục từ 60 ngày trở lên tính từ thời điểm trước khi xảy ra tổn thất. Bất kỳ
      hành động nào được quy cho là:
      <div className="ml-25">
        + Chiến tranh (Dù có tuyên bố chiến tranh hay không), nội chiến.
        <br />+ Khủng bố
        <br />+ Binh chiến, nổi loạn của quân đội hoặc dân chúng, khởi nghĩa,
        phiến loạn
      </div>
      Tịch thu, trưng dụng cho quân đội, trưng thu hoặc phá hủy theo lệnh hợp
      pháp về mặt pháp lý của cơ quan nhà nước có thẩm quyền. Vũ khí nguyên tử
      hay phóng xạ, nhiễm xạ. Chất amiăng dưới bất kỳ hình thức hoặc số lượng
      nào. Hành động gian lận, hành động cố ý hay biết trước hậu quả có thể xảy
      ra nhưng vẫn thực hiện hoặc có sự đồng lõa của Người được bảo hiểm hoặc
      Người đại diện, Người được bảo hiểm hoặc các thành viên trong gia đình,
      Người được bảo hiểm đang sinh sống trong ngôi nhà được bảo hiểm Dữ liệu
      điện tử.
      <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
      - Giấy yêu cầu bảo hiểm toàn diện nhà tư nhân
      <br />
      - Giấy chứng nhận bảo hiểm toàn diện nhà tư nhân
      <br />- Để biết thêm chi tiết, vui lòng liên hệ: 1900 54 54 58
    </div>
  </>
);
// export const gc_care = (
//   <>
//     <div className="blue">
//       ĐẦU TƯ CHO SỨC KHỎE LUÔN LÀ GÓI ĐẦU TƯ “HỜI” NHẤT
//     </div>
//     🌟 Trải qua thời gian dài mới biết thế nào là tầm quan trọng của một sức
//     khỏe tốt. Với mình có 2 điều đáng lưu ý nhất mà mình rút ra:
//     <br />
//     1️⃣ Luôn bổ sung các dưỡng chất thiết yếu cho cơ thể, thực phẩm giàu vitamin
//     cho bản thân và gia đình
//     <br />
//     1️⃣ Trang bị cho gia đình mình gói bảo hiểm sức khỏe GC Care
//     <br />
//     ⚡ Gói bảo hiểm sức khỏe GC Care sẽ thay bạn giải quyết các mối lo về viện
//     phí khi:
//     <br />
//     <div className="ml-25">
//       🔹 Nằm viện do ốm đau, bệnh tật
//       <br />
//       🔹 Khám và điều trị ngoại trú do bệnh tật, tai nạn
//       <br />
//       🔹 Tử vong do tai nạn
//       <br />
//       🔹 Tử vong do ốm bệnh thai sản.
//     </div>
//     Nhiều người cứ nghĩ mình còn trẻ, sức khỏe còn dẻo dai nên chưa mua bảo hiểm
//     nhưng có ai nói trước được nếu bệnh tật đến bất ngờ. Vậy nên, đầu tư vào bảo
//     hiểm là khoảng đầu tư hời nhất bởi có sức khỏe bạn mới có thể “tiến xa” hơn
//     nữa. Tham gia ngay nhé!
//     <div className="text-bold">Quyền lợi Bảo hiểm:</div>
//     <div className="table">
//       <div className="row-table">
//         <div className="col-8-gc b-600 bg-lgreen">
//           QUYỀN LỢI BẢO HIỂM/NGƯỜI/THỜI HẠN BẢO HIỂM
//         </div>
//         <div className="col-2-gc b-600 bg-lgreen"></div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc b-600 bg-color">
//           I. Nằm viện do ốm đau, bệnh tật, tai nạn - Quyền lợi chính
//         </div>
//         <div className="col-2-gc bg-color">50.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">Giới hạn phụ: </div>
//         <div className="col-2-gc"></div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600">(1) Viện phí/ ngày (tối đa 60 ngày/năm)</span>
//           <br />
//           Chi phí viện phí phát sinh trong quá trình nằm viện, bao gồm nhưng
//           không giới hạn bởi:
//           <br />
//           - Chi phí tiền giường. Trong trường hợp điều trị tại bệnh viện quốc
//           tế; bệnh viện tư; khoa quốc tế, khoa tự nguyện, theo yêu cầu, và các
//           khoa có tính chất tương đương tại bệnh viện công thì tiền giường là
//           chi phí tiền giường của phòng đơn tiêu chuẩn. Phòng đơn tiêu chuẩn là
//           phòng 01 giường có giá thấp nhất tại Bệnh viện/khoa/khu điều trị.
//           Trường hợp NĐBH sử dụng phòng VIP và/ hoặc phòng hạng sang PTI sẽ
//           thanh toán chi phí tiền giường của phòng đơn tiêu chuẩn.
//           <br />
//           - Suất ăn theo tiêu chuẩn của Bệnh viện
//           <br />
//           - Chi phí phòng chăm sóc đặc biệt (phòng điều trị tích cực ICU)
//           <br />
//           - Chi phí hành chính, chi phí máu, huyết tương
//           <br />
//           - Thuốc và dược phẩm trong thời gian nằm viện
//           <br />
//           - Chi phí băng, nẹp và bó bột
//           <br />
//           - Vật lí trị liệu (nếu cần thiết trong quá trình nằm viện và theo chỉ
//           định bác sỹ)
//           <br />
//           - Tiêm tĩnh mạch
//           <br />
//           - Chụp X-quang, MRI, CT và PET, các xét nghiệm chẩn đoán khác theo chỉ
//           định của bác sỹ
//           <br />
//           - Chi phí sinh thường
//           <br />- Chi phí y tế khác theo chỉ định bác sỹ
//           <br />
//           <span className="b-600">Tối đa 60 ngày/ năm</span>
//         </div>
//         <div className="col-2-gc">VNĐ 1.250.000 </div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600">
//             (2) Điều trị cấp cứu (bao gồm nội trú, trong ngày và ngoại trú)/ năm
//           </span>
//           <br />
//           Là điều trị cấp cứu được thực hiện tại phòng cấp cứu của một bệnh viện
//           hoặc phòng khám cho một tình trạng nguy kịch
//         </div>
//         <div className="col-2-gc">VNĐ 50.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600">
//             (3) Chi phí phẫu thuật và thủ thuật/ năm
//           </span>
//           <br />
//           Chi phí phẫu thuật là các chi phí cho các ca nằm viện phẫu thuật, phẫu
//           thuật trong ngày và phẫu thuật ngoại trú, gồm: <br />
//           - Chi phí phẫu thuật, chi phí thủ thuật điều trị (bao gồm thủ thuật
//           điều trị trong ngày và nội trú). Danh mục thủ thuật được quy định
//           trong Bảng phân loại Thủ thuật, phẫu thuật hiện hành của Bộ Y Tế
//           <br />
//           - Chí phí thuốc điều trị, thuốc dùng trong phẫu thuật
//           <br />
//           - Những bộ phận giả thiết yếu cần được cấy ghép vào cơ thể để duy trì
//           sự sống
//           <br />
//           - Vật tư tiêu hao, thiết bị cần thiết
//           <br />
//           - Chi phí phòng mổ
//           <br />
//           - Chi phí gây mê
//           <br />
//           - Chi phí chẩn đoán trước phẫu thuật
//           <br />
//           - Chi phí hồi sức sau phẫu thuật
//           <br />
//           - Chi phí cấy ghép nội tạng
//           <br />- Chi phí phẫu thuật lại
//         </div>
//         <div className="col-2-gc">VNĐ 50.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600">(4) Trước khi nhập viện/ năm</span>
//           <br />
//           PTI chi trả các chi phí khám, chẩn đoán, xét nghiệm và các chi phí y
//           tế khác cho lần khám cuối cùng cần điều trị nội trú ngay sau đó theo
//           chỉ định của bác sỹ điều trị và liên quan trực tiếp đến ốm đau, bệnh
//           tật của Người được bảo hiểm và những chẩn đoán này là cơ sở trực tiếp
//           để bác sĩ điều trị kết luận việc điều trị nằm viện là cần thiết. Chi
//           phí này được chi trả trong vòng 30 ngày trước khi nhập viện.
//         </div>
//         <div className="col-2-gc">VNĐ 1.250.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600">(5) Sau khi xuất viện/ năm</span>
//           <br />
//           PTI chi trả cho các chi phí điều trị ngay sau khi xuất viện theo chỉ
//           định của bác sĩ điều trị cho ốm đau, bệnh tật của Người được bảo hiểm
//           đã phải điều trị nằm viện, bao gồm: chi phí tái khám, chi phí xét
//           nghiệm, tiền thuốc ngay sau khi xuất viện. Chi phí này được chi trả
//           tối đa trong vòng 30 ngày sau khi xuất viện
//         </div>
//         <div className="col-2-gc">VNĐ 1.250.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600">(6) Y tá chăm sóc tại nhà/ năm</span>
//           <br />
//           PTI chi trả chi phí cho người được bảo hiểm các dịch vụ chăm sóc y tế
//           của một y tá được cấp giấy phép hành nghề hợp pháp, được hưởng ngay
//           sau khi rời bệnh viện, tại nơi ở của Người được bảo hiểm, thực hiện
//           theo chỉ định của bác sĩ điều trị trong vòng 30 ngày sau khi xuất
//           viện. Quyền lợi này không áp dụng cho trường hợp xuất viện sau khi
//           sinh con và biến chứng thai sản
//         </div>
//         <div className="col-2-gc">VNĐ 1.250.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600"> (7) Vận chuyển khẩn cấp/ năm</span>
//           <br />
//           a. Dịch vụ xe cứu thương địa phương (không bao gồm vận chuyển bằng
//           đường Hàng không và dịch vụ SOS/ IPA) b. Chi phí xe taxi cho trường
//           hợp khẩn cấp/người/năm (chi phí thực tế cần thiết theo thông lệ và hợp
//           lý). Tối đa VND 500,000/ người/ năm
//         </div>
//         <div className="col-2-gc">VNĐ 50.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600"> (8)Trợ cấp ngày nằm viện/ ngày</span>
//           <br />
//           Tối đa 60 ngày/ năm. Không áp dụng thai sản
//         </div>
//         <div className="col-2-gc">VNĐ 50.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           <span className="b-600"> (9) Trợ cấp mai táng</span>
//           <br />
//           (Trong trường hợp người được bảo hiểm mất tại bệnh viện)
//         </div>
//         <div className="col-2-gc">VNĐ 2.100.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc b-600">(10) Phục hồi chức năng</div>
//         <div className="col-2-gc">VNĐ 5.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc b-600">(11) Thai sản/ năm</div>
//         <div className="col-2-gc">VNĐ 7.500.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           Sinh thường
//           <br /> Sinh mổ, Biến chứng thai sản
//         </div>
//         <div className="col-2-gc bold">
//           Theo các giới hạn phụ "Viện phí" & "Phẫu Thuật"
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc b-600 bg-color">
//           II. Khám và điều trị ngoại trú do bệnh tật, tai nạn - quyền lợi lựa
//           chọn
//         </div>
//         <div className="col-2-gc bg-color">VNĐ 6.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">Giới hạn phụ:</div>
//         <div className="col-2-gc"></div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">(1) Số lần khám tối đa 1 năm</div>
//         <div className="col-2-gc">10 lần/năm</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">(2) Số tiền tối đa cho mỗi lần khám</div>
//         <div className="col-2-gc">VNĐ 600.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc">
//           (3) Khám chữa răng / năm (Trong đó bao gồm giới hạn lấy cao răng
//           200.000đ)
//         </div>
//         <div className="col-2-gc">VNĐ 600.000</div>
//       </div>

//       <div className="row-table">
//         <div className="col-8-gc b-600 bg-color">III. Tử vong do tai nạn</div>
//         <div className="col-2-gc bg-color">VNĐ 50.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc b-600 bg-color">
//           IV. Tử vong do ốm bệnh, thai sản
//         </div>
//         <div className="col-2-gc bg-color">VNĐ 50.000.000</div>
//       </div>
//       <div className="row-table">
//         <div className="col-8-gc b-600 bg-color">
//           V. Trợ cấp ngày trong thời gian nghỉ thực tế do tai nạn, theo chỉ định
//           của bác sĩ (tối đa 60 ngày/năm)
//         </div>
//         <div className="col-2-gc bg-color">VNĐ 100.000/ngày</div>
//       </div>
//     </div>
//     <div className="text-bold"> Đối tượng:</div>
//     Người Việt Nam hoặc nước ngoài đang sinh sống, làm việc tại Việt Nam và:
//     <div className="ml-25">
//       - Từ đủ 18 đến hết 65 tuổi
//       <br />
//       - Không bị mắc bệnh tâm thần, không bị ung thư
//       <br />- Không bị thương tật vĩnh viễn từ 80% trở lên
//     </div>
//     <div className="text-bold"> Quy tắc:</div>
//     Bảo hiểm Kết hợp Con Người theo Quy tắc bảo hiểm kết hợp con người của PTI
//     ban hành theo quyết định số 270/QĐ-PTI-BHCN ngày 26/09/2012 của Tổng Giám
//     đốc Tổng Công ty CP Bảo hiểm Bưu điện.
//     <div className="text-bold"> Phạm vi:</div>
//     • Bảo hiểm tai nạn: chi trả trong trường hợp Tử vong/ Thương tật vĩnh viễn
//     và hậu quả trong vòng 104 tuần kể từ ngày xảy ra tai nạn. Trợ cấp ngày trong
//     thời gian nghỉ thực tế do tai nạn, theo chỉ định của bác sĩ và chi phí y tế
//     phát sinh do tai nạn trong thời hạn bảo hiểm.
//     <br />• Bảo hiểm sức khỏe: chi trả cho những chi phí điều trị thực tế và tử
//     vong/ thương tật vĩnh viễn do ốm bệnh, thai sản xảy ra trong thời hạn bảo
//     hiểm.
//     <div className="text-bold"> Các điểm loại trừ:</div>
//     Những hạng mục, hoạt động sau đây và các chi phí phát sinh liên quan hay chi
//     phí hậu quả của chúng bị loại trừ và PTI không chịu trách nhiệm đối với:
//     <br />
//     1. Người được bảo hiểm vi phạm luật giao thông gồm: xe đi vào đường cấm, khu
//     vực cấm; rẽ, quay đầu tại nơi bị cấm; đi ngược chiều; vượt đèn đỏ hoặc không
//     chấp hành theo hiệu lệnh của người điều khiển giao thông.
//     <br />
//     2. Người được bảo hiểm bị tác động bởi chất kích thích, chất gây nghiện,
//     thuốc cai nghiện, thuốc không được bác sĩ chuyên môn kê toa, rượu, bia hoặc
//     các chất có cồn. <br />
//     3. Kiểm tra, tầm soát và khám sức khỏe bao gồm kiểm tra sức khỏe tổng quát.
//     <br />
//     4. Khám và các xét nghiệm không có kết luận bệnh của bác sĩ. Khuyết tật bẩm
//     sinh, dị tật bẩm sinh, bệnh bẩm sinh, các bệnh hoặc dị dạng thuộc về gen,
//     tình trạng sức khỏe di truyền với các dấu hiệu từ lúc sinh.
//     <br />
//     5. Thực phẩm chức năng, khoáng chất, các chất hữu cơ bổ sung cho chế độ dinh
//     dưỡng hoặc phục vụ cho chế độ ăn kiêng có sẵn trong tự nhiên, các sản phẩm
//     mỹ phẩm. Các điều trị liên quan đến chứng ngủ ngáy không rõ nguyên nhân.
//     <br /> 6. Các điểm loại trừ khác quy định trong quy tắc bảo hiểm đính kèm.
//     <div className="text-bold"> Hồ sơ/quy trình yêu cầu bồi thường:</div>
//     1. Thời hạn thông báo và nộp hồ sơ bồi thường
//     <div className="ml-25">
//       -Thời hạn thông báo tổn thất cho PTI: 120 ngày kể từ ngày tử vong, xuất
//       viện;
//       <br />
//       -Thời hạn nộp hồ sơ yêu cầu bồi thường: 01 năm.
//     </div>
//     Thời hạn giải quyết bồi thường: 15 ngày làm việc kể từ ngày nhận được bộ hồ
//     sơ đầy đủ và hợp lệ.
//     <br />
//     2. Thủ tục bồi thường
//     <br />
//     Bước 1: Chụp ảnh/ Scan hồ sơ yêu cầu trả tiền bảo hiểm (bản gốc) hoặc liên
//     hệ 1900068888 (nhánh 5) để được hướng dẫn;
//     <br />
//     Bước 2: Nộp hồ sơ yêu cầu trả tiền bảo hiểm theo 1 trong 2 cách sau:
//     <div className="ml-25">
//       - Cách 1: Upload hình ảnh vào App “Your PTI”
//       <br />- Cách 2: Gửi email tới địa chỉ: nhanhsbt_mb@pti.com.vn;
//     </div>
//     Bước 3: PTI kiểm tra và phản hồi qua email;
//     <br />
//     Bước 4: Khách hàng nhận thông báo bồi thường;
//     <br />
//     3. Hồ sơ yêu cầu trả tiền bảo hiểm
//     <br />
//     Đối với Hồ sơ yêu cầu trả tiền bảo hiểm, NĐBH cung cấp bản chụp các chứng từ
//     sau:
//     <div className="ml-25">
//       • Giấy yêu cầu trả tiền bảo hiểm (theo mẫu của PTI);
//       <br />
//       • CMND/ CCCD/ Hộ chiếu hoặc Giấy khai sinh của NĐBH;
//       <br />
//       • Biên bản tai nạn;
//       <br />
//       • Các chứng từ y tế liên quan đến điều trị thương tật như: Giấy ra viện,
//       báo cáo y tế, kết quả siêu âm, xét nghiệm, chụp chiếu, giấy chỉ định nghỉ
//       của bác sỹ,…;
//       <br />
//       • Chứng từ thanh toán: hóa đơn, phiếu thu, bảng kê chi tiết…;
//       <br />
//       • Trường hợp tử vong: Giấy chứng tử, giấy thừa kế hợp pháp;
//       <br />
//       • Trường hợp ủy quyền cho người khác: bổ sung giấy ủy quyền hợp pháp.
//       <br />• Các giấy tờ khác có liên quan khi có yêu cầu
//     </div>
//     Lưu ý:
//     <div className="ml-25">
//       + Đối với quyền lợi Tử vong (do tai nạn, ốm bệnh, thai sản): PTI giải
//       quyết bồi thường căn cứ vào bản chụp hồ sơ bồi thường đầy đủ, hợp lệ và
//       trả tiền bảo hiểm sau khi nhận được bản gốc các chứng từ trên;
//       <br />+ Đối với quyền lợi khác: PTI giải quyết bồi thường và trả tiền bảo
//       hiểm căn cứ vào bản chụp hồ sơ bồi thường đầy đủ và hợp lệ. NĐBH không cần
//       nộp lại bản gốc. Trong trường hợp cần thiết, PTI có thể yêu cầu cung cấp
//       bản gốc hồ sơ để có cơ sở giải quyết bồi thường.
//     </div>
//   </>
// );
export const tomato = (
  <>
    <div className="text-bold">Quyền lợi Bảo hiểm:</div>
    <div className="row-table bg-lgreen">
      <div className="border cell-3 bold">Gói</div>
      <div className="border cell-4 bold text-center">Quyền lợi</div>
      <div className="border cell-3 bold">Giá</div>
    </div>
    <div className="row-table bg-color">
      <div className="border cell-3">Tomato 1</div>
      <div className="border cell-4 text-center">
        Quyền lợi lên đến 100 Triệu đồng
      </div>
      <div className="border cell-3">200.000đ</div>
    </div>
    <div className="row-table bg-color">
      <div className="border cell-3">Tomato 2</div>
      <div className="border cell-4 text-center">
        Quyền lợi lên đến 200 Triệu đồng
      </div>
      <div className="border cell-3">400.000đ</div>
    </div>
    <div className="row-table bg-color">
      <div className="border cell-3">Tomato 3</div>
      <div className="border cell-4 text-center">
        Quyền lợi lên đến 300 Triệu đồng
      </div>
      <div className="border cell-3">600.000đ</div>
    </div>
    <div className="row-table bg-color">
      <div className="border cell-3">Tomato 4</div>
      <div className="border cell-4 text-center">
        Quyền lợi lên đến 500 Triệu đồng
      </div>
      <div className="border cell-3">1.000.000đ</div>
    </div>
    <div className="row-table bg-color">
      <div className="border cell-3">Tomato 5</div>
      <div className="border cell-4 text-center">
        Quyền lợi lên đến 1 Tỷ đồng
      </div>
      <div className="border cell-3">2.000.000đ</div>
    </div>
    <br />
    <div className="row-table bg-yellow">
      <div className="border cell-4 bold">Quyền lợi bảo hiểm tối đa/năm</div>
      <div className="border cell-6 bold">
        Tổng quyền lợi tích lũy và tối đa VND 1,000,000,000/năm
      </div>
    </div>
    <div className="row-table">
      <div className="border cell-4">
        1. Tử vong, thương tật toàn bộ vĩnh viễn do tai nạn
      </div>
      <div className="border cell-6">
        Tổng số tiền bảo hiểm tại thời điểm xảy ra tai nạn và tối đa VND
        1.000.000.000
      </div>
    </div>
    <div className="row-table">
      <div className="border cell-4">
        2. Thương tật bộ phận vĩnh viễn do tai nạn
      </div>
      <div className="border cell-6">
        [Tỷ lệ thương tật vĩnh viễn] x [Tổng số tiền BH tại thời điểm xảy ra tai
        nạn]
      </div>
    </div>
    <div className="row-table">
      <div className="border cell-4">
        3. Chi phí y tế điều trị thương tật do tai nạn/năm.
      </div>
      <div className="border cell-6">
        Trả theo chi phí y tế thực tế.
        <br />
        <span className="italic">
          (Tối đa đến 10% số tiền bảo hiểm tại thời điểm xảy ra tai nạn hoặc VND
          100,000,000. Tùy số tiền nào thấp hơn)
        </span>
      </div>
    </div>
    <div className="text-bold">Đối tượng:</div>
    Mọi công dân Việt Nam và người nước ngoài đang sinh sống, làm việc tại Việt
    Nam từ 15 ngày tuổi đến 70 tuổi (sau đây gọi là Người được bảo hiểm).
    <br />
    Không nhận bảo hiểm cho các đối tượng:
    <div className="ml-25">
      - Những người bị bệnh tâm thần <br />- Những người bị tàn phế hoặc thương
      tật vĩnh viễn từ 50% trở lên;
    </div>
    <div className="text-bold">Quy tắc:</div>
    QUY TẮC BẢO HIỂM TAI NẠN CON NGƯỜI 24/24 (Ban hành kèm theo Quyết định số
    0090/09/QĐ-HV/TGĐ ngày 28 tháng 05 năm 2009 của Tổng giám đốc Tổng Công ty
    Bảo hiểm Hùng Vương)
    <div className="text-bold">Phạm vi:</div>
    Bảo hiểm cho rủi ro tai nạn xảy ra trong lãnh thổ Việt Nam và đối với Người
    được bảo hiểm trong thời hạn bảo hiểm, bao gồm:
    <div className="ml-25">
      - Tử vong do tai nạn: Chi trả toàn bộ Số tiền bảo hiểm. <br />
      - Thương tật toàn bộ vĩnh viễn do tai nạn: Chi trả toàn bộ Số tiền bảo
      hiểm. <br />
      - Thương tật bộ phận vĩnh viễn do tai nạn: Chi trả theo tỷ lệ thương tật
      vĩnh viễn
      <br />- Chi phí y tế: Chi trả chi phí điều trị tối đa 10% số tiền bảo hiểm
    </div>
    Điều khoản mở rộng:
    <br /> 1. Ngộ độc thức ăn, đồ uống hoặc hít phải hơi độc, khí độc, chất độc
    <br /> 2. Mở rộng độ tuổi tham gia từ 15 ngày tuổi đến 70 tuổi
    <div className="text-bold"> Các điểm loại trừ:</div>
    1. Hành động cố ý của Người được bảo hiểm hoặc người thừa kế hợp pháp (trừ
    trường hợp người thừa kế là trẻ em dưới 14 tuổi).
    <br />
    2. Người được bảo hiểm vi phạm nghiêm trọng pháp luật, nội quy, quy định của
    chính quyền địa phương hoặc các tổ chức xã hội
    <br />
    3. Người được bảo hiểm bị ảnh hưởng trực tiếp do sử dụng rượu, bia, ma túy
    hoặc các chất kích thích tương tự khác.
    <br />
    Ghi chú: Chỉ loại trừ Nồng độ cồn vượt quá 80 miligam/100ml máu hoặc vượt
    quá 0,4 miligam/1 lít khí thở
    <br />
    4. Điều trị hoặc sử dụng thuốc không theo hướng dẫn của cơ sở y tế. <br />
    5. Các hoạt động hàng không (trừ khi với tư cách là hành khách có vé), các
    cuộc diễn tập, huấn luyện quân sự, tham gia chiến đấu của các lực lượng vũ
    trang. <br />
    6. Người được bảo hiểm tham gia đánh nhau trừ khi được xác nhận đó là hành
    động tự vệ.
    <br />
    7. Cảm đột ngột, trúng gió, bệnh tật, sẩy thai, bệnh nghề nghiệp, những tai
    biến trong quá trình điều trị bệnh và thai sản.
    <br />
    8. Động đất, núi lửa, nhiễm phóng xạ. <br />
    9. Chiến tranh, nội chiến, đình công. <br />
    10. Khủng bố.
    <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
    (Hình chụp được thực hiện trên ứng dụng BH365, không yêu cầu gửi hồ sơ gốc.
    Trừ các chứng từ sau, yêu cầu phải có bản gốc:
    <div className="ml-25">
      - Hóa đơn chi phí từ 2 triệu đồng trở lên;
      <br />
      - Biên bản xác nhận quyền thụ hưởng;
      <br />- Biên bản công an đối với hồ sơ tử vong;
    </div>
    Và trong trường hợp hóa đơn cần thiết, GC sẽ yêu cầu Khách hàng cung cấp
    chứng từ gốc để xác minh, đối chiếu.)
    <br />
    1. Hồ sơ súc vật cắn
    <div className="ml-25">
      - Kê khai yêu cầu trên ứng dụng BH365 và GC xuất phôi claim và chữ ký của
      khách hàng thành mẫu giấy yêu cầu bảo hiểm;
      <br />
      - Hình chụp trực tiếp (realtime) phiếu chích ngừa/ chỉ định chích ngừa;
      <br />- Hình chụp Hóa đơn trực tiếp (realtime) chích ngừa đối với hóa đơn
      giấy hoặc link hóa đơn đối với hóa đơn điện tử.
    </div>
    Lưu ý: Hóa đơn giấy từ trên 2 triệu đồng thu thập bản cứng;
    <br />
    2. Hồ sơ bỏng
    <div className="ml-25">
      - Kê khai yêu cầu trên ứng dụng BH365 và GC xuất phôi claim và chữ ký của
      khách hàng thành mẫu giấy yêu cầu bảo hiểm;
      <br />
      - Hình chụp trực tiếp (realtime) toa thuốc/ giấy ra viện có ghi chuẩn đoán
      “bỏng”, độ bỏng, diện tích bỏng (nếu có), chữ ký bác sỹ và mộc tròn bệnh
      viện/ phòng khám.
      <br />- Hình chụp trực tiếp (realtime) Hóa đơn điều trị đối với hóa đơn
      giấy và link đối với hóa đơn điện tử.{" "}
    </div>
    Lưu ý: Hóa đơn giấy từ trên 2 triệu đồng thu thập bản cứng.
    <br />
    3. Hồ sơ tai nạn (tai nạn sinh hoạt, tai nạn giao thông, tai nạn lao động)
    <div className="ml-25">
      3.1 Hồ sơ ngoại trú liên quan đến xương
      <div className="ml-25">
        - Kê khai yêu cầu trên ứng dụng BH365 và GC xuất phôi claim và chữ ký
        của khách hàng thành mẫu giấy yêu cầu bảo hiểm;
        <br />
        - Hình chụp trực tiếp (realtime) toa thuốc/ giấy ra viện có ghi chuẩn
        đoán, có chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám.
        <br />
        - Hình chụp trực tiếp (realtime) phim XQ, hình chụp phiếu đọc kết quả
        phim XQ.
        <br />- Hình chụp trực tiếp (realtime) Hóa đơn điều trị đối với hóa đơn
        giấy và link đối với hóa đơn điện tử.
      </div>
      Lưu ý: Hóa đơn từ trên 2 triệu đồng thu thập bản cứng.
      <br /> 3.2 Hồ sơ ngoại trú không liên quan đến xương
      <div className="ml-25">
        - Kê khai yêu cầu trên ứng dụng BH365 và GC xuất phôi claim và chữ ký
        của khách hàng thành mẫu giấy yêu cầu bảo hiểm;
        <br />
        - Hình chụp trực tiếp (realtime) toa thuốc/ giấy ra viện có ghi chuẩn
        đoán, có chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám.
        <br />- Hình chụp trực tiếp (realtime) Hóa đơn điều trị đối với hóa đơn
        giấy và link đối với hóa đơn điện tử.
      </div>
      Lưu ý: Hóa đơn từ trên 2 triệu đồng thu thập bản cứng.
      <br /> 3.3 Hồ sơ nội trú
      <div className="ml-25">
        - Kê khai yêu cầu trên ứng dụng BH365 và GC xuất phôi claim và chữ ký
        của khách hàng thành mẫu giấy yêu cầu bảo hiểm;
        <br />
        - Hình chụp trực tiếp (realtime) giấy ra viện, giấy chứng nhận phẩu
        thuật (nếu có).
        <br />- Hình chụp trực tiếp (realtime) Hóa đơn điều trị đối với hóa đơn
        giấy và link đối với hóa đơn điện tử.
      </div>
      Lưu ý: Hóa đơn từ trên 2 triệu đồng thu thập bản cứng.
      <br />
    </div>
    4. Hồ sơ tử vong
    <div className="ml-25">
      - Kê khai yêu cầu trên ứng dụng BH365 và GC xuất phôi claim và chữ ký của
      khách hàng thành mẫu giấy yêu cầu bảo hiểm;
      <br />
      - Biên bản công an điều tra có kết luận nguyên nhân tử vong (bản chụp và
      sau đó nộp bản gốc về)
      <br />
      - Giấy chứng tử (bản chụp)
      <br />- Giấy xác nhận quyền thụ hưởng (chụp lên hệ thống và sau đó nộp bản
      gốc bổ sung hồ sơ)
    </div>
  </>
);
export const tomato_combo = (
  <>
    <div className="row-table bg-lgreen">
      <div className="border cell-4 bold">Chương trình mua lẻ</div>
      <div className="border cell-6 bold ">Chương trình mua Combo</div>
    </div>
    <div className="row-table">
      <div className="border cell-4 bold">Chờ các chương trình từ GSale</div>
      <div className="border cell-6 bold ">
        Chủ động có Mã tiết kiệm để bán và mua, sau khi mua Mã sẽ được lưu vào
        mục Quản lý mã Code
      </div>
    </div>
    <div className="row-table">
      <div className="border cell-4 bold">Mua lẻ chiết khấu thấp</div>
      <div className="border cell-6 bold ">Mua Combo hưởng thu nhập cao</div>
    </div>
    <div className="row-table">
      <div className="border cell-4 bold">Chưa tiết kiệm</div>
      <div className="border cell-6 bold ">Tiết kiệm</div>
    </div>
    <div className="text-bold">
      Khi mua Combo TNDS BB Xe máy bạn được gì?
      <div className="ml-25">
        1. Chiết khấu cao
        <br />
        2. Chủ động có Mã tiết kiệm để mua và bán với số lượng lớn
        <br />
        3. Sản phẩm rất cần thiết đối với tất cả mọi người
        <br />
        4. Thời hạn bảo hiểm linh động
      </div>
      Tham gia ngay nhé!
      <br />
      Quyền lợi:
    </div>
    <div className="row-table bg-yellow">
      <div className="border cell-4 bold">Quyền lợi bảo hiểm</div>
      <div className="border cell-6 bold "></div>
    </div>
    <div className="row-table">
      <div className="border cell-4 bold">Bồi thường thiệt hại về người</div>
      <div className="border cell-6 bold ">150.000.000 VNÐ/người/vụ</div>
    </div>
    <div className="row-table">
      <div className="border cell-4 bold">Bồi thường thiệt hại về tài sản</div>
      <div className="border cell-6 bold ">50.000.000 VNÐ/vụ</div>
    </div>
    Chi trả cho người ngồi trên xe: <br />- 10.000.000 đồng/1 người đối với
    trường hợp Tử vong do tai nạn; <br />- Chi trả theo Bảng tỷ lệ thương tật
    đối với các Thương tật thân thể (thương tật bộ phận vĩnh viễn, thương tật
    tạm thời) do tai nạn, tối đa đến 10.000.000 đồng/người/vụ.
    <div className="text-bold">Đối tượng:</div>
    Ðối tượng mua bảo hiểm
    <br />
    - Là chủ sở hữu xe cơ giới hoặc được chủ sở hữu xe cơ giới giao chiếm hữu,
    sử dụng hợp pháp xe cơ giới.
    <br /> Ðối tượng được bảo hiểm
    <br />
    - Là bên thứ ba là người bị thiệt hại về sức khoẻ, tính mạng, tài sản do xe
    cơ giới gây ra, trừ những người sau:
    <br />
    - Người lái xe, người trên chiếc xe đó
    <br />- Chủ xe (trừ trường hợp chủ xe đã giao xe đó cho tổ chức/ cá nhân
    khác chiếm hữu, sử dụng chiếc xe đó.
    <div className="text-bold">Quy tắc:</div>
    - Bảo hiểm bắt buộc trách nhiệm dân sự: theo Thông tư số 04/2021/TT-BTC ngày
    15/01/2021 của Bộ Tài Chính và Nghị Định 03/2021/NĐ-CP ngày 15/01/2021 của
    Chính Phủ. Trường hợp quy tắc Bảo hiểm bắt buộc trách nhiệm dân sự đính kèm
    Hợp Đồng này bị sửa đổi, bổ sung do Bộ Tài Chính/Chính Phủ thay đổi hoặc ban
    hành Thông tư hoặc Nghị định mới, Global Care thông báo cho đối tác về nội
    dung thay đổi trước thời điểm áp dụng.
    <br />
    - Bảo hiểm tai nạn người ngồi trên xe (NNTX): theo Quy tắc bảo hiểm tự
    nguyện xe mô tô – xe máy ban hành kèm theo Quyết định số 104/2016/QĐ-MIC
    ngày 01/01/2016 của Tổng Giám Đốc Tổng Công ty Cổ phần Bảo hiểm Quân đội.
    Trường hợp Quy tắc Bảo hiểm tự nguyện xe mô tô – xe máy đính kèm Hợp Đồng
    này bị sửa đổi, bổ sung do Công Ty Bảo Hiểm thay đổi hoặc ban hành quy định
    mới, Global Care thông báo cho đối tác về nội dung thay đổi trước thời điểm
    áp dụng.
    <br />
    <div className="text-bold">Phạm vi:</div>
    - Đối với Bảo hiểm TNDSBB: Trách nhiệm của Người mua bảo hiểm đối với các
    Thiệt hại về thân thể, tính mạng và tài sản của người khác (bên thứ ba) do
    xe của Người mua bảo hiểm gây ra.
    <br />- Đối với bảo hiểm NNTX: Thiệt hại về tính mạng và thương tật thân thể
    của người lái xe và người ngồi trên xe của Người mua bảo hiểm khi có tai nạn
    do xe của Người mua bảo hiểm gây ra.
    <div className="text-bold">Các điểm loại trừ:</div>
    - Hành động cố ý gây thiệt hại của chủ xe cơ giới, người lái xe hoặc người
    bị thiệt hại.
    <br />
    - Người lái xe gây tai nạn cố ý bỏ chạy không thực hiện trách nhiệm dân sự
    của chủ xe cơ giới. Trường hợp người lái xe gây tai nạn cố ý bỏ chạy nhưng
    đã thực hiện trách nhiệm dân sự của chủ xe cơ giới thì không thuộc trường
    hợp loại trừ trách nhiệm bảo hiểm.
    <br />
    - Người lái xe chưa đủ độ tuổi hoặc quá độ tuổi điều khiển xe cơ giới theo
    quy định của pháp luật về giao thông đường bộ,…
    <br />- Xem thêm Số 03/2021/NĐ-CP
    <div className="text-bold">Hồ sơ/Quy trình yêu cầu bồi thường:</div>
    Hồ sơ bồi thường sẽ được Giám định viên Công ty Bảo hiểm yêu cầu cung cấp
    theo mỗi tình huống, dựa theo Quy định của Thông tư số 04/2021/TT-BTC ban
    hành ngày 15/01/2021 của Bộ Tài Chính và Nghị Định số 03/2021/NĐ-CP ban hành
    ngày 15/01/2021 của Chính Phủ.
    <br />
    Hãy ngay lập tức sơ cấp cứu cho người bị thương (nếu có), sau đó thực hiện
    các bước sau đây
    <div className="ml-25">
      + B1: Gọi công an giao thông nơi gần nhất
      <br />
      + B2: Gọi ngay cho số của Công Ty Bảo Hiểm để báo sự cố bảo hiểm và được
      hướng dẫn. Trong thời hạn 5 ngày làm việc kể từ ngày xảy ra tai nạn (trừ
      trường hợp bất khả kháng) bên mua bảo hiểm, người được bảo hiểm phải gửi
      thông báo tai nạn bằng văn bản hoặc thông qua hình thức điện tử cho doanh
      nghiệp bảo hiểm.
      <br />
      + B3: Chờ công an hoặc Giám định viên của Công Ty Bảo Hiểm xuống hiện
      trường xử lý. Trường hợp thiệt hại không lớn, Giám định viên sẽ hướng dẫn
      thoả thuận mức đền bù cho bên thứ ba cùng với khách hàng. <br />
      + B4: Khách hàng bổ sung đầy đủ hồ sơ mà Giám định viên yêu cầu để được
      nhận bồi thường.
      <br />+ B5: Công ty Bảo hiểm thanh toán bồi thường cho người được bảo hiểm
      trong vòng 15 (mười lắm) ngày làm việc kể từ khi nhận được đầy đủ hồ sơ
      yêu cầu bồi thường bảo hiểm hợp lệ và không quá 30 (ba mươi) ngày làm việc
      kể từ khi nhận được đầy đủ hồ sơ yêu cầu bồi thường bảo hiểm hợp lệ trong
      trường hợp phải tiến hành xác minh hồ sơ.
    </div>
    Trường hợp từ chối bồi thường, Công ty Bảo hiểm sẽ thông báo bằng văn bản
    cho Người được bảo hiểm biết lý do từ chối bồi thường trong thời hạn 15
    (mười lăm) ngày làm việc kể từ ngày nhận được đầy đủ hồ sơ yêu cầu bồi
    thường bảo hiểm hợp lệ.
  </>
);
export const tru_cot = (
  <>
    <div className="text-bold">
      👨👩👦👦 Ba mẹ chính là tấm khiên là trụ cột cho con cái, bạn có từng
      tưởng tượng được rằng con cái mình sẽ ra sao nếu như mất đi chỗ dựa. Dù
      đang có một sức khỏe tốt, một công việc ổn định thì người trụ cột gia đình
      không nên chủ quan về sức khỏe, dự phòng tài chính.
      <br />⚡ Tai nạn, bệnh tật hoặc rủi ro là những việc không lường trước
      được, nếu đang giữ vai trò trụ cột trong gia đình, bạn nên trang bị cho
      mình gói bảo hiểm trụ cột gia đình bởi những quyền lợi sau:
    </div>
    <div className="ml-25">
      🔹Mức chi trả cao lên đến 100 triệu đồng
      <br />
      🔹Giúp con trẻ vẫn thực hiện được ước mơ đến trường và cuộc sống no đủ khi
      không may người trụ cột không còn nữa
      <br />
      🔹Mang đến sự an tâm cho gia đình khi người trụ cột làm việc, đi lại…
    </div>
    <div className="text-bold">
      Với gói bảo hiểm trụ cột gia đình bạn hoàn toàn có thể yên tâm tài chính.
      Tham gia ngay nhé!
      <br />
      Quyền lợi:{" "}
    </div>
    - Bệnh đặc biệt – đột quỵ:
    <div className="table bold">
      <div className="row-table">
        <div className="border cell-4-tc text-center">
          Tử vong, thương tật vĩnh viễn
        </div>
        <div className="column cell-6-tc">
          <div className="row-table">
            <div className="border cell-5">Vàng</div>
            <div className="border cell-5">Kim Cương</div>
          </div>
          <div className="row-table">
            <div className="border cell-5">50.000.000 VNĐ</div>
            <div className="border cell-5">100.000.000 VNĐ</div>
          </div>
        </div>
      </div>
      <div className="row-table">
        <div className="border cell-4-tc text-center">Thời gian chờ</div>
        <div className="column cell-6-tc ">
          <div className="border text-center">Đột quỵ: 2 ngày</div>
          <div className="border text-center">Bệnh đặc biệt: 180 ngày</div>
        </div>
      </div>
    </div>
    - Dịch bệnh – Bệnh thông thường – Bệnh khác
    <div className="table bold">
      <div className="row-table">
        <div className="border cell-4-tc text-center">Tử vong</div>
        <div className="column cell-6-tc">
          <div className="row-table">
            <div className="border cell-5">Vàng</div>
            <div className="border cell-5">Kim Cương</div>
          </div>
          <div className="row-table">
            <div className="border cell-5">50.000.000 VNĐ</div>
            <div className="border cell-5">100.000.000 VNĐ</div>
          </div>
        </div>
      </div>
      <div className="row-table">
        <div className="border cell-4-tc text-center">Thời gian chờ</div>
        <div className="column cell-6-tc ">
          <div className="border text-center">Dịch bệnh: 2 ngày</div>
          <div className="border text-center">
            Bệnh thông thường và bệnh khác: 30 ngày
          </div>
        </div>
      </div>
    </div>
    - Trợ cấp nằm viện: Tối đa 180 ngày/ năm
    <div className="table bold">
      <div className="row-table">
        <div className="border cell-4-tc text-center">
          Dịch bệnh, đột quỵ, Bệnh đặc biệt, Bệnh thông thường
        </div>
        <div className="column cell-6-tc">
          <div className="row-table">
            <div className="border cell-5">Vàng</div>
            <div className="border cell-5">Kim Cương</div>
          </div>
          <div className="row-table">
            <div className="border cell-5">250.000 VNĐ/ ngày</div>
            <div className="border cell-5">500.000 VNĐ/ ngày</div>
          </div>
        </div>
      </div>
      <div className="row-table">
        <div className="border cell-4-tc text-center">Thời gian chờ</div>
        <div className="column cell-6-tc ">
          <div className="border text-center">Dịch bệnh, đột quỵ: 2 ngày</div>
          <div className="border text-center">
            Bệnh thông thường và thông thường: 30 ngày
          </div>
        </div>
      </div>
    </div>
    <div className="text-bold">Đối tượng: </div>
    Bảo hiểm Viễn Đông nhận bảo hiểm Đột Quỵ cho tất cả các khách hàng là công
    dân Việt Nam, người nước ngoài đang sống, học tập và công tác tại Việt Nam,
    có độ tuổi khi tham gia bảo hiểm từ đủ 18 tuổi và không quá 65 tuổi tại ngày
    tham gia bảo hiểm. Loại trừ các trường hợp sau:
    <div className="ml-25">
      • Những người đang bị bệnh tâm thần, phong (cùi, hủi);
      <br />
      • Những người đang có tỷ lệ thương tật vĩnh viễn từ 50% trở lên;
      <br />• Người đang trong thời gian điều trị hoặc đang mắc các bệnh đặc
      biệt.
    </div>
    <div className="text-bold">Quy tắc: </div>
    Căn cứ vào Quy tắc Bảo hiểm Trụ cột gia đình ban hành kèm theo Quyết định số
    237/2015/QĐ-VASS/TGĐ ngày 17/09/2015 của Tổng Giám đốc Công ty Cổ phần Bảo
    hiểm Viễn Đông (VASS) (sau đây gọi tắt là Quy tắc) và các điều khoản khác
    được thỏa thuận giữa các bên tại Phụ lục này. Bên B sẽ chi trả bồi thường
    cho Người được bảo hiểm trong trường hợp Người được bảo hiểm bị tai nạn bất
    ngờ xảy ra trong thời hạn bảo hiểm. Trong trường hợp có bất cứ mâu thuẫn nào
    giữa Quy tắc và các điều khoản được quy định tại Phụ lục này, Các Bên sẽ ưu
    tiên áp dụng các điều khoản được quy định tại Phụ lục này.
    <div className="text-bold">Phạm vi:</div>
    Phạm Vi Bồi Thường
    <br />
    <span className="underline">Phạm vi 1:</span> Tử vong hoặc Thương tật vĩnh
    viễn do tai nạn.
    <br />
    Người được bảo hiểm bị tử vong hoặc Thương tật vĩnh viễn do tai nạn mà không
    thuộc các điểm loại trừ của Quy tắc bảo hiểm.
    <br />
    <span className="underline">Phạm vi 2:</span> Tử vong hoặc Thương tật vĩnh
    viễn do Đột quỵ, đột tử và các Bệnh đặc biệt.
    <br />
    Người được bảo hiểm bị tử vong hoặc Thương tật vĩnh viễn do Đột quỵ, đột tử
    và các Bệnh đặc biệt được định nghĩa bên trên mà không thuộc các điểm loại
    trừ của Quy tắc bảo hiểm.
    <br />
    <span className="underline">Phạm vi 3:</span> Tử vong do Bệnh thông thường,
    Dịch bệnh và bệnh khác.
    <br />
    Người được bảo hiểm bị tử vong do Bệnh thông thường, Dịch bệnh và bệnh khác
    mà không thuộc các điểm loại trừ của Quy tắc bảo hiểm.
    <br />
    <span className="underline">Phạm vi 4:</span> Trợ cấp nằm viện.
    <br />
    Trường hợp Người được bảo hiểm bị Thương tật vĩnh viễn do tai nạn, đột quỵ,
    bệnh đặc biệt và bệnh thông thường. Bảo hiểm Viễn Đông sẽ chi trả trợ cấp
    nằm viện kể từ Ngày nằm viện thứ 3. (ba).
    <div className="text-bold">Các điểm loại trừ: </div>
    1. Hành động cố ý của Người được bảo hiểm hoặc việc tử vong hoặc Thương tật
    vĩnh viễn của người được bảo hiểm bị gây ra bởi Người thụ hưởng, người thừa
    kế hợp pháp hoặc người thụ hưởng hay đại diện hợp pháp của người phụ thuộc;
    <br />
    2. Tham gia các hoạt động thể thao chuyên nghiệp như: Lặn dưới nước có sử
    dụng thiết bị lặn; Đấm bốc; leo núi; Môn thể thao bay lượn; Săn bắn; Bóng
    ném; Bóng đá; Nhảy dù; Môn trượt nước; Đấu vật; Môn bóng ngựa; Cuộc đua các
    loại; Đua ngựa; Lướt ván và ảo thuật; Bơi thuyền buồm cách xa bờ biển
    5km;...
    <br />
    3. Do ảnh hưởng của rượu, bia, ma túy và các chất kích thích tương tự khác;
    <br />
    4. Tham gia đánh nhau trừ khi có cơ sở pháp lý để xác định đó là hành động
    phòng vệ chính đáng;
    <br />
    5. Ngộ độc thức ăn, đồ uống (Trừ khi Người được bảo hiểm tham gia điều khoản
    bổ sung);
    <br />
    6. Bị các bệnh/triệu chứng bẩm sinh; Phẩu thuật thẩm mỹ.
    <br />
    7. Bệnh/thương tật có sẵn;
    <br />
    8. Do các bệnh lây lan qua đường tình dục, bệnh giang mai, bệnh lậu, liên
    quan đến các bệnh thuộc hội chứng suy giảm miễn dịch (HIV);
    <br />
    9. Do điều trị hoặc sử dụng thuốc không theo chỉ dẫn của bác sỹ hoặc cơ sở y
    tế.
    <br />
    10. Động đất, núi lửa, nhiễm phóng xạ; các hoạt động hàng không (Trừ khi với
    tư cách là hành khách), các cuộc diễn tập hoặc huấn luyện quân sự, tham gia
    chiến đấu của các lực lượng vũ trang;
    <br />
    11. Chiến tranh, nội chiến, khủng bố, đình công.
    <div className="text-bold">Hồ sơ yêu cầu bồi thường: </div>
    Hồ sơ yêu cầu bồi thường: Hình chụp được thực hiện trên ứng dụng BH365,
    không yêu cầu gửi hồ sơ gốc. Trừ biên bản xác nhận quyền thụ hưởng và Biên
    bản công an đối với hồ sơ tử vong. Trong trường hợp cần thiết, Nhà Bảo hiểm
    có thể yêu cầu Khách hàng cung cấp chứng từ gốc để xác minh, đối chiếu. Cụ
    thể theo các trường hợp sau:
    <br />
    Hồ sơ nội trú
    <div className="ml-25">
      • Kê khai yêu cầu trên ứng dụng (xác thực chữ ký của khách hàng bằng
      phương thức mã OTP)
      <br />• Hình chụp giấy ra viện, giấy chứng nhận phẩu thuật (nếu có)
    </div>
    Hồ sơ tử vong
    <div className="ml-25">
      • Kê khai yêu cầu trên ứng dụng (xác thực chữ ký của khách hàng bằng
      phương thức mã OTP)
      <br />• Biên bản công an điều tra có kết luận nguyên nhân tử vong (chụp
      lên hệ thống và sau đó nộp bản gốc bổ sung hồ sơ)
    </div>
    Giấy chứng tử (bản chụp)
    <div className="ml-25">
      • Giấy xác nhận quyền thụ hưởng (chụp lên hệ thống và sau đó nộp bản gốc
      bổ sung hồ sơ), xác nhận quyền thụ hưởng (chụp lên hệ thống và sau đó nộp
      bản gốc bổ sung hồ sơ) Thời hạn yêu cầu bồi thường bảo hiểm:
      <br />• Thời hạn yêu cầu trả tiền bảo hiểm là một (01) năm kể từ ngày xảy
      ra sự kiện bảo hiểm. Thời gian xảy ra sự kiện bất khả kháng hoặc trở ngại
      khách quan khác không tính vào thời hạn yêu cầu trả tiền bảo hiểm.
    </div>
  </>
);
// export const master_rider = (
//   <>
//     <div className="text-bold">
//       Master Rider là giải pháp bảo hiểm vật chất mô tô – xe máy được phát triển
//       bởi Global Care và Tổng Công ty CP Bảo hiểm Bưu Điện PTI. Chỉ với một
//       khoản chi phí nhỏ, bạn đã có thể bảo vệ toàn diện cho chiếc xe đồng hành
//       hàng ngày trước các rủi ro bị mất cắp, mất cướp hoặc hư hỏng do tai nạn,
//       cháy nổ, thiên tai với quy trình bồi thường trực tuyến, minh bạch và nhanh
//       chóng.
//       <br />
//       Quyền lợi bảo hiểm:
//     </div>
//     <div className="table bg-color">
//       <div className="row-table bold">
//         <div className="col-4-mr text-center bg-lgreen">QUYỀN LỢI</div>
//         <div className="column bg-lgreen">
//           <div className="col-6-mr text-center">CHƯƠNG TRÌNH</div>
//           <div className="row-table">
//             <div className="col-15-mr">A</div>
//             <div className="col-15-mr">B</div>
//             <div className="col-15-mr">C</div>
//             <div className="col-15-mr">D</div>
//           </div>
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-mr">1</div>
//         <div className="border cell-35-mr">
//           Hư hỏng toàn bộ xe máy PTI sẽ bồi thường thực tế của xe
//         </div>
//         <div className="col-15-mr">✓ </div>
//         <div className="col-15-mr"></div>
//         <div className="col-15-mr"></div>
//         <div className="col-15-mr">✓ </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-mr">2</div>
//         <div className="border cell-35-mr">
//           Hư hỏng bộ phận (mức miễn thường VND500,000/vụ) PTI bồi thường theo
//           chi phí thực tế
//         </div>
//         <div className="col-15-mr">✓ </div>
//         <div className="col-15-mr"></div>
//         <div className="col-15-mr"></div>
//         <div className="col-15-mr"></div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-mr">3</div>
//         <div className="border cell-35-mr">
//           PTI bồi thường cho trường hợp Mất cắp, mất cướp toàn bộ xe khi: PTI sẽ
//           bồi thường thực tế của xe
//         </div>
//         <div className="col-15-mr">✓ </div>
//         <div className="col-15-mr">✓</div>
//         <div className="col-15-mr"></div>
//         <div className="col-15-mr">✓</div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-mr">4</div>
//         <div className="border cell-35-mr">
//           Cháy nổ xe máy PTI sẽ bồi thường thực tế của xe
//         </div>
//         <div className="col-15-mr">✓ </div>
//         <div className="col-15-mr"></div>
//         <div className="col-15-mr">✓</div>
//         <div className="col-15-mr">✓</div>
//       </div>
//       <div className="row-table">
//         <div className="col-4-mr">Tỷ lệ phí bảo hiểm (% trên phí NET)</div>
//         <div className="col-15-mr">2.2% x Giá trị xe Min VND 220,000</div>
//         <div className="col-15-mr">0.44% x Giá trị xe Min VND 110,000</div>
//         <div className="col-15-mr">0.22% x Giá trị xe Min VND 55,000</div>
//         <div className="col-15-mr">1.65% x Giá trị xe Min VND 77,000</div>
//       </div>
//     </div>
//     <div className="text-bold"> Đối Tượng Bảo Hiểm</div>
//     Xe mô tô, Xe máy có đăng ký hợp lệ.
//     <div className="text-bold">Quy Tắc Bảo Hiểm</div>
//     Theo quy tắc 08/QĐ-PTI Quyết định của Tổng giám đốc Tổng Công ty Cổ phần Bảo
//     hiểm Bưu Điện{" "}
//     <a
//       href="https://drive.google.com/file/d/13N2dTDmA_V3slDaqEu5Kg0_8wxd4IyZE/view"
//       target="_bank"
//     >
//       tại đây.
//     </a>
//     <div className="text-bold">Phạm Vi Bồi Thường</div>
//     PHẠM VI BẢO HIỂM VẬT CHẤT XE <br />
//     PTI bồi thường cho Chủ xe những thiệt hại vật chất xe xảy ra do những tai
//     nạn bất ngờ, ngoài sự kiểm soát của Chủ xe, lái xe trong những trường hợp:
//     <br /> 1. Đâm, va, lật, đổ;
//     <br /> 2. Hỏa hoạn, cháy, nổ bất ngờ; <br />
//     3. Những tai họa bất khả kháng do thiên nhiên: Bão, lũ, lụt (do mưa hoặc
//     triều cường), sụt lở, sét đánh, động đất, mưa đá, sóng thần.
//     <br />
//     PHẠM VI BẢO HIỂM MẤT CẮP, MẤT CƯỚP TOÀN BỘ XE
//     <br />
//     1. Bị cướp hoặc tấn công bằng vũ lực;
//     <br />
//     2. Mất cắp tại nhà, nơi cư trú bị đột nhập, cạy phá có dấu vết và bằng chứng
//     để lại;
//     <br />
//     3. Mất cắp tại các điểm trông giữ xe của trường học, bệnh viện, tòa nhà, cơ
//     quan nhà nước, các điểm trông giữ xe được cơ quan nhà nước có thẩm quyền cấp
//     giấy phép hoạt động đồng thời có phiếu, thẻ, vé giữ xe;
//     <br />
//     4. Mất cắp, mất cướp trong trường hợp bị tai nạn giao thông;
//     <br />
//     5. Đối với các trường hợp mất cắp khác ngoài các trường hợp quy định tại
//     điểm (1),(2),(3),(4) nêu trên thì được bồi thường tối đa 15% số tiền bảo
//     hiểm.
//     <div className="text-bold">Các Điểm Loại Trừ Bồi Thường</div>
//     BẢO HIỂM VẬT CHẤT XE
//     <br />
//     PTI không nhận bảo hiểm và không chịu trách nhiệm bồi thường thiệt hại vật
//     chất mô tô - xe gắn máy trong các trường hợp sau:
//     <br />
//     1. Tai nạn xảy ra ngoài lãnh thổ nước Cộng hoà xã hội chủ nghĩa Việt Nam
//     <br />
//     2. Hành động cố ý gây tai nạn của lái xe; sử dụng mô tô - xe gắn máy để đua
//     (bao gồm cả hợp pháp và trái phép), sử dụng xe cướp giật tài sản hoặc sử
//     dụng vào các mục đích bất hợp pháp khác gây tai nạn.
//     <br />
//     3. Lái xe không có giấy phép lái xe hoặc có nhưng không hợp lệ và phù hợp
//     đối với loại xe điều khiển (đối với loại xe bắt buộc phải có giấy phép lái
//     xe).
//     <br />
//     4. Hao mòn, hỏng hóc do sử dụng, lão hoá, mất giá, hỏng hóc do khuyết tật
//     hoặc tổn thất thêm do sửa chữa, trong quá trình sửa chữa (gồm cả chạy thử).
//     <br />
//     5. Chi phí thay xăng, nhiên liệu, dầu nhớt.
//     <br />
//     6. Tổn thất đối với săm lốp, nhãn mác, logo trừ trường hợp tổn thất này xảy
//     ra do cùng nguyên nhân và đồng thời với các bộ phận khác của xe trong cùng
//     một tai nạn.
//     <br />
//     MẤT CẮP, MẤT CƯỚP TOÀN BỘ XE
//     <br />
//     1. Hành động cố ý gây tai nạn của Chủ xe, Lái xe, những người được giao sử
//     dụng xe;
//     <br />
//     2. Nguyên nhân chiến tranh, các lý do tương tự chiến tranh như nội chiến,
//     đình công, bạo động dân sự, khủng bố;
//     <br />
//     3. Tai nạn xảy ra ngoài lãnh thổ nước Cộng hoà xã hội chủ nghĩa Việt Nam
//     (trừ trường hợp có thỏa thuận khác bằng văn bản);
//     <br />
//     4. Tổn thất, thiệt hại bị quy cho hành vi lừa đảo, lợi dụng lòng tin của bất
//     kỳ người nào mà theo định nghĩa của Bộ luật hình sự là lừa đảo/tội phạm, lợi
//     dụng lòng tin hoặc do hành vi gian lận, không trung thực, thông đồng với kẻ
//     gian của người được bảo hiểm;
//     <br />
//     5. Mất cắp, mất cướp bộ phận của xe
//     <br />
//     6. Người được bảo hiểm không thông báo bằng văn bản cho PTI trong vòng 10
//     ngày kể từ ngày phát hiện ra sự việc (trừ trường hợp bất khả kháng).
//     <div className="text-bold"> Hồ Sơ Yêu Cầu Bồi Thường</div>
//     Quy trình xử lý giám định bồi thường:
//     <br />
//     Bước 1: Thông báo tai nạn: Call Center 24/7 1900 5454 75 và làm theo hướng
//     dẫn
//     <br />
//     Bước 2: Làm theo hướng dẫn
//     <br />
//     Bước 3: Nhận kết quả bồi thường
//   </>
// );
export const travel = (
  <>
    <div className="text-bold">
      Để tận hưởng chuyến du lịch một cách trọn vẹn nhất, việc trang bị một hợp
      đồng bảo hiểm du lịch sẽ giúp bạn cảm thấy an tâm hơn khi đến một quốc
      gia, một vùng đất mới. Bảo hiểm Du lịch do Global Care phối hợp với Bảo
      hiểm Quân đội MIC phát triển và phân phối trên ứng dụng GSale sẽ bảo vệ
      bạn toàn diện trước các rủi ro từ tính mạng, sức khỏe đến thiệt hại về
      hành lý và tư trang; Mất giấy tờ thông hành; Chuyến bay bị hoãn hoặc hủy…
      với nhiều lựa chọn linh hoạt về Gói quyền lợi khi tham gia.
      <br />
      Quyền lợi bảo hiểm:
    </div>
    <br />
    <div className="blue">QUYỀN LỢI BẢO HIỂM DU LỊCH NỘI ĐỊA - GÓI ĐOÀN</div>
    <div className="table">
      <div className="border cell-full bold bg-lgreen">
        ĐỘ TUỔI BẢO HIỂM: không giới hạn
      </div>
      <div className="row-table bold">
        <div className="border cell-25-tv bg-color"></div>
        <div className="border cell-15-tv bg-color">Gói 1</div>
        <div className="border cell-15-tv bg-color">Gói 2</div>
        <div className="border cell-15-tv bg-color">Gói 3</div>
        <div className="border cell-15-tv bg-color">Gói 4</div>
        <div className="border cell-15-tv bg-color">Gói 5</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-25-tv">SỐ TIỀN BẢO HIỂM (ĐVT: VND)</div>
        <div className="border cell-15-tv">10,000,000</div>
        <div className="border cell-15-tv">30,000,000</div>
        <div className="border cell-15-tv">60,000,000</div>
        <div className="border cell-15-tv">120,000,000</div>
        <div className="border cell-15-tv">200,000,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-25-tv">
          Tử vong, thương tật tạm thời do tai nạn
        </div>
        <div className="border cell-15-tv">10,000,000</div>
        <div className="border cell-15-tv">30,000,000</div>
        <div className="border cell-15-tv">60,000,000</div>
        <div className="border cell-15-tv">120,000,000</div>
        <div className="border cell-15-tv">200,000,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-25-tv">
          Tử vong do ốm đau bất ngờ, ngộ độc thức ăn
        </div>
        <div className="border cell-15-tv">5,000,000</div>
        <div className="border cell-15-tv">15,000,000</div>
        <div className="border cell-15-tv">30,000,000</div>
        <div className="border cell-15-tv">60,000,000</div>
        <div className="border cell-15-tv">100,000,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-25-tv">
          Ngộ độc thức ăn, đồ uống; ngộ độc/ngạt thở do khí ga và khói độc: MIC
          chi trả theo chi phí y tế thực tế hợp lý, giới hạn mỗi ngày là
          600.000VND
        </div>
        <div className="border cell-15-tv">1,200,000</div>
        <div className="border cell-15-tv">3,000,000</div>
        <div className="border cell-15-tv">6,000,000</div>
        <div className="border cell-15-tv">12,000,000</div>
        <div className="border cell-15-tv">20,000,000</div>
      </div>
    </div>
    <br />
    <div className="blue">QUYỀN LỢI BẢO HIỂM DU LỊCH TOÀN CẦU - GÓI ĐOÀN</div>
    <div className="table">
      <div className="row-table bold">
        <div className="border cell-6-tv bg-green">
          QUYỀN LỢI CƠ BẢN BẮT BUỘC (ĐVT: USD) Tỉ giá tính tại thời điểm đơn vào
          hiệu lực
        </div>
        <div className="border cell-1-tv bg-green">Gói 4</div>
        <div className="border cell-1-tv bg-green">Gói 3</div>
        <div className="border cell-1-tv bg-green">Gói 2</div>
        <div className="border cell-1-tv bg-green">Gói 1</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">I</div>
        <div className="border cell-55-tv bg-color">TAI NẠN CÁ NHÂN</div>
        <div className="border cell-1-tv bg-color">70,000</div>
        <div className="border cell-1-tv bg-color">50,000</div>
        <div className="border cell-1-tv bg-color">30,000</div>
        <div className="border cell-1-tv bg-color">10,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">1</div>
        <div className="border cell-55-tv">- Từ 18 tuổi đến 65 tuổi</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
        <div className="border cell-1-tv">30,000</div>
        <div className="border cell-1-tv">10,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv"></div>
        <div className="border cell-55-tv">
          - Từ 06 tuần tuổi đến 18 tuổi và từ 66 tuổi trở lên
        </div>
        <div className="border cell-1-tv">35,000</div>
        <div className="border cell-1-tv">25,000</div>
        <div className="border cell-1-tv">15,000</div>
        <div className="border cell-1-tv">5,000</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">II</div>
        <div className="border cell-55-tv bg-color">QUYỀN LỢI Y TẾ</div>
        <div className="border cell-1-tv bg-color">70,000</div>
        <div className="border cell-1-tv bg-color">50,000</div>
        <div className="border cell-1-tv bg-color">30,000</div>
        <div className="border cell-1-tv bg-color">10,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">2</div>
        <div className="border cell-55-tv">
          Chi phí y tế do tai nạn hoặc bệnh bất ngờ: Viện phí, chi phí phẫu
          thuật, xe cấp cứu, thuốc, các xét nghiệm.
        </div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
        <div className="border cell-1-tv">30,000</div>
        <div className="border cell-1-tv">10,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">3</div>
        <div className="border cell-55-tv">
          Vận chuyển hài cốt/ mai táng: vận chuyển hài cốt của Người được bảo
          hiểm về Việt Nam hoặc quê hương hoặc mai táng ngay tại địa phương
        </div>
        <div className="border cell-1-tv">14,000</div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">6,000</div>
        <div className="border cell-1-tv">2,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">4</div>
        <div className="border cell-55-tv">
          Hồi hương: chi phí đưa người được bảo hiểm về Việt Nam hoặc quê hương
          (bao gồm cả chi phí cho thiết bị y tế cho thiết bị di động và nhân
          viên y tế đi kèm)
        </div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
        <div className="border cell-1-tv">30,000</div>
        <div className="border cell-1-tv">10,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">5</div>
        <div className="border cell-55-tv">
          Chi phí thăm viếng để thu xếp tang lễ ở nước ngoài: chi phí đi lại cho
          một người thân trong gia đình Người được bảo hiểm để hỗ trợ sắp xếp
          các việc liên quan đến việc xử lý thi hài và/ hoặc các thủ tục tang lễ
          ở nước ngoài
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">6</div>
        <div className="border cell-55-tv bold">
          Bảo hiểm trong trường hợp khủng bố (loại trừ khủng bố hạt nhân, vũ khí
          hóa học, sinh học)
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">III</div>
        <div className="border cell-55-tv bg-color">HỖ TRỢ DU LỊCH</div>
        <div className="border cell-1-tv bg-color">GÓI 4</div>
        <div className="border cell-1-tv bg-color">GÓI 3</div>
        <div className="border cell-1-tv bg-color">GÓI 2</div>
        <div className="border cell-1-tv bg-color">GÓI 1</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">7</div>
        <div className="border cell-55-tv bold">
          Thiệt hại hành lý và tư trang: mất mát hay hỏng hành lý và tư trang do
          bị tai nạn, cướp, trộm cắp hay do vận chuyển nhầm. Giới hạn cho mỗi
          hạng mục là 100USD, mỗi đôi hoặc bộ là 200USD
        </div>
        <div className="border cell-1-tv">1,400</div>
        <div className="border cell-1-tv">1,000</div>
        <div className="border cell-1-tv">600</div>
        <div className="border cell-1-tv">200</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">8</div>
        <div className="border cell-55-tv bold">
          Mất giấy tờ thông hành: chi phí xin cấp lại hộ chiếu, visa đã bị mất
          cùng chi phí đi lại và ăn ở phát sinh do việc xin cấp lại các giấy tờ
          đó. Giới hạn bồi thường tối đa một ngày là 10% của mức giới hạn cho
          quyền lợi này
        </div>
        <div className="border cell-1-tv">14,000</div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">6,000</div>
        <div className="border cell-1-tv">2,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">9</div>
        <div className="border cell-55-tv bold">
          Chuyến bay bị hoãn: trợ cấp tiền mặt cho mỗi 100USD trong mỗi 06 giờ
          liên tục do phương tiện vận chuyển theo lịch dự kiến bị hoãn khi ở
          nước ngoài (với điều kiên phải có chuyến khác thay thế).
        </div>
        <div className="border cell-1-tv">400</div>
        <div className="border cell-1-tv">300</div>
        <div className="border cell-1-tv">200</div>
        <div className="border cell-1-tv">100</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">10</div>
        <div className="border cell-55-tv bold">
          Hủy/ hoãn chuyến đi: tiền đặt cọc không được hoàn lại cho chuyến đi và
          chi phí đi lại tăng lên vì hủy/hoãn chuyến do Người được bảo hiểm bị
          chết, ốm đau/ thương tật nghiêm trọng, phải ra làm chứng hay hầu tòa
          hoặc được cách ly để kiểm duyệt...
        </div>
        <div className="border cell-1-tv">5,000</div>
        <div className="border cell-1-tv">3,000</div>
        <div className="border cell-1-tv">2,000</div>
        <div className="border cell-1-tv">1,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">11</div>
        <div className="border cell-55-tv bold">
          Trợ giúp chuyến đi 24/24 giờ - Thông tin về quy định chích ngừa và
          visa - Giới thiệu sứ quán - Trợ giúp thông tin về thời tiết - Trợ giúp
          thông tin về tỉ giá
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
    </div>
    <br />
    <div className="blue">QUYỀN LỢI BẢO HIỂM DU LỊCH QUỐC TẾ - GÓI CÁ NHÂN</div>
    <div className="table">
      <div className="row-table bold">
        <div className="border cell-7-tv bg-green">
          QUYỀN LỢI CƠ BẢN BẮT BUỘC (ĐVT: USD) Tỉ giá tính tại thời điểm đơn vào
          hiệu lực
        </div>
        <div className="border cell-1-tv bg-green">Gói 3</div>
        <div className="border cell-1-tv bg-green">Gói 2</div>
        <div className="border cell-1-tv bg-green">Gói 1</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">I</div>
        <div className="border cell-65-tv bg-color">TAI NẠN CÁ NHÂN</div>
        <div className="border cell-1-tv bg-color">100,000</div>
        <div className="border cell-1-tv bg-color">70,000</div>
        <div className="border cell-1-tv bg-color">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">1</div>
        <div className="border cell-65-tv">- Từ 18 tuổi đến 65 tuổi</div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv"></div>
        <div className="border cell-65-tv">
          - Từ 06 tuần tuổi đến 18 tuổi và từ 66 tuổi trở lên
        </div>
        <div className="border cell-1-tv">50,000</div>
        <div className="border cell-1-tv">35,000</div>
        <div className="border cell-1-tv">25,000</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">II</div>
        <div className="border cell-65-tv bg-color">QUYỀN LỢI Y TẾ</div>
        <div className="border cell-1-tv bg-color">100,000</div>
        <div className="border cell-1-tv bg-color">70,000</div>
        <div className="border cell-1-tv bg-color">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">2</div>
        <div className="border cell-65-tv">
          Chi phí y tế do tai nạn hoặc bệnh bất ngờ: Viện phí, chi phí phẫu
          thuật, xe cấp cứu, thuốc, các xét nghiệm.
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">3</div>
        <div className="border cell-65-tv">
          Chi phí y tế liên quan đến thai sản: Chi phí y tế thực tế, hợp lý
          trong trường hợp sảy thai, nạo thai cần thiết theo chỉ định của bác sỹ
          phát sinh trong chuyến đi
        </div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">7,000</div>
        <div className="border cell-1-tv">5,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">4</div>
        <div className="border cell-65-tv">
          Chi phí điều trị tiếp theo: chi phí y tế phát sinh trong lãnh thổ nước
          xuất phát trong vòng 30 ngày kể từ khi trở về nước xuất phát
        </div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">7,000</div>
        <div className="border cell-1-tv">5,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">5</div>
        <div className="border cell-65-tv">
          Chi phí y tế cho thân nhân đi thăm: chi phí đi lại và ăn ở cho một
          người thân trong gia đình đi thăm khi người được bảo hiểm phải nằm
          viện trên 05 ngày
        </div>
        <div className="border cell-1-tv">6,000</div>
        <div className="border cell-1-tv">4,000</div>
        <div className="border cell-1-tv">2,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">6</div>
        <div className="border cell-65-tv">
          Vận chuyển hài cốt/ mai táng: vận chuyển hài cốt của Người được bảo
          hiểm về Việt Nam hoặc quê hương hoặc mai táng ngay tại địa phương
        </div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">7,000</div>
        <div className="border cell-1-tv">5,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">7</div>
        <div className="border cell-65-tv">
          Hồi hương: chi phí đưa người được bảo hiểm về Việt Nam hoặc quê hương
          (bao gồm cả chi phí cho thiết bị y tế cho thiết bị di động và nhân
          viên y tế đi kèm){" "}
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">8</div>
        <div className="border cell-65-tv">
          Chi phí thăm viếng để thu xếp tang lễ ở nước ngoài: chi phí đi lại cho
          một người thân trong gia đình Người được bảo hiểm để hỗ trợ sắp xếp
          các việc liên quan đến việc xử lý thi hài và/ hoặc các thủ tục tang lễ
          ở nước ngoài
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">9</div>
        <div className="border cell-65-tv">
          Đưa trẻ em hồi hương: chi phí đi lại và ăn ở cần thiết phát sinh thêm
          cho một trẻ em dưới 14 tuổi để đưa trẻ em đó về Việt Nam hoặc quê
          hương
        </div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">10</div>
        <div className="border cell-65-tv">
          Trợ giúp y tế: - Tư vấn y tế - Giới thiệu các cơ sở y tế trên khắp thế
          giới - Thu xếp cuộc hẹn với bác sĩ y khoa - Theo dõi tình trạng của
          người được bảo hiểm trong thời gian nằm viện - Trợ giúp thu xếp vé máy
          bay khẩn cấp
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">11</div>
        <div className="border cell-65-tv">
          Vận chuyển y tế khẩn cấp: đưa người được bảo hiểm tới cơ sở y tế gần
          nhất có khả năng cung cấp dịch vụ y tế thích hợp
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">12</div>
        <div className="border cell-65-tv">
          Bảo lãnh viện phí: trong trường hợp chi phí điều trị vượt quá 2.000
          USD
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">13</div>
        <div className="border cell-65-tv">
          Bảo hiểm trong trường hợp khủng bố (loại trừ khủng bố hạt nhân, vũ khí
          hóa học, sinh học)
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">III</div>
        <div className="border cell-65-tv bg-color">HỖ TRỢ DU LỊCH</div>
        <div className="border cell-1-tv bg-color">GÓI 3</div>
        <div className="border cell-1-tv bg-color">GÓI 2</div>
        <div className="border cell-1-tv bg-color">GÓI 1</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">14</div>
        <div className="border cell-65-tv">
          Thiệt hại hành lý và tư trang: mất mát hay hỏng hành lý và tư trang do
          bị tai nạn, cướp, trộm cắp hay do vận chuyển nhầm. Giới hạn cho mỗi
          hạng mục là 100USD, mỗi đôi hoặc bộ là 200USD
        </div>
        <div className="border cell-1-tv">2,000</div>
        <div className="border cell-1-tv">1,400</div>
        <div className="border cell-1-tv">1,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">15</div>
        <div className="border cell-65-tv">
          Mất giấy tờ thông hành: chi phí xin cấp lại hộ chiếu, visa đã bị mất
          cùng chi phí đi lại và ăn ở phát sinh do việc xin cấp lại các giấy tờ
          đó. Giới hạn bồi thường tối đa một ngày là 10% của mức giới hạn cho
          quyền lợi này
        </div>
        <div className="border cell-1-tv">20,000</div>
        <div className="border cell-1-tv">10,400</div>
        <div className="border cell-1-tv">10,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">16</div>
        <div className="border cell-65-tv">
          Chuyến bay bị hoãn: trợ cấp tiền mặt cho mỗi 200USD trong mỗi 06 giờ
          liên tục do phương tiện vận chuyển theo lịch dự kiến bị hoãn khi ở
          nước ngoài (với điều kiên phải có chuyến khác thay thế).
        </div>
        <div className="border cell-1-tv">1,000</div>
        <div className="border cell-1-tv">700</div>
        <div className="border cell-1-tv">500</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">17</div>
        <div className="border cell-65-tv">
          Hủy/ hoãn chuyến đi: tiền đặt cọc không được hoàn lại cho chuyến đi và
          chi phí đi lại tăng lên vì hủy/hoãn chuyến do Người được bảo hiểm bị
          chết, ốm đau/ thương tật nghiêm trọng, phải ra làm chứng hay hầu tòa
          hoặc được cách ly để kiểm duyệt...
        </div>
        <div className="border cell-1-tv">5,000</div>
        <div className="border cell-1-tv">3,000</div>
        <div className="border cell-1-tv">2,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">18</div>
        <div className="border cell-65-tv">
          Trợ giúp chuyến đi 24/24 giờ - Thông tin về quy định chích ngừa và
          visa - Giới thiệu sứ quán - Trợ giúp thông tin về thời tiết - Trợ giúp
          thông tin về tỉ giá
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
    </div>
    <br />
    <div className="blue">
      QUYỀN LỢI BẢO HIỂM DÀNH CHO KHÁCH QUỐC TẾ VÀO VIỆT NAM - GÓI CÁ NHÂN
    </div>
    <div className="table">
      <div className="row-table bold">
        <div className="border cell-7-tv bg-green">
          QUYỀN LỢI CƠ BẢN BẮT BUỘC (ĐVT: USD) Tỉ giá tính tại thời điểm đơn vào
          hiệu lực
        </div>
        <div className="border cell-1-tv bg-green">Gói 3</div>
        <div className="border cell-1-tv bg-green">Gói 2</div>
        <div className="border cell-1-tv bg-green">Gói 1</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">I</div>
        <div className="border cell-65-tv bg-color">TAI NẠN CÁ NHÂN</div>
        <div className="border cell-1-tv bg-color">100,000</div>
        <div className="border cell-1-tv bg-color">70,000</div>
        <div className="border cell-1-tv bg-color">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">1</div>

        <div className="border cell-65-tv">- Từ 18 tuổi đến 65 tuổi</div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv"></div>

        <div className="border cell-65-tv">
          - Từ 06 tuần tuổi đến 18 tuổi và từ 66 tuổi trở lên
        </div>
        <div className="border cell-1-tv">50,000</div>
        <div className="border cell-1-tv">35,000</div>
        <div className="border cell-1-tv">25,000</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">II</div>
        <div className="border cell-65-tv bg-color">QUYỀN LỢI Y TẾ</div>
        <div className="border cell-1-tv bg-color">100,000</div>
        <div className="border cell-1-tv bg-color">70,000</div>
        <div className="border cell-1-tv bg-color">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">2</div>
        <div className="border cell-65-tv">
          Chi phí y tế do tai nạn hoặc bệnh bất ngờ: Viện phí, chi phí phẫu
          thuật, xe cấp cứu, thuốc, các xét nghiệm.
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">3</div>
        <div className="border cell-65-tv">
          Chi phí y tế liên quan đến thai sản: Chi phí y tế thực tế, hợp lý
          trong trường hợp sảy thai, nạo thai cần thiết theo chỉ định của bác sỹ
          phát sinh trong chuyến đi
        </div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">7,000</div>
        <div className="border cell-1-tv">5,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">4</div>
        <div className="border cell-65-tv">
          Vận chuyển hài cốt/ mai táng: vận chuyển hài cốt của Người được bảo
          hiểm về quê hương hoặc mai táng ngay tại địa phương
        </div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">7,000</div>
        <div className="border cell-1-tv">5,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">5</div>
        <div className="border cell-65-tv">
          Hồi hương: chi phí đưa người được bảo hiểm về Việt Nam hoặc quê hương
          (bao gồm cả chi phí cho thiết bị y tế cho thiết bị di động và nhân
          viên y tế đi kèm)
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">6</div>
        <div className="border cell-65-tv">
          Chi phí thăm viếng để thu xếp tang lễ ở nước ngoài: chi phí đi lại cho
          một người thân trong gia đình Người được bảo hiểm để hỗ trợ sắp xếp
          các việc liên quan đến việc xử lý thi hài và/ hoặc các thủ tục tang lễ
          ở nước ngoài
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">7</div>
        <div className="border cell-65-tv">
          Đưa trẻ em hồi hương: chi phí đi lại và ăn ở cần thiết phát sinh thêm
          cho một trẻ em dưới 14 tuổi để đưa trẻ em đó về Việt Nam hoặc quê
          hương
        </div>
        <div className="border cell-1-tv">10,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">8</div>
        <div className="border cell-65-tv">
          Trợ giúp y tế: - Tư vấn y tế - Giới thiệu các cơ sở y tế trên khắp thế
          giới - Thu xếp cuộc hẹn với bác sĩ y khoa - Theo dõi tình trạng của
          người được bảo hiểm trong thời gian nằm viện - Trợ giúp thu xếp vé máy
          bay khẩn cấp
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">9</div>
        <div className="border cell-65-tv">
          Vận chuyển y tế khẩn cấp: đưa người được bảo hiểm tới cơ sở y tế gần
          nhất có khả năng cung cấp dịch vụ y tế thích hợp
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">10</div>
        <div className="border cell-65-tv">
          Bảo lãnh viện phí: trong trường hợp chi phí điều trị vượt quá 2.000
          USD
        </div>
        <div className="border cell-1-tv">100,000</div>
        <div className="border cell-1-tv">70,000</div>
        <div className="border cell-1-tv">50,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">11</div>
        <div className="border cell-65-tv">
          Bảo hiểm trong trường hợp khủng bố (loại trừ khủng bố hạt nhân, vũ khí
          hóa học, sinh học)
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
      <div className="row-table bold">
        <div className="border cell-05-tv bg-color">III</div>
        <div className="border cell-65-tv bg-color">HỖ TRỢ DU LỊCH</div>
        <div className="border cell-1-tv bg-color">GÓI 3</div>
        <div className="border cell-1-tv bg-color">GÓI 2</div>
        <div className="border cell-1-tv bg-color">GÓI 1</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">12</div>
        <div className="border cell-65-tv">
          Thiệt hại hành lý và tư trang: mất mát hay hỏng hành lý và tư trang do
          bị tai nạn, cướp, trộm cắp hay do vận chuyển nhầm. Giới hạn cho mỗi
          hạng mục là 100USD, mỗi đôi hoặc bộ là 200USD
        </div>
        <div className="border cell-1-tv">2,000</div>
        <div className="border cell-1-tv">1,400</div>
        <div className="border cell-1-tv">1,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">13</div>
        <div className="border cell-65-tv">
          Mất giấy tờ thông hành: chi phí xin cấp lại hộ chiếu, visa đã bị mất
          cùng chi phí đi lại và ăn ở phát sinh do việc xin cấp lại các giấy tờ
          đó. Giới hạn bồi thường tối đa một ngày là 10% của mức giới hạn cho
          quyền lợi này
        </div>
        <div className="border cell-1-tv">20,000</div>
        <div className="border cell-1-tv">10,400</div>
        <div className="border cell-1-tv">10,000</div>
      </div>
      <div className="row-table">
        <div className="border cell-05-tv">14</div>
        <div className="border cell-65-tv">
          Trợ giúp chuyến đi 24/24 giờ - Thông tin về quy định chích ngừa và
          visa - Giới thiệu sứ quán - Trợ giúp thông tin về thời tiết - Trợ giúp
          thông tin về tỉ giá
        </div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
        <div className="border cell-1-tv">bao gồm</div>
      </div>
    </div>
    <div className="text-bold">Đối Tượng Bảo Hiểm</div>
    BẢO HIỂM DU LỊCH NỘI ĐỊA - GÓI ĐOÀN
    <br />
    - ĐỘ TUỔI BẢO HIỂM: không giới hạn
    <br />
    BẢO HIỂM DU LỊCH TOÀN CẦU - GÓI ĐOÀN
    <br />
    - ĐỘ TUỔI BẢO HIỂM: 06 tuần tuổi đến hết 85 tuổi
    <br />
    BẢO HIỂM DU LỊCH QUỐC TẾ - GÓI CÁ NHÂN
    <br />
    - ĐỘ TUỔI BẢO HIỂM: 06 tuần tuổi đến hết 85 tuổi
    <br />
    BẢO HIỂM DÀNH CHO KHÁCH QUỐC TẾ VÀO VIỆT NAM - GÓI CÁ NHÂN
    <br />- ĐỘ TUỔI BẢO HIỂM: 06 tuần tuổi đến hết 85 tuổi
    <div className="text-bold">Quy Tắc Bảo Hiểm:</div>
    - Phụ lục 1: Áp dụng qui tắc BH du lịch trong nước của MIC ban hành kèm theo
    Quyết định số 77/2016/QĐ-MIC ngày 01/01/2016
    <br />
    - Phụ lục 2: Áp dụng qui tắc BH du lịch toàn cầu của MIC, ban hành kèm theo
    Quyết định số 83/2016/QĐ-MIC ngày 01/01/2016
    <br />
    - Phụ lục 3: Áp dụng qui tắc BH du lịch toàn cầu của MIC, ban hành kèm theo
    Quyết định số 83/2016/QĐ-MIC ngày 01/01/2016
    <br />
    - Phụ lục 4: Áp dụng qui tắc BH du khách quốc tế của MIC, ban hành kèm theo
    Quyết định số 85/2016/QĐ-MIC ngày 01/01/2016
    <br />- Phụ lục 5: BH chậm hành lý (Áp dụng Qui tắc Du khách quốc tế)
    <div className="text-bold"> Phạm Vi Bồi Thường:</div>
    BẢO HIỂM DU LỊCH NỘI ĐỊA - GÓI ĐOÀN
    <br />
    - KHU VỰC BẢO HIỂM: Việt Nam
    <br />
    BẢO HIỂM DU LỊCH TOÀN CẦU - GÓI ĐOÀN
    <br />
    - KHU VỰC BẢO HIỂM: Toàn cầu
    <br />
    BẢO HIỂM DU LỊCH QUỐC TẾ - GÓI CÁ NHÂN
    <br />
    - KHU VỰC BẢO HIỂM: Toàn cầu
    <br />
    BẢO HIỂM DÀNH CHO KHÁCH QUỐC TẾ VÀO VIỆT NAM - GÓI CÁ NHÂN
    <br />- KHU VỰC BẢO HIỂM: Việt Nam
    <div className="text-bold"> Các Điểm Loại Trừ Bồi Thường:</div>
    - Bảo hiểm du khách quốc tế:
    <br />
    Trong mọi trường hợp MIC không nhận bảo hiểm và không chịu trách nhiệm đối
    với những người trên 75 tuổi trừ khi đã được chấp nhận bằng văn bản từ trước
    ngày bắt đầu bảo hiểm.
    <br />- Quy tắc bảo hiểm Khách du lịch trong nước:
    <div className="ml-25">
      • Hành động cố ý của người được bảo hiểm hoặc người thừa kế hợp pháp (là
      Người được chỉ định nhận tiền bảo hiểm theo Giấy chứng nhận bảo hiểm hoặc
      di chúc hay theo pháp luật);
      <br />
      • Người được bảo hiểm vi phạm pháp luật, nội quy, quy định của cơ quan du
      lịch, của chính quyền địa phương nơi du lịch;
      <br />
      • Người nước ngoài sử dụng hoặc bị ảnh hưởng của rượu, bia, ma túy và các
      chất kích thích tương tự khác. Các chất kích thích được hiểu theo quy tắc
      là các chất mà người được bảo hiểm sử dụng để gây nghiện, gây tê liệt thần
      kinh không kiểm soát được hành động của bản thân và pháp luật nghiêm cấm;
      <br />
      • Điều trị hoặc sử dụng thuốc không theo chỉ dẫn của y, bác sĩ điều trị;
      <br />
      • Chiến tranh , khủng bố;
      <br />• Bệnh có sẵn và bệnh đặc biệt trừ trường hợp có thỏa thuận riêng
      trong Hợp đồng bảo hiểm.
    </div>
    <div className="text-bold">Hồ Sơ Yêu Cầu Bồi Thường</div>
    Trong thời gian sớm nhất có thể và trong mọi trường hợp không quá ba mươi
    (30) ngày kể từ ngày biết sự kiện xảy ra mà có thể dẫn đến khiếu nại, bên
    mua bảo hiểm phải thông báo cho nhà Bảo hiểm Quân đội MIC bằng văn bản về sự
    kiện bảo hiểm.
    <br />
    Số Hotline: 1900 55 88 91
  </>
);
export const vcoto = (
  <>
    <div className="text-bold">
      Bạn muốn tìm hiểu và so sánh giá sản phẩm Bảo hiểm Vật chất xe ô tô trước
      khi tham gia? Với Bảo hiểm Vật chất xe ô tô của Global Care, bạn có thể so
      sánh báo giá và lựa chọn trong 4 nhà bảo hiểm uy tín như VNI, Bảo Minh,
      PTI, PVI thật dễ dàng và nhanh chóng. Cùng với giao diện thân thiện giúp
      tăng trải nghiệm nhanh chóng, thao tác đơn giản 3 bước không cần giấy tờ
      phức tạp, không chờ đợi, không giám định viên.
      <br />
    </div>
    <div className="text-bold">Quyền lợi bảo hiểm</div>
    🚗BẢO VỆ Ô TÔ CỦA BẠN TRƯỚC ĐA DẠNG TRƯỜNG HỢP: <br />
    Quyền lợi cơ bản: <br />- Tổn thất toàn bộ
    <br /> - Tổn thất bộ phận
    <br /> - Mất cắp toàn bộ
    <br /> 🚗Quyền lợi bổ sung tha hồ chọn lựa: <br />- Bảo hiểm khấu hao thay
    thế mới
    <br /> - Bảo hiểm thủy kích
    <br /> - Bảo hiểm sửa chữa chính hãng
    <br /> - Bảo hiểm mất cắp bộ phận (giới hạn 2 lần/năm)
    <br /> Mức khấu trừ sẽ được ghi cụ thể khi bấm vào xem chi tiết theo từng
    nhà bảo hiểm trên ứng dụng GSale của Global Care, giúp bạn dễ dàng so sánh
    và lựa chọn nhà bảo hiểm yêu thích.
    <div className="text-bold">Đối Tượng Bảo Hiểm</div>
    Xe cơ giới hoạt động trên lãnh thổ Việt Nam bao gồm thân vỏ, máy móc và
    trang thiết bị khác trên xe. <br />
    Là những xe ô tô định danh theo biển số đăng ký hay số khung và số máy (đối
    với xe chưa hoàn tất thủ tục đăng ký theo luật định).
    <div className="text-bold">Quy Tắc Bảo Hiểm</div>
    - Sản phẩm Bảo hiểm Vật chất xe của VNI: Vui lòng xem “Quy tắc Bảo hiểm kết
    hợp xe cơ giới Ban hành theo Quyết định số 187/2019/QĐ-BHHK ngày 27/03 năm
    2019 của Tổng giám đốc công ty cổ phần bảo hiểm hàng không (VNI)”.
    <br />
    - Sản phẩm Bảo hiểm Vật chất xe của BM: Vui lòng xem “Quy tắc, điều khoản
    Bảo hiểm xe ô tô (Ban hành theo Quyết định số 2288/2018-BM/XCG ngày
    06/12/2018 của Tổng giám đốc Tổng Công ty Cổ phần Bảo Minh)”.
    <br />
    - Sản phẩm Bảo hiểm Vật chất xe của PTI: Vui lòng xem “Quy tắc Bảo hiểm tự
    nguyện xe cơ giới Ban hành kèm theo Quyết định số 370/2018/QĐ-PTI ngày
    26/12/2018 của Tổng giám đốc Tổng công ty Cổ phần Bảo hiểm Bưu điện (PTI)”.
    <br />- Sản phẩm Bảo hiểm Vật chất xe của PVI: Vui lòng xem “Quy tắc Bảo
    hiểm tự nguyện xe Ô tô (Ban hành theo Quyết định số 938/QĐ-PVIBH ngày 03
    tháng 12 năm 2021 của Tổng giám đốc Tổng Công ty Bảo hiểm PVI trên cơ sở
    chấp thuận của Bộ tài chính theo công văn số 14569/BTC-QLBH ngày 22/11/2018
    và 13080/BTC-QLBH ngày 16/11/2021)"
    <div className="text-bold">Phạm Vi Bồi Thường</div>
    Nhà bảo hiểm sẽ chịu trách nhiệm bồi thường cho chủ xe những thiệt hại vật
    chất xe trước đa dạng trường hợp:
    <br />
    1.1. Đâm va (bao gồm cả va chạm với vật thể khác ngoài xe ô tô), lật, đổ,
    chìm, rơi toàn bộ xe, bị các vật thể khác rơi vào;
    <br />
    1.2. Hỏa hoạn, cháy, nổ;
    <br />
    1.3. Những tai họa bất khả kháng do thiên nhiên gây ra (như: Bão, lũ lụt,
    sạt lở, mưa đá, sét đánh, động đất, sóng thần...);
    <br />
    1.4. Mất toàn bộ xe do trộm, cướp. Ngoài ra, nhà bảo hiểm còn bồi thường cho
    chủ xe những chi phí cần thiết và hợp lý theo thỏa thuận tại Hợp đồng bảo
    hiểm để thực hiện các yêu cầu và chỉ dẫn của nhà bảo hiểm (thuộc phạm vi bảo
    hiểm). Vui lòng xem thêm thông tin quyền lợi, điều kiện, điều khoản chi tiết
    của sản phẩm được thể hiện trực quan khi bạn tiến hành thao tác tham gia sản
    phẩm. Trong mọi trường hợp, tổng số tiền bồi thường đối với một sự kiện bảo
    hiểm không vượt quá số tiền bảo hiểm đã ghi trên Hợp đồng bảo hiểm/ Giấy
    chứng nhận bảo hiểm.
    <div className="text-bold">Các Điểm Loại Trừ Bồi Thường</div>
    - Hành động cố ý gây thiệt hại của Chủ xe cơ giới, Lái xe hoặc người bị
    thiệt hại hoặc người có quyền lợi liên quan đến sở hữu và khai thác sử dụng
    xe;
    <br />
    - Tại thời điểm xe tham gia giao thông xảy ra tổn thất, xe không có Giấy
    chứng nhận kiểm định an toàn kỹ thuật và bảo vệ môi trường phương tiện giao
    thông cơ giới đường bộ hợp lệ theo quy định của Pháp luật hiện hành. (Điểm
    loại trừ này không áp dụng đối với xe trong thời gian thực hiện thủ tục đăng
    ký lần đầu tiên tại Việt Nam).
    <br />
    - Lái xe không có Giấy phép lái xe/Giấy phép điều khiển xe hoặc Giấy phép
    lái xe/ Giấy phép điều khiển xe không phù hợp đối với loại xe cơ giới bắt
    buộc phải có Giấy phép lái xe.
    <br />
    - Lái xe trong tình trạng sử dụng bia, rượu hoặc có nồng độ cồn trong máu
    hoặc khí thở, trong cơ thể có chất ma túy hoặc chất kích thích khác bị cấm
    theo quy định của Pháp luật.
    <br />
    Ngoài ra, tùy nhà bảo hiểm sẽ có điểm loại trừ chi tiết riêng, vui lòng xem
    chi tiết tại Quy tắc của từng nhà bảo hiểm khi tham gia sản phẩm.
    <br />
    <div className="text-bold">Hồ Sơ Yêu Cầu Bồi Thường</div>
    Bước 1: Liên hệ ngay cho nhà bảo hiểm theo số điện thoại ghi trên Giấy chứng
    nhận bảo hiểm hoặc hotline bên dưới để thông báo sự cố và được hướng dẫn cụ
    thể: <br />
    VNI - Tổng Công Ty Cổ Phần Bảo hiểm hàng không : 1900 9696 90 <br />
    BMI - Tổng Công ty Bảo hiểm Bảo Minh: 1800 5888 12 <br />
    PVI - Tổng Công ty Bảo hiểm Dầu khí Việt Nam: 1900 5454 58 <br />
    PTI - Tổng Công ty Bảo hiểm Bưu điện: 1900 068 888 <br />
    Bước 2: Mang xe đến Gara gần nhất <br />
    Bước 3: Nhận lại xe sau khi sửa chữa tại Gara
  </>
);
export const bhyt = (
  <>
    <div className="text-bold">
      Bảo hiểm y tế hộ gia đình là chính sách bảo hiểm của Đảng và Nhà nước, vì
      quyền lợi của người lao động và Nhân dân, được Nhà nước bảo hộ, do cơ quản
      Bảo hiểm xã hội thực hiện. Đặc biệt, từ ngày 23/12/2022, Global Care đã
      chính thức trở thành Đại lý thu của BHXH Việt Nam. Quý khách hàng có thể
      tham gia BHYT hộ gia đình hoàn toàn trực tuyến chỉ với vài phút ngay trên
      ứng dụng GSale của Global Care.
      <br />
      Quyền Lợi Bảo Hiểm:
    </div>
    Tham gia BHYT, bạn sẽ hưởng được nhiều quyền lợi khám chữa bệnh (KCB) trong
    phạm vi quyền lợi và mức hưởng tại các cơ sở, trung tâm y tế và bệnh viện
    theo quy định.
    <br />
    - Được cấp thẻ BHYT.
    <br />
    - Được chi trả chi phí khám chữa bệnh (KCB) trong phạm vi quyền lợi và mức
    hưởng quy định trên thẻ BHYT
    <br />
    - Được lựa chọn và thay đổi nơi đăng ký KCB ban đầu vào tháng đầu mỗi quý.
    <br />- Được khiếu nại, tố cáo hành vi vi phạm pháp luật về BHYT.12
    <div className="text-bold">Đối Tượng Bảo Hiểm:</div>
    - Những người có tên trong Sổ hộ khẩu/Sổ tạm trú hoặc cùng đăng ký thường
    trú/tạm trú tại một chỗ ở hợp pháp theo quy định của pháp luật.
    <br />
    - Đăng ký thường trú/tạm trú tại một chỗ ở hợp pháp theo quy định của pháp
    luật.
    <br />
    - Chức sắc, chức việc, nhà tu hành.
    <br />
    - Người sinh sống trong cơ sở bảo trợ xã hội, trừ những người đã có thẻ BHYT
    thuộc nhóm tham gia khác
    <br />
    - Chức sắc, chức việc, nhà tu hành, người sinh sống trong cơ sở bảo trợ xã
    hội mà không được ngân sách nhà nước hỗ trợ đóng BHYT
    <br />- Trừ những người tham gia bảo hiểm y tế (BHYT) thuộc nhóm khác mà
    không được ngân sách nhà nước hỗ trợ đóng BHYT.
    <div className="text-bold">Quy Tắc Bảo Hiểm:</div>
    <a
      href="https://drive.google.com/file/d/1ydDCy8_ZLGWuNN3CuyGXC8UQzhT5zMGW/view"
      target="_bank"
    >
      - Theo Nghị định 146/2018/NĐ-CP về BHYT
    </a>
    <br />
    <a
      href="https://drive.google.com/file/d/1YxQgSDG-z6s0TVpAlYI6RLy9wfXgB49U/view"
      target="_bank"
    >
      - Thông tư 41/2014/TTLT-BYT-BTC – Hướng dẫn Thực hiện BHYT
    </a>
    <br />
    <a
      href="https://drive.google.com/file/d/1C55IBLpxW5icG2Cvln9J1HFRH1E4n7FE/view"
      target="_bank"
    >
      - Luật số 46/2014/QH13 sửa đổi
    </a>
    <div className="text-bold">Phạm Vi Bồi Thường:</div>
    Việt Nam
    <div className="text-bold">Các Điểm Loại Trừ Bồi Thường:</div>
    Theo quy tắc luật BHYT đang hiện hành
    <div className="text-bold">Hồ Sơ Yêu Cầu Bồi Thường:</div>
    Theo quy tắc luật BHYT đang hiện hành
  </>
);
// export const bm_care = (
//   <>
//     <div className="text-bold">
//       Chúng ta đều biết rằng sức khỏe là quan trọng nhất. Nhưng không phải ai
//       cũng thực sự xem sức khỏe là ưu tiên hàng đầu, nhất là khi đang còn khỏe.
//       Tuy nhiên, những rủi ro ốm đau, bệnh tật là điều không thể biết trước
//       được. Chuẩn bị một kế hoạch tài chính dự phòng trước rủi ro bệnh tật khi
//       còn khỏe mạnh là điều vô cùng cần thiết. Chỉ cần vài thao tác đơn giản
//       trên ứng dụng GSale, BM Care sẽ bảo vệ sức khỏe gia đình bạn để an nhiên
//       vui sống. BM Care là bảo hiểm sức khỏe gia đình do Global Care & Tổng Công
//       ty CP Bảo Minh phát triển và được phân phối trực tuyến trên ứng dụng GSale
//       của Global Care.
//       <br />
//       Quyền lợi bảo hiểm:
//     </div>
//     <div className="table">
//       <div className="row-table">
//         <div className="border cell-05-bm bg-green">STT</div>
//         <div className="border cell-35-bm bg-green">Quyền lợi bảo hiểm</div>
//         <div className="border cell-2-bm bg-green">Phổ Thông</div>
//         <div className="border cell-2-bm bg-green">Cao Cấp</div>
//         <div className="border cell-2-bm bg-green">Thượng Hạng</div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bold bg-lgreen">I</div>
//         <div className="border cell-35-bm bold bg-lgreen">Bảo hiểm chính</div>
//         <div className="border cell-2-bm bold bg-lgreen">50.000.000 VNĐ</div>
//         <div className="border cell-2-bm bold bg-lgreen">100.000.000 VNĐ</div>
//         <div className="border cell-2-bm bold bg-lgreen">150.000.000 VNĐ</div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen">1</div>
//         <div className="border cell-35-bm bg-color">
//           Trường hợp chết (Do mọi nguyên nhân) (*)
//         </div>
//         <div className="border cell-2-bm bg-color">100% số tiền bảo hiểm</div>
//         <div className="border cell-2-bm bg-color">100% số tiền bảo hiểm</div>
//         <div className="border cell-2-bm bg-color">100% số tiền bảo hiểm</div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen">2</div>
//         <div className="border cell-35-bm bg-color">
//           Thương tật thân thể vĩnh viễn do tai nạn
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Tỷ lệ % của Số tiền bảo hiểm dựa theo Bảng tỷ lệ thương tật
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Tỷ lệ % của Số tiền bảo hiểm dựa theo Bảng tỷ lệ thương tật
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Tỷ lệ % của Số tiền bảo hiểm dựa theo Bảng tỷ lệ thương tật
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen">3</div>
//         <div className="border cell-35-bm bg-color">
//           Thương tật thân thể tạm thời do tai nạn
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí điều trị thực tế không quá tỷ lệ % theo Bảng tỷ lệ thương tật
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí điều trị thực tế không quá tỷ lệ % theo Bảng tỷ lệ thương tật
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí điều trị thực tế không quá tỷ lệ % theo Bảng tỷ lệ thương tật
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen">4</div>
//         <div className="border cell-35-bm bg-color">
//           Nằm viện tại bệnh viện Tây y do ốm đau, bệnh tật, thai sản. (Tối đa 60
//           ngày /năm bảo hiểm)
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế không quá 250.000VNĐ/ngày
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế không quá 500.000VNĐ/ngày
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế không quá 750.000VNĐ/ngày
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen">5</div>
//         <div className="border cell-35-bm bg-color">
//           Nằm viện tại bệnh viện Đông y do ốm đau, bệnh tật, thai sản (Tối đa
//           120 ngày /năm bảo hiểm)
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế không quá 150.000VNĐ/ngày
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế không quá 300.000VNĐ/ngày
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế không quá 450.000VNĐ/ngày
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen">6</div>
//         <div className="border cell-35-bm bg-color">
//           Phẫu thuật do ốm đau, bệnh tật, thai sản
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế, không quá tỷ lệ % theo Bảng tỷ lệ phẫu thuật.
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế, không quá tỷ lệ % theo Bảng tỷ lệ phẫu thuật.
//         </div>
//         <div className="border cell-2-bm bg-color">
//           Chi phí thực tế, không quá tỷ lệ % theo Bảng tỷ lệ phẫu thuật.
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bold bg-lgreen">II</div>
//         <div className="border cell-95-bm bg-lgreen">Bảo hiểm mở rộng</div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen text-center">1</div>
//         <div className="column">
//           <div className="row-table bg-color">
//             <div className="border cell-35-bm">
//               Điều trị ngoại trú do ốm đau, bệnh tật, thai sản
//             </div>
//             <div className="border cell-2-bm">1.250.000VNĐ</div>
//             <div className="border cell-2-bm">2.000.000VNĐ</div>
//             <div className="border cell-2-bm">2.500.000VNĐ</div>
//           </div>
//           <div>
//             <div className="row-table bg-color">
//               <div className="border cell-35-bm">
//                 Theo chi phí thực tế (Tối đa 5 lần/năm bảo hiểm)
//               </div>
//               <div className="border cell-2-bm">200.000VNĐ/ngày nằm viện</div>
//               <div className="border cell-2-bm">400.000VNĐ/lần</div>
//               <div className="border cell-2-bm">500.000VNĐ/lần</div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="row-table">
//         <div className="border cell-05-bm bg-lgreen text-center">2</div>
//         <div className="column">
//           <div className="row-table bg-color">
//             <div className="border cell-35-bm">
//               Mất giảm thu nhập trong thời gian nằm viện do tai nạn hay do ốm
//               đau, bệnh tật, thai sản
//             </div>
//             <div className="border cell-2-bm">6.000.000VNĐ</div>
//             <div className="border cell-2-bm">9.000.000VNĐ</div>
//             <div className="border cell-2-bm">12.000.000VNĐ</div>
//           </div>
//           <div>
//             <div className="row-table bg-color">
//               <div className="border cell-35-bm">
//                 (Tối đa 60 ngày /năm bảo hiểm, không áp dụng cho người dưới 18
//                 tuổi và chỉ trả từ ngày thứ 5 trở đi)
//               </div>
//               <div className="border cell-2-bm">100.000VNĐ/ngày nằm viện</div>
//               <div className="border cell-2-bm">150.000VNĐ/ngày nằm viện</div>
//               <div className="border cell-2-bm">200.000VNĐ/ngày nằm viện</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="text-bold"> Đối Tượng Bảo Hiểm</div>
//     Người được bảo hiểm: Mọi công dân Việt Nam và người nước ngoài đang sinh
//     sống tại Việt Nam từ 1 tuổi đến 65 tuổi; trẻ em dưới 18 tuổi sẽ do
//     Bố/Mẹ/Người giám hộ đại diện làm Bên mua bảo hiểm.
//     <div className="text-bold">Quy Tắc Bảo Hiểm</div>
//     Theo Quy tắc bảo hiểm sức khỏe gia đình ban hành theo Quyết định số:{" "}
//     <a
//       href="https://drive.google.com/file/d/16ueeXEu2ZeMjukVu8PfqKyczc2NVD0aw/preview"
//       target="_bank"
//     >
//       1508/2018 -BM/BHCN
//     </a>{" "}
//     ngày 17/08/2018 của Tổng Giám đốc Tổng Công ty Cổ phần Bảo Minh.
//     <div className="text-bold">Phạm Vi Bồi Thường</div>
//     Việt Nam
//     <div className="text-bold">Các Điểm Loại Trừ Bồi Thường</div>
//     Các điểm loại trừ bồi thường:
//     <br />
//     a. Những người bị bệnh tâm thần, phong, ung thư;
//     <br />
//     b. Những người bị tàn phế hoặc thương tật vĩnh viễn từ 50% trở lên;
//     <br />
//     c. Những người đang trong thời gian điều trị bệnh tật, thương tật;
//     <br />
//     d. Những người trên 65 tuổi
//     <div className="text-bold">Hồ Sơ Yêu Cầu Bồi Thường</div>
//     Liên hệ số Hotline của Bảo hiểm Bảo Minh 1800 588 812 và làm theo hướng dẫn
//   </>
// );
export const tk_htri = (
  <>
    <div>
      Tiết kiệm Hưu trí được kiến tạo bởi liên minh 4 đơn vị hàng đầu trong các
      lĩnh vực như Công ty Bảo hiểm Bảo Minh, Quỹ Đầu tư Tài chính VinaCapital,
      thực hiện đầu tư qua Fmarket là nền tảng giao dịch quỹ mở đã được Ủy ban
      Chứng khoán Nhà nước cấp phép, vận hành hệ thống công nghệ và phát triển
      kinh doanh bởi Global Care.
    </div>
    <br />
    <div>
      Bảo hiểm Tiết kiệm Hưu trí được phân phối trên nền tảng công nghệ GSale
      của Global Care với nhiều quyền lợi vượt trội cho khách hàng khi kết hợp
      yếu tố bảo vệ, đầu tư sinh lời và tích lũy giá trị tài khoản theo thời
      gian. Sản phẩm được biết đến như một dấu ấn tiên phong trên thị trường
      Công nghệ Bảo hiểm hiện nay.
    </div>
    <div className="text-bold">Quyền lợi:</div>
    <div>
      Tham gia Tiết kiệm Hưu trí, khách hàng sẽ chủ động lựa chọn nhiều quyền
      lợi với giải pháp bảo vệ:
    </div>
    <div className="text-bold">
      🛡 💥 TÓM TẮT VỚI 4 QUYỀN LỢI BẢO HIỂM SỨC KHỎE VÀNG:
    </div>
    <div>Quyền lợi chính:</div>
    <div>
      ✅ Nằm viện, phẫu thuật do ốm đau, bệnh tật, thai sản: Số tiền bảo hiểm
      lên đến 40 triệu đồng
    </div>
    <div>Quyền lợi bổ sung:</div>
    <div>
      ✅ Tử vong, thương tật bộ phận vĩnh viễn, toàn bộ do ốm đau, thai sản: Số
      tiền bảo hiểm lên đến 40 triệu đồng
      <br />
      ✅ Tử vong, thương tật bộ phận vĩnh viễn, toàn bộ vĩnh viễn do tai nạn: Số
      tiền bảo hiểm lên đến 200 triệu đồng
      <br />✅ Chi phí y tế do tai nạn: Số tiền bảo hiểm lên đến 30 triệu đồng
    </div>
    <div className="text-bold">
      🔥 5 ĐẶC ĐIỂM NỔI BẬT KHI THAM GIA SẢN PHẨM TRÊN GSALE:
    </div>
    <div>
      • ĐƠN GIẢN: Sản phẩm bảo hiểm được thiết kế đơn giản, tinh gọn, dễ hiểu.{" "}
      <br />
      • LINH HOẠT: Chia nhỏ số kỳ đóng phí - 12 kỳ/ năm và có thể rút tiền bất
      cứ khi nào. <br />
      • PHÍ CẠNH TRANH: Có thể góp hàng tháng, từ 300k/kỳ. <br />
      • SỐ HÓA HOÀN TOÀN: Từ khâu tham gia, quản lý và bồi thường. <br />• TÍCH
      LŨY GIÁ TRỊ TÀI KHOẢN THEO THỜI GIAN: An tâm tài chính tuổi về hưu.
    </div>
    <div className="text-bold">
      🔥 6 QUYỀN LỢI ƯU VIỆT của TIẾT KIỆM HƯU TRÍ:
    </div>
    <div>
      • Được bảo vệ <br />
      • Tiết kiệm linh động <br />
      • Đầu tư an toàn <br />
      • An nhàn hưu trí <br />
      • Di sản thừa kế: Cho người thân yêu
      <br />• Đặc quyền luật sư riêng: Đồng hành tư vấn, giải đáp pháp lý suốt
      thời hạn hợp đồng
    </div>
    <div className="text-bold">Đối tượng bảo hiểm:</div>
    <div className="ml-25">
      + Công dân Việt Nam
      <br />
      + Tuổi tham gia của Người được bảo hiểm: Từ đủ 18 tuổi đến 65 tuổi
      <br />+ Tuổi của Người mua Bảo hiểm: Từ 18 tuổi đến 65 tuổi
    </div>
    <div className="text-bold">Phạm vi bảo hiểm:</div>
    <div> Việt Nam</div>
    <div className="text-bold">Quy tắc bảo hiểm:</div>
    <div>
      Quy tắc bảo hiểm Sức khỏe nhóm và quy tắc bảo hiểm tai nạn nhóm ban hành
      theo quyết định số 0432 – 0433/2010-BM/QLNV ngày 01/03/2010 của Tổng Giám
      đốc Tổng Công ty Cổ phần Bảo Minh.
    </div>
    <div className="text-bold">Điểm loại trừ bảo hiểm:</div>
    <div>Các điểm loại trừ bồi thường:</div>
    <div className="ml-25">
      a. Những người bị bệnh tâm thần, phong, ung thư;
      <br />
      b. Những người bị tàn phế hoặc thương tật vĩnh viễn từ 50% trở lên;
      <br />
      c. Những người đang trong thời gian điều trị bệnh tật, thương tật;
      <br />
      d. Những người trên 65 tuổi
    </div>
    <div className="text-bold">Hồ sơ yêu cầu bồi thường:</div>
    <div className="ml-25">
      + Bước 1: Liên hệ số Hotline của Bảo hiểm Bảo Minh 1800 588 812 <br />
      + Bước 2: Làm theo hướng dẫn <br />+ Bước 3: Nhận kết quả
    </div>
    <div className="text-bold">Trường hợp yêu cầu trả tiền bảo hiểm:</div>
    <div>
      Khi yêu cầu trả tiền bảo hiểm, người được bảo hiểm hoặc người thụ hưởng
      phải gửi cho Bảo Minh các giấy tờ chính sau đây:
      <br /> 1. Giấy yêu cầu trả tiền bảo hiểm theo mẫu của Bảo Minh.
      <br />
      2. Giấy chứng nhận bảo hiểm hoặc Hợp đồng bảo hiểm (Bản sao).
      <br />
      3. Biên bản tai nạn có xác nhận của cơ quan, đơn vị chính quyền địa phương
      hoặc Công an nơi Người được bảo hiểm bị tai nạn (trường hợp bị tai nạn).
      <br />
      4. Các chứng từ y tế: giấy ra viện (trường hợp điều trị nội trú), phiếu mổ
      (trường hợp phẫu thuật) ... <br />
      5. Giấy chứng tử (trường hợp Người được bảo hiểm chết).
      <br />
      6. Chứng từ chứng minh quyền thụ hưởng hợp pháp trong trường hợp Người
      được bảo hiểm chết hay giấy ủy quyền nhận tiền bảo hiểm trong trường hợp
      thay mặt Người được bảo hiểm nhận tiền bảo hiểm.
    </div>
  </>
);
// export const skv = (
//   <>
//     <div>
//       <b>Bảo hiểm Sức khỏe vàng</b> là sản phẩm do Global Care liên kết với Tổng
//       Công ty CP Bảo hiểm Bảo Minh với mong muốn đưa bảo hiểm đến gần hơn với
//       mọi người để ai cũng được chăm sóc sức khỏe với mức phí phù hợp tài chính
//       từng người. Đỡ lo viện phí với mức phí mini, được trả góp hàng tháng chỉ
//       từ 2.300 đ/ngày!
//     </div>
//     <br />
//     <div>
//       <b>➡ Minh họa kế hoạch bảo vệ & </b>tham gia tại GSale chỉ trong 1 phút
//       nhé !
//     </div>
//     <div className="text-bold">Quyền Lợi Bảo Hiểm:</div>
//     <div className="text-bold">
//       ✨ CHỈ TỪ 2.300Đ/NGÀY, bạn sẽ được bảo vệ sức khỏe toàn diện với các quyền
//       lợi:
//     </div>
//     <div>
//       🍀Bồi thường chi phí điều trị nội trú do ốm đau, bệnh tật, thai sản lên
//       đến 400 triệu đồng.
//       <br />
//       🍀Mở rộng quyền lợi bảo vệ đến 1 TỶ đồng đối với trường hợp Tử vong/
//       Thương tật vĩnh viễn do tai nạn.
//       <br />
//       🍀Bảo lãnh viện phí tại hơn 200 cơ sở y tế trên toàn quốc.
//       <br />
//     </div>
//     <div className="text-bold">
//       ✨ TÙY CHỈNH KẾ HOẠCH BẢO VỆ THEO NGÂN SÁCH VÀ NHU CẦU:
//     </div>
//     <div>
//       🍀 Được lựa chọn quyền lợi bảo hiểm phù hợp với nhu cầu
//       <br />
//       🍀 Phí bảo hiểm linh hoạt theo quyền lợi tham gia
//       <br />
//       🍀 Được góp phí bảo hiểm hàng tháng
//       <br />
//     </div>
//     <div className="text-bold">Đối Tượng Bảo Hiểm:</div>
//     <div className="ml-25">
//       • Công dân Quốc tịch Việt Nam
//       <br />
//       • Tuổi tham gia của Người được bảo hiểm: Từ Đủ 12 tháng tuổi đến đủ 65
//       tuổi
//       <br />
//       • Tuổi của Người mua Bảo hiểm: Từ 18 tuổi đến 65 tuổi
//       <br />
//       • Người nước ngoài cư trú hợp pháp tại Việt Nam
//       <br />
//     </div>
//     <div className="text-bold">Quy Tắc Bảo Hiểm:</div>
//     <div>
//       Quy tắc bảo hiểm Sức khỏe nhóm và quy tắc bảo hiểm tai nạn nhóm ban hành
//       theo quyết định số{" "}
//       <a
//         href="https://drive.google.com/file/d/1xfyRjnjjTNloEaGRGF3Qg_xRaXEfE_Sn/view "
//         target="_blank"
//       >
//         0432 – 0433/2010-BM/QLNV
//       </a>
//       ngày 01/03/2010 của Tổng Giám đốc Tổng Công ty Cổ phần Bảo Minh.
//     </div>
//     <div className="text-bold">Phạm Vi Bồi Thường:</div>
//     <div className="text-bold">Việt Nam</div>
//     <div className="text-bold">Các điểm loại trừ bồi thường:</div>
//     <div className="ml-25">
//       a. Những người bị bệnh tâm thần, phong, ung thư;
//       <br />
//       b. Những người bị tàn phế hoặc thương tật vĩnh viễn từ 50% trở lên;
//       <br />
//       c. Những người đang trong thời gian điều trị bệnh tật, thương tật;
//       <br />
//       d. Những người trên 65 tuổi
//     </div>
//     <div className="text-bold">Hồ Sơ Yêu Cầu Bồi Thường:</div>
//     <div className="ml-25">
//       Bước 1: Liên hệ số Hotline của Bảo hiểm <b>Bảo Minh 1800 588 812</b>
//       <br />
//       Bước 2: Làm theo hướng dẫn
//       <br />
//       Bước 3: Nhận kết quả Trường hợp yêu cầu trả tiền bảo hiểm:
//     </div>
//     <div className="text-bold">Trường hợp yêu cầu trả tiền bảo hiểm:</div>
//     <div>
//       Khi yêu cầu trả tiền bảo hiểm, người được bảo hiểm hoặc người thụ hưởng
//       phải gửi cho Bảo Minh các giấy tờ chính sau đây:
//       <br />
//       1. Giấy yêu cầu trả tiền bảo hiểm theo mẫu của Bảo Minh.
//       <br />
//       2. Giấy chứng nhận bảo hiểm hoặc Hợp đồng bảo hiểm (Bản sao).
//       <br />
//       3. Biên bản tai nạn có xác nhận của cơ quan, đơn vị chính quyền địa phương
//       hoặc Công an nơi Người được bảo hiểm bị tai nạn (trường hợp bị tai nạn).
//       <br />
//       4. Các chứng từ y tế: giấy ra viện (trường hợp điều trị nội trú), phiếu mổ
//       (trường hợp phẫu thuật) ...
//       <br />
//       5. Giấy chứng tử (trường hợp Người được bảo hiểm chết).
//       <br />
//       6. Chứng từ chứng minh quyền thụ hưởng hợp pháp trong trường hợp Người
//       được bảo hiểm chết hay giấy ủy quyền nhận tiền bảo hiểm trong trường hợp
//       thay mặt Người được bảo hiểm nhận tiền bảo hiểm.
//     </div>
//   </>
// );
export const ntd = (
  <>
    <div>
      Căn nhà không chỉ là tài sản lớn đối với mỗi người, mà còn là nơi trở về
      sau những bộn bề lo toan ngoài kia. Để bảo vệ “tổ ấm” yêu thương của bạn,
      Global Care liên kết với Tổng Công ty CP Bảo hiểm Hàng không (VNI) mang
      lại cho khách hàng quyền lợi bảo vệ ngôi nhà trước các rủi ro: cháy, nổ,
      giông bão, lũ lụt, trộm cắp…. với quyền lợi bảo vệ lên đến 10 TỶ ĐỒNG mà
      mức phí chỉ từ 200.000đ/năm.
    </div>
    <br />
    <div className="text-bold">Quyền Lợi Bảo Hiểm:</div>
    <div>QUYỀN LỢI BẢO VỆ LÊN ĐẾN 10 TỶ ĐỒNG</div>
    <div>
      Bạn có thể dễ dàng tùy chỉnh kế hoạch bảo vệ theo nhu cầu và giá trị ngôi
      nhà bằng cách chọn giá trị ngôi nhà và số tiền bảo hiểm từ 800 triệu đồng
      đến 10 tỷ đồng và nhận báo giá Phí bảo hiểm tức thì.
    </div>

    <div>ƯU THẾ VƯỢT TRỘI</div>
    <div className="ml-25">
      • Không yêu cầu đánh giá giá trị thực tế của ngôi nhà
      <br />
      • Không yêu cầu kê khai danh mục tài sản bên trong
      <br />
      • Miễn phí thuê nhà sau tổn thất
      <br />
      • Bồi thường thay thế mới theo giá thị trường
      <br />
      • Thủ tục bồi thường nhanh chóng, thuận tiện
      <br />
    </div>
    <div className="text-bold">Đối tượng:</div>
    <div className="ml-25">
      - Nhà liền kề <br />
      - Biệt thự <br />
      - Chung cư <br />
      - Ngôi nhà (Giá trị khung nhà tối đa 25 năm) <br />
      - Tài sản bên trong ngôi nhà <br />
      <div className="ml-25">
        • Ngôi nhà bao gồm cả trang thiết bị nội thất cố định, cổng, hàng rào.{" "}
        <br />• Tài sản bên trong bao gồm các đồ đạc, dụng cụ gia đình, máy móc
        và các Tài sản khác đặt bên trong Ngôi nhà nhưng không bao gồm tiền,
        giấy tờ các loại, vàng bạc, kim loại quý, đá quý, đồ cổ trừ khi những
        Tài sản này được VNI chấp thuận bằng văn bản.
      </div>
    </div>
    <div className="text-bold">Quy Tắc Bảo Hiểm:</div>
    <div>
      Theo Quy tắc bảo hiểm Nhà Tư nhân ban hành kèm theo Quyết định số:
      <a
        href="https://drive.google.com/file/d/1kkIC8tlRNP6TvoVDpVanKC37g7WTuEJZ/preview"
        target="_blank"
      >
        37/2019/QĐ-BHHK
      </a>{" "}
      ngày 09 tháng 01 năm 2019 của Tổng giám đốc Tổng công ty Cổ phần bảo hiểm
      hàng không.
    </div>
    <div className="text-bold">Phạm Vi Bồi Thường:</div>
    <div>
      Bảo hiểm nhà tư nhân của VNI chịu trách nhiệm bồi thường cho những tổn
      thất vật chất bất ngờ và không lường trước được đối với tài sản được bảo
      hiểm gây ra bởi các rủi ro được liệt kê dưới đây và không thuộc các điểm
      loại trừ trong quy tắc bảo hiểm của VNI:
      <br />
      <div className="ml-25">
        • Hỏa hoạn, sét đánh;
        <br />
        • Nổ;
        <br />
        • Máy bay và các phương tiện hàng không khác hoặc các thiết bị trên các
        phương tiện đó rơi vào; <br />• Giông bão, lũ lụt;
        <br />
        • Vơ hay tràn nước từ các bể chứa, thiết bị chứa nước hay đường ống dẫn;
        <br />
        • Xe cộ hay súc vật không thuộc quyền sở hữu hay kiểm soát của người
        được bảo hiểm hay người làm thuê cho họ đâm vào;
        <br />
        • Động đất;
        <br />
        • Trộm cắp
        <br />
      </div>
    </div>
    <div className="text-bold">Loại trừ bảo hiểm:</div>
    <div className="text-bold">Nhà bảo hiểm VNI sẽ không bồi thường cho:</div>
    <div className="ml-25">
      -Những thiệt hại do gây rối, quần chúng nổi dậy, bãi công, sa thải công
      nhân
      <br /> -Những thiệt hại bắt nguồn từ nguyên liệu vũ khí hạt nhân, tên lửa,
      vũ khí chiến tranh, phóng xạ ion hoá <br />
      -Những thiệt hại gây ra do ô nhiễm, nhiễm bẩn <br />
      -Những thiệt hại gây ra bởi chiến tranh và/ hoặc hành động khủng bố
    </div>
    <div className="text-bold">Hồ Sơ Yêu Cầu Bồi Thường:</div>
    <div className="ml-25">
      - Giấy yêu cầu bảo hiểm nhà tư nhân <br />- Giấy chứng nhận bảo hiểm nhà
      tư nhân
      <br />
      <b>
        Bước 1: Thông báo tổn thất, gọi ngay Hotline nhà bảo hiểm thông báo tổn
        thất: -VNI: 1900 969 690 <br />
        Bước 2: Cung cấp hồ sơ theo hướng dẫn của nhà Bảo hiểm <br />
        Bước 3: Nhận bồi thường
      </b>
    </div>
  </>
);

export const vbicare = (
  <>
    <div>
      Nếu bạn đang tìm một gói bảo hiểm để chăm sóc sức khỏe và chuẩn bị cho kế
      hoạch thai sản thì VBI sẽ là lựa chọn tối ưu cho bạn đấy.
    </div>
    <div>
      Bảo hiểm VBI Care do Global Care kết hợp nhà bảo hiểm VBI, với nhiều đặc
      điểm nổi bật cùng với mức phí chỉ từ 1.000Đ/ngày.
    </div>
    1. Quyền lợi thai sản ưu việt: THỜI GIAN CHỜ NGẮN NHẤT THỊ TRƯỜNG
    <br />
    2. Bảo vệ cho các loại BỆNH SẴN CÓ
    <br />
    3. Trẻ em được tham gia ĐỘC LẬP
    <br />
    4. Bồi thường nhanh chóng CHỈ TRONG 5 NGÀY
    <br />
    5. Bảo lãnh viện phí SIÊU TỐC
    <br />
    6. Quy trình online MỌI LÚC, MỌI NƠI.
    <br />
    <br />
    <b>Quyền lợi bảo hiểm:</b>
    <br />
    + Bảo hiểm tử vong, thương tật toàn bộ vĩnh viễn do tai nạn
    <br />
    + Bảo hiểm tử vong, thương tật toàn bộ vĩnh viễn do bệnh <br />
    + Bảo hiểm điều trị nội trú phẫu thuật do bệnh
    <br />
    + Chi phí y tế do tai nạn <br />
    <br />
    <b>Quyền lợi bổ sung:</b>
    <br />
    + Thai sản
    <br />
    + Trợ cấp nằm viện do tai nạn
    <br />
    + Bảo hiểm điều trị ngoại trú
    <br />
    + Dịch vụ bảo lãnh viện phí <br />
    + Nha khoa <br />
    <br />
    <b>Đối tượng tham gia:</b>
    <br />
    Công dân Việt Nam
    <br />
    Người nước ngoài đang cư trú hợp pháp tại Việt Nam
    <br />
    Từ đủ 60 ngày tuổi đến 65 tuổi
    <br />
    <br />
    <b>Phạm vi bảo hiểm:</b>
    <br />
    Global Care cung cấp cho khách hàng 04 gói bảo hiểm đa dạng, gồm: Gói Cơ
    bản, Gói Mở rộng, Gói Nâng cao và Gói Đặc biệt.
    <br />
    <br />
    <b>Thời gian chờ:</b>
    <br />
    + Bảo hiểm có hiệu lực sau thời gian chờ dưới đây kể từ ngày bắt đầu thời
    hạn bảo hiểm
    <br />
    + Quy định trên Giấy chứng nhận bảo hiểm:
    <br />
    <div style={{ paddingLeft: 40 }}>
      - Bệnh thông thường, điều trị ốm đau (bao gồm nha khoa): 30 ngày
      <br />
      - Điều trị hoặc tử vong do bệnh đặc biệt, bệnh/ thương tật có sẵn: 365
      ngày
      <br />
      - Thai sản (sinh thường, sinh mổ, sinh khó, tai biến sản khoa): 270 ngày
      <br />
      - Điều trị bất thường trong quá trình mang thai và bệnh lý phát sinh trong
      thai kỳ: 90 ngày
      <br />
      - Tử vong do ốm đau, bệnh thông thường: 90 ngày
      <br />
      - Điều trị đứt dây chằng, rách sụn chêm: 365 ngày
      <br />
      - Viêm phế quản, viêm tiểu phế quản, viêm phổi các loại cho trẻ em dưới 7
      tuổi: 90 ngày.
      <br />
    </div>
    <br />
    <b>Quy tắc bảo hiểm:</b>
    <br />
    Quy tắc (Theo{" "}
    <a href="https://drive.google.com/file/d/1h7UWoFN3Eho46v2LLgki1DQdlPJmG_zI/view">
      Quyết định số 1368/QĐ-VBI6
    </a>{" "}
    ngày 17 tháng 7 năm 2018 của Tổng giám đốc Bảo hiểm VietinBank)
    <br />
    <br />
    <b>Đối tượng loại trừ:</b>
    <br />
    Người đã, đang mắc phải bệnh tâm thần, thần kinh bệnh phong.
    <br />
    Người đã/đang mắc phải bệnh lý nghiêm trọng, ung thư.
    <br />
    Người bị thương tật vĩnh viễn quá 50%.
    <br />
    Người đang trong thời gian điều trị nội trú do bệnh hoặc tai nạn.
    <br />
    <br />
    <b>Hồ sơ yêu cầu bồi thường:</b>
    <br />
    Qua app VBI Care:
    <br />
    Bước 1: Khai báo bồi thường online ngay tại Bệnh viện.
    <br />
    Bước 2: Sau 5 phút VBI sẽ gửi email thông báo bổ sung hồ sơ còn thiếu ngay
    khi KH đang ở bệnh viện.
    <br />
    Bước 3: VBI chuyển tiền cho người thụ hưởng trong hợp đồng bảo hiểm từ 24h
    đến 5 ngày. <br />
  </>
);

export const familycare = (
  <>
    Bảo hiểm Family Care do Global Care kết hợp với nhà bảo hiểm Bảo Minh giúp
    “trụ cột” gia đình an tâm trọn vẹn trước những “ngày mưa” trong cuộc sống
    khi gia đình luôn được bảo vệ vững vàng với giải pháp dự phòng tài chính ưu
    việt.
    <br />
    ƯU ĐIỂM NỔI BẬT CỦA FAMILY CARE:
    <br />- Mức phí chỉ từ <b>287.000đ/ năm.</b>
    <br />- Bảo vệ tài chính gia đình: quyền lợi bảo vệ vượt trội lên tới
    <b> 2 TỶ ĐỒNG.</b>
    <br />
    - Bảo hiểm cho cả tai nạn và bệnh tật
    <br />- <b>Không hạn chế</b> bệnh viện điều trị
    <br />- Độ tuổi tham gia từ <b>14 ngày tuổi đến 65 tuổi</b> <br />- Duyệt hồ
    sơ bồi thường online chỉ <b>trong 1 phút 30 giây</b> <br />- Được hưởng{" "}
    <b>quyền lợi song song</b> với các chương trình bảo hiểm khác .
    <br />
    <br />
    <b>Quyền lợi bảo hiểm:</b>
    <br />
    Thương tật bộ phận vĩnh viễn do ốm bệnh, tai nạn
    <br />
    Tử vong, thương tật toàn bộ vĩnh viễn do ốm, bệnh, tai nạn
    <br />
    Trợ cấp nằm viện/ngày do ốm, bệnh, tai nạn
    <br />
    <br />
    <b>Quyền lợi bảo hiểm</b>
    <br />
    <br />
    <table border="1">
      <tr style={{ background: "green", color: "#fff" }}>
        <th>Quyền lợi bảo hiểm</th>
        <th>Chương trình 1 </th>
        <th>Chương trình 2</th>
        <th>Chương trình 3</th>
        <th>Chương trình 4</th>
        <th>Chương trình 5</th>
      </tr>
      <tr>
        <th>Tổng số tiền bảo hiểm</th>
        <th>VND 207,200,000 </th>
        <th>VND 621,600,000</th>
        <th>VND 1,036,000,000</th>
        <th>VND 1,450,400,000</th>
        <th>VND 2,072,000,000</th>
      </tr>
      <tr style={{ background: "#D6F9E2", color: "#000" }}>
        <th>A. Quyền lợi bảo hiểm tai nạn</th>
        <th>VND 103,600,000 </th>
        <th>VND 310,800,000</th>
        <th>VND 518,000,000</th>
        <th>VND 725,200,000</th>
        <th>VND 1,036,000,000</th>
      </tr>
      <tr>
        <th>Tử vong, thương tật toàn bộ vĩnh viễn</th>
        <th>VND 100,000,000 </th>
        <th>VND 300,000,000</th>
        <th>VND 500,000,000</th>
        <th>VND 700,000,000</th>
        <th>VND 1,000,000,000</th>
      </tr>
      <tr>
        <th>Thương tật bộ phận vĩnh viễn</th>
        <th colspan="5">
          Chi trả theo bảng tỷ lệ thương tật do Bảo Minh ban hành
        </th>
      </tr>
      <tr>
        <th>Trợ cấp nằm viện/ ngày do tai nạn. Tối đa 30 ngày/năm</th>
        <th>VND 207,200,000 </th>
        <th>VND 621,600,000</th>
        <th>VND 621,600,000</th>
        <th>VND 1,450,400,000</th>
        <th>VND 2,072,000,000</th>
      </tr>
      <tr style={{ background: "#D6F9E2", color: "#000" }}>
        <th>B. Quyền lợi ốm bệnh</th>
        <th>VND 103,600,000 </th>
        <th>VND 310,800,000</th>
        <th>VND 518,000,000</th>
        <th>VND 725,200,000</th>
        <th>VND 1,036,000,000</th>
      </tr>
      <tr>
        <th>
          1. Tử vong, thương tật toàn bộ vĩnh viễn, trong đó:
          <br />
          - Từ đủ 1 tuổi đến 65 tuổi: 100% Số tiền bảo hiểm
          <br />- Từ 14 ngày tuổi đến dưới 1 tuổi: 50% Số tiền bảo hiểm
        </th>
        <th>VND 100,000,000 </th>
        <th>VND 300,000,000</th>
        <th>VND 500,000,000</th>
        <th>VND 700,000,000</th>
        <th>VND 1,000,000,000</th>
      </tr>
      <tr>
        <th>2. Thương tật bộ phận vĩnh viễn</th>
        <th colspan="5">
          Chi trả theo bảng tỷ lệ thương tật do Bảo Minh ban hành
        </th>
      </tr>
      <tr>
        <th>
          3. Trợ cấp nằm viện/ngày do ốm bệnh. Tối đa 30 ngày/năm, giới hạn tối
          đa 5 ngày/đợt nằm viện
        </th>
        <th>VND 120,000 </th>
        <th>VND 360,000</th>
        <th>VND 600,000</th>
        <th>VND 840,000</th>
        <th>VND 1,200,000</th>
      </tr>
      <tr style={{ background: "#D6F9E2", color: "#000" }}>
        <th> Phí bảo hiểm cá nhân</th>
        <th>VND 287,000 </th>
        <th>VND 862,000</th>
        <th>VND 1,437,000</th>
        <th>VND 2,012,000</th>
        <th>VND 2,875,000</th>
      </tr>
    </table>
    <br />
    <br />
    <br />
    <b>Đối tượng tham gia:</b>
    <br />
    Công dân Việt Nam
    <br />
    Người nước ngoài đang cư trú hợp pháp tại Việt Nam
    <br />
    Từ đủ 14 ngày tuổi đến 65 tuổi (trẻ em từ 5 tuổi trở xuống phải tham gia bảo
    hiểm cùng với bố/và hoặc mẹ). <br />
    <br />
    <b>Đối tượng loại trừ:</b>
    <br />
    Thương tật vĩnh viễn từ 50% trở lên.
    <br />
    Người có tiền sử hoặc đang bị bệnh tâm thần, thần kinh, phong, ung thư.
    <br />
    Người đang trong thời gian điều trị thương tật do tai nạn hoặc điều trị nội
    trú/ phẫu thuật do bệnh tật tại các cơ sở y tế. <br />
    <br />
    <b>Thời gian chờ:</b>
    <br />
    Rủi ro tai nạn: Không áp dụng
    <br />
    Bệnh thông thường: 30 ngày
    <br />
    Bệnh đặc biệt, bệnh có sẵn: 365 ngày <br />
    <br />
    <b>Quy Tắc Bảo Hiểm</b>
    <br />
    Theo Quy tắc Bảo hiểm sức khỏe gia đình được ban hành theo Quyết định số{" "}
    <a href="https://drive.google.com/file/d/1k-1_SmT1dNRdNPg1KVG0fbz1Vfo8yIeF/view?usp=drive_link">
      001325/ 2006-BM/BHCN{" "}
    </a>
    ngày 23/05/2006 của Tổng Giám đốc Công ty Cổ phần Bảo Minh.
    <br />
    Bảng tỷ lệ Trả tiền bảo hiểm thương tật (Ban hành theo công văn số{" "}
    <a href="https://drive.google.com/file/d/1ZShwF1DZlYduORi3PbB6MbX9LSb1ZRDL/view?usp=sharing">
      5701/ BTC-BH{" "}
    </a>
    ngày 04/05/2006 của Bộ tài chính.) <br />
    <br />
    <b>Phạm Vi Bồi Thường</b>
    <br />
    Chết do mọi nguyên nhân <br />
    Thương tật thân thể do tai nạn
    <br />
    Ốm đau, bệnh tật, thai sản phải nằm viện hoặc phẫu thuật <br />
    <br />
    <b>Các Điểm Loại Trừ Bồi Thường</b>
    <br />
    <b>
      Bảo hiểm này không trả tiền bảo hiểm trong những trường hợp sau hoặc gây
      ra bởi:
    </b>
    <br />
    1. Bệnh có sẵn và bệnh đặc biệt (chỉ áp dụng trong năm đầu tiên tham gia bảo
    hiểm).
    <br />
    2. Hành động cố ý của Người được bảo hiểm hoặc người thụ hưởng.
    <br />
    3. Người được bảo hiểm từ đủ 14 tuổi trở lên vi phạm nghiêm trọng pháp luật,
    quy định của chính quyền địa phương hoặc các tổ chức xã hội.
    <br />
    4. Người được bảo hiểm bị ảnh hưởng trực tiếp do sử dụng rượu, bia, ma túy
    hoặc các chất kích thích tương tự khác.
    <br />
    5. Việc điều trị tại các trung tâm điều dưỡng, khu điều dưỡng trong bệnh
    viện, trung tâm dưỡng lão, suối nước nóng, suối nước khoáng.
    <br />
    6. Nằm viện để kiểm tra sức khỏe hoặc khám giám định y khoa mà không liên
    quan đến việc điều trị bệnh tật.
    <br />
    7. Điều trị hoặc phẫu thuật các bệnh tật bẩm sinh, những thương tật và chỉ
    định phẫu thuật có từ trước ngày bắt đầu bảo hiểm.
    <br />
    8. Điều trị chưa được khoa học công nhận hoặc điều trị thử nghiệm.
    <br />
    9. Tạo hình thẩm mỹ, chỉnh hình, phục hồi chức năng, làm chân, tay giả, mắt
    giả, răng giả, kế hoạch hóa gia đình.
    <br />
    10. Điều trị các bệnh giang mai, lậu, nhiễm vi rút HIV, sốt rét, lao và bệnh
    nghề nghiệp.
    <br />
    11. Động đất, núi lửa, nhiễm phóng xạ.
    <br />
    12. Chiến tranh, nội chiến, đình công và khủng bố.
    <br />
    <br />
    <b>Hồ Sơ Yêu Cầu Bồi Thường</b>
    <br />
    Bước 1: Liên hệ số Hotline của Bảo hiểm Bảo Minh 1800 588 812
    <br />
    Bước 2: Làm theo hướng dẫn
    <br />
    Bước 3: Nhận kết quả bồi thường
    <br />
    <b>
      Hoặc yêu cầu bồi thường trực tiếp trên ứng dụng BH365 của Global Care:
    </b>
    <br />
    Bước 1: Thu thập hồ sơ y tế
    <br />
    Bước 2: Scan khai báo trên app BH365 <br />
    Bước 3: Nhận kết quả phê duyệt bồi thường <br />
    Bước 4: Nộp hồ sơ gốc
    <br />
    Bước 5: Nhận tiền bảo hiểm <br />
  </>
);
